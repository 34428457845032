<template>
  <v-container
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        mt-3
        xs12
      >
        <material-card
          title="測站品質統計及問題分析"
          color="general"
          class="card-padding pt-0 pb-3">
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout
                wrap
                align-items-center>
                <v-flex
                  xs12
                  sm6
                  lg4>
                  <v-autocomplete
                    v-model="summaryModelAndFactory"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryModelAndFactoryMenu"
                    label="Model name"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  lg1/>
                <v-flex
                  xs12
                  sm6
                  lg3>
                  <v-autocomplete
                    v-model="summaryProductStation"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryProductStationMenu"
                    label="Station"
                    class="v-text-field__slot"
                    color="white"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  lg3/>
                <v-flex
                  flex-small
                  d-flex
                  align-items-center
                  xs12
                  lg4>
                  <p class="m-0">時間</p>
                  <v-menu
                    v-model="summaryStartDateMenu"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    style="max-width: 170px;margin-left: 10px;"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="summaryStartDate"
                      label=""
                      prepend-icon="event"
                    />
                    <v-date-picker
                      v-model="summaryStartDate"
                      :min="startdate"
                      :max="new Date() | date-format"
                      :color="theme==='dark'?'danger':'general'"
                      :event-color="'green'"
                      :events="manufacturingDateList"
                      type="date"
                      no-title
                      scrollable
                      actions/>
                  </v-menu>
                  <p class="m-0 text-center px-3">到</p>
                  <v-menu
                    v-model="summaryEndDateMenu"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    style="max-width: 170px;margin-left: 10px;"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="summaryEndDate"
                      label=""
                      prepend-icon="event"
                    />
                    <v-date-picker
                      v-model="summaryEndDate"
                      :min="summaryStartDate"
                      :max="new Date() | date-format"
                      :color="theme==='dark'?'danger':'general'"
                      :event-color="'green'"
                      :events="manufacturingDateList"
                      type="date"
                      no-title
                      scrollable
                      actions/>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  lg1/>
                <v-flex
                  d-flex
                  xs12
                  lg4>
                  <v-checkbox
                    v-model="summaryStatisticItem"
                    class="mt-1 flex"
                    style="padding: 0px !important;margin-bottom: -15px !important;"
                    label="CPK"
                    value="cpk"/>
                  <v-checkbox
                    v-model="summaryStatisticItem"
                    class="mt-1 flex"
                    style="padding: 0px !important;margin-bottom: -15px !important;"
                    label="Yield Rate、Problem chart"
                    value="yield_rate"/>
                </v-flex>
                <v-flex
                  xs12
                  lg2/>
                <v-flex
                  xs12
                  lg1
                  text-xs-right
                >
                  <v-btn
                    :disabled="summaryStartDate===''||summaryEndDate===''||summaryModelAndFactory===''||summaryProductStation===''||summaryStatisticItem.length===0"
                    color="general"
                    class="ml-3"
                    small
                    @click="doSubmitAndGetStatisticItem()">
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>分析
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          v-if="deviceTestItemView"
          style="margin-top: 20px!important;">
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  flex
                  xs12
                  lg10>
                  <v-autocomplete
                    v-model="summaryTestItem"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryTestItemMenu"
                    label="測站測項列表"
                    class="v-text-field__slot issue_tracking_menu_chips row-btn-175 pt-0 mt-0 v-chip-small"
                    style="margin-bottom: -10px;"
                    color="white"
                    multitude
                    chips
                    persistent-hint
                    multiple
                    outlined
                  />
                  <v-btn
                    class="mt-3 ml-3 mr-0 font-weight-light float-right"
                    color="red"
                    @click="summaryTestItemListClear()"
                  >
                    清除
                  </v-btn>
                  <v-btn
                    class="mt-3 ml-3 mr-0 font-weight-light float-right"
                    color="blue"
                    @click="summaryTestItemListAll()"
                  >
                    全選
                  </v-btn>
                </v-flex>
                <v-flex
                  xs12
                  lg1/>
                <v-flex
                  xs12
                  lg1
                  text-xs-right>
                  <v-btn
                    :disabled="responseDateStart===''||responseDateEnd===''||responseModelAndFactory===''||responseProductStation===''||responseStatisticItem.length===0||summaryTestItem.length===0"
                    color="general"
                    class="ml-3 mr-0"
                    small
                    @click="doSubmitAndGetStationCpk()">
                    <v-icon class="mr-2">mdi-chart-bar</v-icon>統計
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        v-if="(stationYieldQualityView||stationCpkView)&&deviceTestItemIsSubmit"
        xs12
        lg3>
        <material-stats-card
          :value="responseTotalAmount"
          style="margin-top:10px !important;margin-bottom:0px !important;"
          color="light-blue"
          icon="mdi-factory"
          title="Total inputs"/>
      </v-flex>
      <v-flex
        v-if="(stationYieldQualityView||stationCpkView)&&deviceTestItemIsSubmit"
        xs12
        sm6
        lg3>
        <material-stats-card
          :value="(responseYieldRate * 100).toFixed(1) + '%'"
          :class="evaluationSpecBgColor"
          :color="evaluationSpecColor"
          :icon="evaluationSpecIcon"
          class="title-color"
          style="margin-top:10px !important;margin-bottom:0px !important;"
          title="Yield Rate"/>
      </v-flex>
      <v-flex
        v-if="(stationYieldQualityView||stationCpkView)&&deviceTestItemIsSubmit"
        xs12
        lg6>
        <material-card
          v-if="summaryStatisticItem!=='sn'"
          style="margin-top: 20px!important;">
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  d-flex
                  xs12
                  lg9>
                  <v-checkbox
                    v-model="summaryExportItem"
                    class="mt-1 flex"
                    style="padding: 0px !important;margin-top: 20px !important;margin-bottom: -10px !important;"
                    label="CPK"
                    value="cpk"/>
                  <v-checkbox
                    v-model="summaryExportItem"
                    class="mt-1 flex"
                    style="padding: 0px !important;margin-top: 20px !important;margin-bottom: -10px !important;"
                    label="Distribution"
                    value="distribution"/>
                  <v-checkbox
                    v-model="summaryExportItem"
                    class="mt-1 flex"
                    style="padding: 0px !important;margin-top: 20px !important;margin-bottom: -10px !important;"
                    label="Yield Rate"
                    value="yield_rate"/>
                  <v-checkbox
                    v-model="summaryExportItem"
                    class="mt-1 flex"
                    style="padding: 0px !important;margin-top: 20px !important;margin-bottom: -10px !important;"
                    label="Detail"
                    value="detail"/>
                </v-flex>
                <v-flex
                  xs12
                  lg3
                  text-xs-right>
                  <v-btn
                    :disabled="summaryExportItem.length===0"
                    color="general"
                    class="mt-3 ml-3 mr-0"
                    small
                    @click="doDownload()">
                    <v-icon class="mr-2">mdi-download</v-icon>匯出分析結果
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        v-if="stationYieldQualityView&&deviceTestItemIsSubmit"
        flex_table_width_auto
        xs5
      >
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="Yield Rate統計表"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <v-btn
            class="mix-btn black text-light"
            style="right: 1rem;"
            @click="doDeviceSelectListViewOpen()">
            <v-icon class="floata-left">mdi-eye</v-icon>檢視勾選清單
          </v-btn>
          <v-btn
            v-if="!chartPieView"
            class="mix-btn teal text-light"
            style="right: 9.5rem;"
            @click="dochartPieViewOpen()">
            <v-icon class="floata-left">mdi-chart-pie</v-icon>檢視測試結果統計圖
          </v-btn>
          <v-data-table
            :headers="tableData.yieldRate.responseHeaders"
            :items="tableData.yieldRate.responseData"
            :pagination.sync="tableData.yieldRate.pagination"
            class="height-row-3"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr>
                <td>{{ item.result }}</td>
                <td class="text-right">
                  <v-chip
                    class="px-1 cursor red"
                    @click="doSubmitAndGetDeviceStatistic(item.testItemPk, item.result)">
                    <v-icon class="small mr-1">mdi-eye</v-icon>{{ item.amount }}
                  </v-chip>
                </td>
                <td class="text-right">{{ (item.proportion*100).toFixed(2) }}%</td>
              </tr>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
      <v-flex
        v-show="chartPieView&&stationYieldQualityView&&deviceTestItemIsSubmit"
        xs7
        style="padding: 0px 12px!important;">
        <material-card
          title="測試結果統計圖"
          color="brown"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <vue-c3 :handler="chartPie"/>
        </material-card>
      </v-flex>
      <v-flex
        v-if="stationYieldQualityView&&deviceListView&&deviceTestItemIsSubmit"
        flex_table_width_auto
        xs7
      >
        <material-card
          :title="responseTestResult"
          color="deep-orange"
          class="checkTable card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <v-select
            v-model="tableData.deviceList.pagination.rowsPerPage"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="tableData.deviceList.rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          />
          <v-text-field
            v-model="tableData.deviceList.keyword"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            class="mix-input"
            style="right: 8.5rem;"
            append-icon="search"
            label="關鍵字過濾"
            single-line
            hide-details/>
          <v-btn
            class="mix-btn black text-light"
            style="right: 20.5rem;"
            @click="doAddDeviceSelectList()">
            <v-icon class="floata-left">mdi-plus</v-icon>加入勾選清單
          </v-btn>
          <!-- <v-btn
            class="mix-btn black text-light"
            style="right: 28.5rem;"
            @click="doAddDeviceSelectListAll()">
            選擇全部
          </v-btn> -->
          <v-data-table
            :headers="tableData.deviceList.responseHeaders"
            :items="tableData.deviceList.responseData"
            :search="tableData.deviceList.keyword"
            :pagination.sync="tableData.deviceList.pagination"
            class="d-table-full height-row-3"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr :key="'d'+item.deviceIsn">
                <td
                  class="td-check table-width-55"
                  style="position: relative;overflow: hidden;">
                  <v-checkbox
                    v-model="tableData.deviceList.checkList"
                    :value="item.deviceIsn"
                    class="mr-2 mt-0"
                    color="white"
                    label=""/>
                </td>
                <td>{{ item.deviceIsn }}</td>
                <td class="text-right">{{ item.passAmount }}</td>
                <td class="text-right">{{ item.failAmount }}</td>
                <td>{{ item.result }}</td>
              </tr>
            </template>
          </v-data-table>
          <div
            v-if="tableData.deviceList.pagination.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="tableData.deviceList.pagination.page"
              :length="tableData.deviceList.pages"/>
          </div>
          <v-autocomplete
            v-if="tableData.deviceList.pagination.rowsPerPage != -1"
            v-model="tableData.deviceList.pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="tableData.deviceList.pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          />
        </material-card>
      </v-flex>
      <v-flex
        v-if="stationYieldQualityView&&deviceSelectListView&&deviceTestItemIsSubmit"
        flex_table_width_auto
        xs7
      >
        <material-card
          title="勾選清單"
          color="deep-orange"
          class="checkTable card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <v-select
            v-model="tableData.deviceSelectList.pagination.rowsPerPage"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="tableData.deviceSelectList.rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          />
          <v-text-field
            v-model="tableData.deviceSelectList.keyword"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            class="mix-input"
            style="right: 8.5rem;"
            append-icon="search"
            label="關鍵字過濾"
            single-line
            hide-details/>
          <v-btn
            :disabled="tableData.deviceSelectList.responseData.length===0"
            class="mix-btn black text-light"
            style="right: 20.5rem;"
            @click="doGoToDeviceStationTestItemStatistic()">
            <v-icon class="floata-left">mdi-eye</v-icon>檢視測項
          </v-btn>
          <v-btn
            class="mix-btn black text-light"
            style="right: 27rem;"
            @click="doRemoveDeviceSelectListAll()">
            移除全部
          </v-btn>
          <v-data-table
            :headers="tableData.deviceSelectList.responseHeaders"
            :items="tableData.deviceSelectList.responseData"
            :search="tableData.deviceSelectList.keyword"
            :pagination.sync="tableData.deviceSelectList.pagination"
            class="d-table-full height-row-3"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr :key="'s'+item.deviceIsn">
                <td>
                  <v-btn
                    class="mx-0 font-weight-bold small mr-2"
                    color="red"
                    @click="doRemoveDeviceSelectList(item.deviceIsn)">
                    移除
                  </v-btn>
                </td>
                <td>{{ item.deviceIsn }}</td>
                <td class="text-right">{{ item.passAmount }}</td>
                <td class="text-right">{{ item.failAmount }}</td>
                <td>{{ item.result }}</td>
              </tr>
            </template>
          </v-data-table>
          <div
            v-if="tableData.deviceSelectList.pagination.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="tableData.deviceSelectList.pagination.page"
              :length="tableData.deviceSelectList.pages"/>
          </div>
          <v-autocomplete
            v-if="tableData.deviceSelectList.pagination.rowsPerPage != -1"
            v-model="tableData.deviceSelectList.pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="tableData.deviceSelectList.pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          />
        </material-card>
      </v-flex>
      <v-flex
        v-if="stationCpkView"
        flex_table_width_auto
        xs12
      >
        <material-card
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="測項CPK統計"
          color="deep-orange"
          class="checkTable card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <v-btn
            class="mix-btn black text-light mx-0"
            style="right:240px;"
            @click="dofilterALLClear()">清除條件</v-btn>
          <v-btn
            :disabled="tableData.stationCpk.checkList.length===0"
            class="mix-btn black text-light mx-0"
            color="primary"
            style="right:140px;"
            @click="doSubmitAndGetStationDistributionInfo()"><v-icon class="small mr-1">mdi-chart-bar</v-icon>分佈統計</v-btn>
          <v-select
            v-model="tableData.stationCpk.pagination.rowsPerPage"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="tableData.stationCpk.rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          />
          <table
            class="statistic-table keypart-table">
            <thead>
              <tr>
                <!-- <th
                  class="td-check table-width-55"
                  style="position: relative;overflow: hidden;">
                  <v-checkbox
                    v-model="tableData.stationCpk.checkALL"
                    :value="true"
                    class="mr-2 mt-0"
                    color="white"
                    label=""/>
                </th> -->
                <th class="pl-3">標記</th>
                <th
                  v-for="(header, h) in tableData.stationCpk.responseHeaders"
                  v-show="h>0"
                  :key="'h'+h"
                  :class="{'desc':tableData.stationCpk.pagination.sortBy===header.value&&tableData.stationCpk.pagination.descending, 'asc':tableData.stationCpk.pagination.sortBy===header.value&&!tableData.stationCpk.pagination.descending}">
                  <div class="table-filter-item">
                    <v-icon @click="dofilterViewOpen(header.type, h, header.text)">mdi-filter</v-icon>
                    {{ filterSettingList[h] }}
                    <v-icon
                      v-if="filterSettingList[h]"
                      class="close"
                      @click="dofilterClear(h)">
                      mdi-close
                    </v-icon>
                  </div>
                  <span @click="doTableSort(header.value)">
                    {{ header.text }}
                    <v-icon class="sort-btn">mdi-arrow-up</v-icon>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item,i) in tableData.stationCpk.responseDataCompile"
                v-show="tableData.stationCpk.pagination.rowsPerPage===-1||((i+1)>(tableData.stationCpk.pagination.page-1)*tableData.stationCpk.pagination.rowsPerPage&&(i+1)<=tableData.stationCpk.pagination.page*tableData.stationCpk.pagination.rowsPerPage)"
                :key="'s'+item.testItemPk">
                <td
                  class="td-check table-width-55"
                  style="position: relative;overflow: hidden;">
                  <v-checkbox
                    v-model="tableData.stationCpk.checkList"
                    :value="item.testItemPk"
                    class="mr-2 mt-0"
                    color="white"
                    label=""/>
                </td>
                <td>{{ item.testItemName }}</td>
                <td>{{ item.upperSpecValue }}</td>
                <td>{{ item.lowerSpecValue }}</td>
                <td :style="!doCpkGrade(item.cpk)?'color:red;':''">{{ item.cpk }}</td>
                <td :style="!doCpkGrade(item.cpk)?'color:red;':''">{{ item.level }}</td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="tableData.stationCpk.pagination.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="tableData.stationCpk.pagination.page"
              :length="tableData.stationCpk.pages"/>
          </div>
          <v-autocomplete
            v-if="tableData.stationCpk.pagination.rowsPerPage != -1"
            v-model="tableData.stationCpk.pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="tableData.stationCpk.pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          />
        </material-card>
      </v-flex>
      <template v-for="(date, d) in stationDistributionDetailTable">
        <v-flex
          v-if="stationCpkView"
          :key="'chartLine'+nowTime+d"
          xs6>
          <material-card
            id="checkTable"
            :title="date.testItemName+' | '+date.stationName"
            color="brown"
            class="small-cpk-area card-mix d-table-t0 no-data-left"
            style="margin-top: 20px!important;">
            <div>
              <div class="small-cpk-info">
                <div :class="{ 'ordinary':doCpkGrade(date.cpk),'bad':!doCpkGrade(date.cpk) }">
                  <span>CPK</span>
                  <p>{{ date.cpk.toFixed(2) }}</p>
                  <div>
                    <span>{{ doCpkGradeName(date.cpk) }}</span>
                  </div>
                </div>
                <div>
                  <span>SPEC</span>
                  <p>{{ date.lowerSpecValue }}{{ (date.lowerSpecValue!==null&&date.upperSpecValue!==null)?' ~ ':'' }}{{ date.upperSpecValue }}</p>
                  <div>
                    <span v-if="date.lowerSpecValue!==null">LOWER</span>
                    <span v-if="date.upperSpecValue!==null">UPPER</span>
                  </div>
                </div>
              </div>
              <table class="small-cpk-table statistic-table">
                <thead>
                  <tr>
                    <th>sigma</th>
                    <th>value</th>
                    <th>histogram</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(row, r) in date.distributionCoverageList">
                    <tr
                      v-if="row.define.type === 'Sigma'"
                      :key="'r'+r">
                      <td>{{ row.define.name }}</td>
                      <td>{{ row.define.value.toFixed(2) }}</td>
                      <td>{{ row.amount }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <vue-c3
              :handler="stationDistributionChartLine[d]"
              style="margin-top: 20px!important;"
              class="small-cpk-chart"/>
          </material-card>
        </v-flex>
      </template>
    </v-layout>
    <v-dialog
      v-model="filterView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="35%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="filterView"
            class="mt-3 mb-3 border-0"
            color="brown">
            <strong class="h4">正在設定[{{ filterTitle }}]條件：{{ filterDirections }}</strong>
          </v-alert>
          <v-flex
            d-flex
            table-filter-setting-bar
            xs12>
            <v-text-field
              v-if="filterInfo.type==='keyword'"
              v-model="filterKeyword"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              label="關鍵字"
              placeholder="請輸入關鍵字"
              persistent-hint/>
            <v-btn-toggle
              v-if="filterInfo.type==='formula'"
              v-model="filterBtnSelect"
              class="d-flex align-items-center"
              multiple>
              <v-text-field
                v-model="filterMinValue"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                :disabled="filterBtnSelect.indexOf('ge')===-1"
                label=""
                persistent-hint
                @keyup="doMinValueReplaceText()"/>
              <v-btn
                value="ge"
                class="general"
                color="light"
                flat>
                <v-icon>mdi-less-than-or-equal</v-icon>
              </v-btn>
              <v-text-field
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                label=""
                value="X"
                disabled
                persistent-hint/>
              <v-btn
                value="le"
                class="general"
                color="light"
                flat>
                <v-icon>mdi-less-than-or-equal</v-icon>
              </v-btn>
              <v-text-field
                v-model="filterMaxValue"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                :disabled="filterBtnSelect.indexOf('le')===-1"
                label=""
                persistent-hint
                @keyup="doMaxValueReplaceText()"/>
            </v-btn-toggle>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="pink"
            small
            @click="dofilterSetting()">
            <v-icon class="mr-2">mdi-plus</v-icon>新增條件
          </v-btn>
          <v-btn
            color="general"
            small
            @click="filterView=false">
            <v-icon class="mr-2">mdi-close</v-icon>返回
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :top="true">
      {{ snackbarMsg }}
      <v-btn
        flat
        @click="snackbar = false">
        關閉
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import qs from 'qs'
// import $ from 'jquery'
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'
import NormalDistribution from 'normal-distribution'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'DataAnalyzer',
  components: {
    VueC3
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'green',
      snackbarMsg: '',
      nowTime: new Date().getTime(),
      Loading,
      loadingView: false,
      processingView: false,
      stationYieldQualityView: false,
      deviceListView: false,
      deviceTestItemView: false,
      deviceTestItemIsSubmit: false,
      chartPieView: false,
      stationCpkView: false,
      deviceSelectListView: false,
      filterView: false,
      filterInfo: {
        type: '',
        index: -1,
        group: '',
        item: ''
      },
      filterTitle: '',
      filterKeyword: '',
      filterMinValue: '',
      filterMaxValue: '',
      filterBtnSelect: [],
      filterSettingList: [],
      filterSettingDetailList: [],
      tempModelAndFactory: '',
      tempProductStation: '',
      summaryModelAndFactoryMenu: [],
      summaryProductStationMenu: [],
      summaryModelAndFactory: '',
      summaryProductStation: '',
      summaryStatisticItem: [],
      summaryStartDateMenu: false,
      summaryStartDate: this.$moment(new Date()).format('YYYY-MM-DD'),
      summaryEndDateMenu: false,
      summaryEndDate: this.$moment(new Date()).format('YYYY-MM-DD'),
      summaryTestItemMenu: [],
      summaryTestItem: [],
      summaryTestItemAllSelect: false,
      summaryExportItem: [],
      manufacturingDateList: [],
      evaluationSpecStandard: 0.8,
      evaluationSpecStatus: 'qualified',
      evaluationSpecBgColor: 'green',
      evaluationSpecColor: 'light-green',
      evaluationSpecIcon: 'mdi-check',
      CpkGradeList: [],
      chartPie: new Vue(),
      stationDistributionChartLine: [],
      stationDistributionDetailTable: [],
      responseDateStart: '',
      responseDateEnd: '',
      responseModelAndFactory: '',
      responseProductStation: '',
      responseTestResult: '',
      responseStatisticItem: [],
      responseTotalAmount: '0',
      responseYieldRate: 0,
      responseTableShowList: {},
      responseTableShowListOnly: {},
      tableData: {
        yieldRate: {
          responseData: [],
          responseHeaders: [
            {
              align: '',
              sortable: true,
              text: 'Test Result',
              value: 'result'
            }, {
              align: 'right',
              sortable: true,
              text: 'Test Amount(times)',
              value: 'amount'
            }, {
              align: 'right',
              sortable: true,
              text: 'Test Proportion(%)',
              value: 'proportion'
            }
          ],
          pagination: {
            sortBy: 'amount',
            descending: true
          }
        },
        deviceList: {
          checkList: [],
          responseData: [],
          responseHeaders: [
            {
              align: 'center',
              sortable: false,
              text: '標記',
              value: ''
            }, {
              align: '',
              sortable: true,
              text: 'Device Serial Number',
              value: 'deviceIsn'
            }, {
              align: 'right',
              sortable: true,
              text: 'Pass times',
              value: 'passAmount'
            }, {
              align: 'right',
              sortable: true,
              text: 'Fail times',
              value: 'failAmount'
            }, {
              align: '',
              sortable: true,
              text: 'Test Result',
              value: 'result'
            }
          ],
          keyword: '',
          rowsAmount: [10, 25, 50, 100],
          pagination: {
            page: 1,
            rowsPerPage: 10,
            sortBy: '',
            descending: null
          },
          pageslist: [],
          pages: 0
        },
        deviceSelectList: {
          checkList: [],
          responseData: [],
          responseHeaders: [
            {
              align: 'center',
              sortable: false,
              text: '操作',
              value: ''
            }, {
              align: '',
              sortable: true,
              text: 'Device Serial Number',
              value: 'deviceIsn'
            }, {
              align: 'right',
              sortable: true,
              text: 'Pass times',
              value: 'passAmount'
            }, {
              align: 'right',
              sortable: true,
              text: 'Fail times',
              value: 'failAmount'
            }, {
              align: '',
              sortable: true,
              text: 'Test Result',
              value: 'result'
            }
          ],
          keyword: '',
          rowsAmount: [10, 25, 50, 100],
          pagination: {
            page: 1,
            rowsPerPage: 10,
            sortBy: '',
            descending: null
          },
          pageslist: [],
          pages: 0
        },
        stationCpk: {
          checkALL: null,
          checkList: [],
          responseData: [],
          responseDataCompile: [],
          responseHeaders: [
            {
              align: 'center',
              sortable: false,
              type: null,
              text: '標記',
              value: ''
            }, {
              align: '',
              sortable: true,
              type: 'keyword',
              text: 'Test Item',
              value: 'testItemName'
            }, {
              align: 'right',
              sortable: true,
              type: 'formula',
              text: '上限',
              value: 'upperSpecValue'
            }, {
              align: 'right',
              sortable: true,
              type: 'formula',
              text: '下限',
              value: 'lowerSpecValue'
            }, {
              align: 'right',
              sortable: true,
              type: 'formula',
              text: 'CPK',
              value: 'cpk'
            }, {
              align: '',
              sortable: true,
              type: 'keyword',
              text: 'CPK評等',
              value: 'level'
            }
          ],
          rowsAmount: [10, 25, 50, 100],
          pagination: {
            page: 1,
            rowsPerPage: 10,
            sortBy: '',
            descending: null
          },
          pageslist: [],
          pages: 0
        }
      }
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    filterDirections () {
      let result = ''
      if (this.filterInfo.type === 'formula') {
        if (this.filterBtnSelect.indexOf('le') > -1 && this.filterBtnSelect.indexOf('ge') > -1) {
          result = '介於' + this.filterMinValue + '~' + this.filterMaxValue + '之間'
        } else if (this.filterBtnSelect.indexOf('le') > -1) {
          result = '小於等於' + this.filterMaxValue
        } else if (this.filterBtnSelect.indexOf('ge') > -1) {
          result = '大於等於' + this.filterMinValue
        }
      } else if (this.filterInfo.type === 'keyword') {
        result = '包含「' + (this.filterKeyword || '') + '」的字詞'
      }
      return result
    },
    chartPieDate () {
      const array = []
      this.tableData.yieldRate.responseData.forEach((r) => {
        array.push([r.result, r.amount])
      })
      return array
    },
    options_pie () {
      return {
        data: {
          columns: this.chartPieDate,
          hide: (this.chartPieDate.length > 1 ? 'Success' : ''),
          type: 'pie'
        },
        pie: {
          label: {
            format: (value, ratio, id) => {
              return (ratio * 100).toFixed(2) + '%'
            }
          }
        },
        tooltip: {
          format: {
            title: (d, value) => {
              return d
            },
            value: (value, ratio, id) => {
              return value
            }
          }
        },
        legend: {
          position: 'right',
          padding: 0,
          show: true
        },
        size: {
          width: ((window.innerWidth - this.shrink) / 2) + 100,
          height: 400
        }
      }
    }
  },
  watch: {
    summaryModelAndFactory (val) {
      this.getProductStation(val)
    },
    summaryProductStation (val) {
      this.getManufacturingDate(val)
    },
    startDate (val) {
      this.startDateMenu = false
    },
    endDate (val) {
      this.endDateMenu = false
    },
    'tableData.stationCpk.checkList' (val) {
      if (val.length > 4) {
        this.tableData.stationCpk.checkList.splice(4, 1)
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '分析統計項目上限為4項'
      }
    },
    'tableData.stationCpk.checkALL' (val) {
      if (!this.processingView) {
        if (val) {
          this.tableData.stationCpk.responseData.forEach((r, rr) => {
            if (this.tableData.stationCpk.checkList.indexOf(r.testItemPk) === -1) {
              this.tableData.stationCpk.checkList.push(r.testItemPk)
            }
          })
        } else {
          this.tableData.stationCpk.responseData.forEach((r, rr) => {
            this.tableData.stationCpk.checkList.splice(this.tableData.stationCpk.checkList.indexOf(r.testItemPk), 1)
          })
        }
      }
    },
    'tableData.deviceList.pagination.rowsPerPage' (val) {
      this.updatedDeviceListTableDate()
    },
    'tableData.deviceList.keyword' (val) {
      this.updatedDeviceListTableDate()
    },
    'tableData.deviceSelectList.pagination.rowsPerPage' (val) {
      this.updatedDeviceSelectListTableDate()
    },
    'tableData.deviceSelectList.keyword' (val) {
      this.updatedDeviceSelectListTableDate()
    },
    'tableData.stationCpk.pagination.rowsPerPage' (val) {
      this.updatedFilterTableDate()
    },
    'tableData.stationCpk.pagination': {
      handler () {
        this.doTableSortDetail()
      },
      deep: true
    },
    summaryTestItem (val) {
      if (val.length === this.summaryTestItemMenu.length) {
        this.summaryTestItemAllSelect = true
      } else {
        this.summaryTestItemAllSelect = false
      }
    },
    filterSettingList (val) {
      this.tableData.stationCpk.responseDataCompile = []
      this.tableData.stationCpk.responseData.forEach((t, tt) => {
        if (this.getFilterResult(t)) {
          this.tableData.stationCpk.responseDataCompile.push(t)
          this.doTableSortDetail()
        }
      })
      this.updatedFilterTableDate()
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/product/analyze/dataAnalyzer')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'dataLog') {
              this.startdate = n.value
            }
          })
          if (this.$route.params.searchDateStart && this.$route.params.searchDateEnd && this.$route.params.searchModelPk && this.$route.params.searchStationPk) {
            this.summaryStartDate = this.$route.params.searchDateStart
            this.summaryEndDate = this.$route.params.searchDateEnd
            this.summaryStatisticItem = ['cpk', 'yield_rate']
            this.tempModelAndFactory = Number(this.$route.params.searchModelPk)
            this.tempProductStation = Number(this.$route.params.searchStationPk)
          }
          this.$http.get('/api/product/fundamental/manufacturingModel').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.summaryModelAndFactoryMenu = []
                res.data.forEach((n) => {
                  this.summaryModelAndFactoryMenu.push({ text: (n.modelName || 'N/A') + ' (' + (n.factoryName || 'N/A') + ')', value: n.pk })
                })
                if (this.tempModelAndFactory !== '') {
                  this.summaryModelAndFactory = this.tempModelAndFactory
                }
              }
            })
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    getProductStation (model) {
      if (model) {
        this.$http.get(`/api/product/fundamental/productStation/${model}`).then(res => res.data)
          .then(res => {
            if (res.status === 'success') {
              this.summaryProductStationMenu = []
              this.summaryProductStation = ''
              res.data.forEach((n) => {
                this.summaryProductStationMenu.push({ text: n.name, value: n.pk })
              })
              if (this.tempProductStation !== '') {
                this.summaryProductStation = this.tempProductStation
                this.doSubmitAndGetStatisticItem()
              }
            }
          })
      }
    },
    getManufacturingDate (productStation) {
      if (productStation) {
        const body = {
          modelPk: this.summaryModelAndFactory,
          stationPk: this.summaryProductStation
        }
        this.$http.get('/api/product/manufacturing/manufacturingDate?' + qs.stringify(body)).then(res => res.data)
          .then(res => {
            if (res.status === 'success') {
              this.manufacturingDateList = res.data
            }
          })
      }
    },
    getCpkGradeList () {
      this.$http.get('/api/product/support/cpkEvaluationList').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.CpkGradeList = res.data
          }
        })
    },
    summaryTestItemListAll () {
      this.summaryTestItem = []
      this.summaryTestItemMenu.forEach((s) => {
        this.summaryTestItem.push(s.value)
      })
    },
    summaryTestItemListClear () {
      this.summaryTestItem = []
    },
    updatedDeviceListTableDate () {
      if (this.tableData.deviceList.pagination.page > this.tableData.deviceList.pages) {
        this.tableData.deviceList.pagination.page = this.tableData.deviceList.pages
      }
      if (this.tableData.deviceList.pagination.page < 1) {
        this.tableData.deviceList.pagination.page = 1
      }

      const num = this.tableData.deviceList.responseData.map((e) => { return e.deviceIsn }).filter(word => word.indexOf(this.tableData.deviceList.keyword) !== -1).length
      this.tableData.deviceList.pages = Math.ceil(num / this.tableData.deviceList.pagination.rowsPerPage)
      this.tableData.deviceList.pageslist = []
      if (this.tableData.deviceList.pagination.rowsPerPage) {
        for (let i = 0; i < Math.ceil(num / this.tableData.deviceList.pagination.rowsPerPage); i++) {
          this.tableData.deviceList.pageslist.push(i + 1)
        }
      }
    },
    updatedDeviceSelectListTableDate () {
      if (this.tableData.deviceSelectList.pagination.page > this.tableData.deviceSelectList.pages) {
        this.tableData.deviceSelectList.pagination.page = this.tableData.deviceSelectList.pages
      }
      if (this.tableData.deviceSelectList.pagination.page < 1) {
        this.tableData.deviceSelectList.pagination.page = 1
      }

      const num = this.tableData.deviceSelectList.responseData.map((e) => { return e.deviceIsn }).filter(word => word.indexOf(this.tableData.deviceSelectList.keyword) !== -1).length
      this.tableData.deviceSelectList.pages = Math.ceil(num / this.tableData.deviceSelectList.pagination.rowsPerPage)
      this.tableData.deviceSelectList.pageslist = []
      if (this.tableData.deviceSelectList.pagination.rowsPerPage) {
        for (let i = 0; i < Math.ceil(num / this.tableData.deviceSelectList.pagination.rowsPerPage); i++) {
          this.tableData.deviceSelectList.pageslist.push(i + 1)
        }
      }
    },
    updatedFilterTableDate () {
      if (this.tableData.stationCpk.pagination.page > this.tableData.stationCpk.pages) {
        this.tableData.stationCpk.pagination.page = this.tableData.stationCpk.pages
      }
      if (this.tableData.stationCpk.pagination.page < 1) {
        this.tableData.stationCpk.pagination.page = 1
      }
      this.tableData.stationCpk.pages = Math.ceil(this.tableData.stationCpk.responseDataCompile.length / this.tableData.stationCpk.pagination.rowsPerPage)
      this.tableData.stationCpk.pageslist = []
      if (this.tableData.stationCpk.pagination.rowsPerPage) {
        for (let i = 0; i < Math.ceil(this.tableData.stationCpk.responseDataCompile.length / this.tableData.stationCpk.pagination.rowsPerPage); i++) {
          this.tableData.stationCpk.pageslist.push(i + 1)
        }
      }
    },
    getFilterResult (array) {
      let result = true
      this.filterSettingDetailList.forEach((n, nn) => {
        if (n !== undefined) {
          if (this.tableData.stationCpk.responseHeaders[nn].type === 'formula') {
            if (n[0].indexOf('le') !== -1 && n[0].indexOf('ge') !== -1) {
              if (!(array[this.tableData.stationCpk.responseHeaders[nn].value] >= n[1] && array[this.tableData.stationCpk.responseHeaders[nn].value] <= n[2])) {
                result = false
              }
            } else if (n[0].indexOf('le') !== -1) {
              if (!(array[this.tableData.stationCpk.responseHeaders[nn].value] <= n[2])) {
                result = false
              }
            } else if (n[0].indexOf('ge') !== -1) {
              if (!(array[this.tableData.stationCpk.responseHeaders[nn].value] >= n[1])) {
                result = false
              }
            }
          } else if (this.tableData.stationCpk.responseHeaders[nn].type === 'keyword') {
            if (array[this.tableData.stationCpk.responseHeaders[nn].value].toUpperCase().indexOf(n.toUpperCase()) === -1) {
              result = false
            }
          }
        }
      })
      return result
    },
    doGoToDeviceStationTestItemStatistic () {
      const sn = this.tableData.deviceSelectList.responseData.map((e) => { return e.deviceIsn })
      const routeData = this.$router.resolve({ path: `/product/analyze/deviceStationTestItemStatistic/${this.responseModelAndFactory}/${this.responseProductStation}/${sn.join()}` })
      window.open(routeData.href, '_blank')
    },
    doTableSort (val) {
      if (this.tableData.stationCpk.pagination.sortBy === val) {
        if (this.tableData.stationCpk.pagination.descending === false) {
          this.tableData.stationCpk.pagination.descending = true
        } else if (this.tableData.stationCpk.pagination.descending === true) {
          this.tableData.stationCpk.pagination.descending = null
          this.tableData.stationCpk.pagination.sortBy = null
        } else if (this.tableData.stationCpk.pagination.descending === null) {
          this.tableData.stationCpk.pagination.descending = false
        }
      } else {
        this.tableData.stationCpk.pagination.sortBy = val
        this.tableData.stationCpk.pagination.descending = false
      }
    },
    doTableSortDetail () {
      if (this.tableData.stationCpk.pagination.descending === null) {
        this.tableData.stationCpk.responseDataCompile.sort((a, b) => {
          return parseFloat(a.index) - parseFloat(b.index)
        })
      } else if (this.tableData.stationCpk.pagination.descending) {
        if (this.tableData.stationCpk.responseHeaders[this.tableData.stationCpk.responseHeaders.map(function (e) { return e.value }).indexOf(this.tableData.stationCpk.pagination.sortBy)].type === 'keyword') {
          this.tableData.stationCpk.responseDataCompile.sort((a, b) => b[this.tableData.stationCpk.pagination.sortBy].localeCompare(a[this.tableData.stationCpk.pagination.sortBy]))
        } else {
          this.tableData.stationCpk.responseDataCompile.sort((a, b) => {
            return parseFloat(b[this.tableData.stationCpk.pagination.sortBy]) - parseFloat(a[this.tableData.stationCpk.pagination.sortBy])
          })
        }
      } else {
        if (this.tableData.stationCpk.responseHeaders[this.tableData.stationCpk.responseHeaders.map(function (e) { return e.value }).indexOf(this.tableData.stationCpk.pagination.sortBy)].type === 'keyword') {
          this.tableData.stationCpk.responseDataCompile.sort((a, b) => a[this.tableData.stationCpk.pagination.sortBy].localeCompare(b[this.tableData.stationCpk.pagination.sortBy]))
        } else {
          this.tableData.stationCpk.responseDataCompile.sort((a, b) => {
            return parseFloat(a[this.tableData.stationCpk.pagination.sortBy]) - parseFloat(b[this.tableData.stationCpk.pagination.sortBy])
          })
        }
      }
    },
    doMinValueReplaceText () {
      this.filterMinValue = this.filterMinValue.replace(/^[^0-9-]*(-?)(\d*(?:\.\d{0,2})?).*$/g, '$1$2')
    },
    doMaxValueReplaceText () {
      this.filterMaxValue = this.filterMaxValue.replace(/^[^0-9-]*(-?)(\d*(?:\.\d{0,2})?).*$/g, '$1$2')
    },
    dochartPieViewOpen () {
      this.chartPieView = true
      this.deviceListView = false
      this.deviceSelectListView = false
    },
    doDeviceSelectListViewOpen () {
      this.tableData.deviceSelectList.pagination.page = 1
      this.chartPieView = false
      this.deviceListView = false
      this.deviceSelectListView = true
    },
    doAddDeviceSelectList () {
      this.tableData.deviceSelectList.checkList = JSON.parse(JSON.stringify(this.tableData.deviceList.checkList))
      this.tableData.deviceSelectList.responseData = []
      this.tableData.deviceList.checkList.forEach(n => {
        this.tableData.deviceSelectList.responseData.push(this.tableData.deviceList.responseData[this.tableData.deviceList.responseData.map((e) => { return e.deviceIsn }).indexOf(n)])
        // if (this.tableData.deviceSelectList.responseData.map((e) => { return e.deviceIsn }).indexOf(n) === -1) {
        //   this.tableData.deviceSelectList.responseData.push(this.tableData.deviceList.responseData[this.tableData.deviceList.responseData.map((e) => { return e.deviceIsn }).indexOf(n)])
        // }
      })
      this.updatedDeviceSelectListTableDate()
      this.snackbar = true
      this.snackbarColor = 'green'
      this.snackbarMsg = '已加入勾選清單'
    },
    doAddDeviceSelectListAll () {
      this.tableData.deviceList.responseData.map((e) => { return e.deviceIsn }).forEach(n => {
        if (this.tableData.deviceList.checkList.indexOf(n) === -1) {
          this.tableData.deviceList.checkList.push(n)
        }
      })
    },
    doRemoveDeviceSelectList (sn) {
      this.tableData.deviceSelectList.responseData.splice([this.tableData.deviceSelectList.responseData.map((e) => { return e.deviceIsn }).indexOf(sn)], 1)
      this.tableData.deviceSelectList.checkList.splice([this.tableData.deviceSelectList.checkList.indexOf(sn)], 1)
      this.updatedDeviceSelectListTableDate()
      this.snackbar = true
      this.snackbarColor = 'green'
      this.snackbarMsg = '已從勾選清單移除'
    },
    doRemoveDeviceSelectListAll () {
      this.tableData.deviceSelectList.responseData = []
      this.tableData.deviceSelectList.checkList = []
      this.updatedDeviceSelectListTableDate()
      this.snackbar = true
      this.snackbarColor = 'green'
      this.snackbarMsg = '已移除勾選清單'
    },
    doCpkGrade (cpk) {
      let result = false
      this.CpkGradeList.forEach(e => {
        if ((e.upperSpecLimit ? e.upperSpecLimit : 999999999999999) > cpk && cpk >= (e.lowerSpecLimit ? e.lowerSpecLimit : -999999999999999)) {
          result = (e.status === 'qualified')
        }
      })
      return result
    },
    doCpkGradeName (cpk) {
      let result = false
      this.CpkGradeList.forEach(e => {
        if ((e.upperSpecLimit ? e.upperSpecLimit : 999999999999999) > cpk && cpk >= (e.lowerSpecLimit ? e.lowerSpecLimit : -999999999999999)) {
          result = e.evaluation
        }
      })
      return result
    },
    dofilterViewOpen (type, index, item) {
      this.filterTitle = item
      this.filterInfo = {
        type: type,
        index: index,
        item: item
      }
      if (type === 'formula') {
        this.filterBtnSelect = this.filterSettingDetailList[index] ? this.filterSettingDetailList[index][0] : []
        this.filterMinValue = this.filterSettingDetailList[index] ? this.filterSettingDetailList[index][1] : 0
        this.filterMaxValue = this.filterSettingDetailList[index] ? this.filterSettingDetailList[index][2] : 0
      } else if (type === 'keyword') {
        this.filterKeyword = this.filterSettingList[index]
      }
      this.filterView = true
    },
    dofilterSetting () {
      if (this.filterInfo.type === 'formula') {
        if (this.filterBtnSelect.indexOf('le') > -1 && this.filterBtnSelect.indexOf('ge') > -1) {
          if (Number(this.filterMaxValue) >= Number(this.filterMinValue)) {
            this.filterSettingDetailList[this.filterInfo.index] = [this.filterBtnSelect, this.filterMinValue, this.filterMaxValue]
            this.filterSettingList.splice(this.filterInfo.index, 1, this.filterMinValue + '≤ X ≤' + this.filterMaxValue)
            this.filterView = false
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '左側數字不得大於右側數字'
          }
        } else if (this.filterBtnSelect.indexOf('le') > -1) {
          this.filterSettingDetailList[this.filterInfo.index] = [this.filterBtnSelect, 0, this.filterMaxValue]
          this.filterSettingList.splice(this.filterInfo.index, 1, '≤' + this.filterMaxValue)
          this.filterView = false
        } else if (this.filterBtnSelect.indexOf('ge') > -1) {
          this.filterSettingDetailList[this.filterInfo.index] = [this.filterBtnSelect, this.filterMinValue, 0]
          this.filterSettingList.splice(this.filterInfo.index, 1, '≥' + this.filterMinValue)
          this.filterView = false
        } else {
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = '請輸入條件'
        }
      } else if (this.filterInfo.type === 'keyword') {
        this.filterSettingDetailList[this.filterInfo.index] = this.filterKeyword
        this.filterSettingList.splice(this.filterInfo.index, 1, this.filterKeyword)
        this.filterView = false
      }
    },
    dofilterClear (index) {
      this.filterSettingDetailList[index] = undefined
      this.filterSettingList.splice(index, 1, undefined)
    },
    dofilterALLClear () {
      this.filterSettingDetailList = new Array(this.tableData.stationCpk.responseHeaders.length + 1)
      this.filterSettingList = new Array(this.tableData.stationCpk.responseHeaders.length + 1)
    },
    getEvaluationSpec () {
      this.$http.get(`/api/product/support/evaluationSpec/${this.summaryModelAndFactory}`).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.evaluationSpecStandard = res.data.standard
            this.evaluationSpecStatus = res.data.status
          }
        })
    },
    doStandardUpperLower (distributionCoverageList, upperSpecValue, lowerSpecValue) {
      const array = []
      let ULindex = upperSpecValue !== null ? -0.5 : null
      let LLindex = lowerSpecValue !== null ? -0.5 : null
      distributionCoverageList.map(function (e) {
        if (e.define.type === 'Sigma') {
          return e.define
        } else {
          return null
        }
      }).filter(Boolean).forEach((r, rr) => {
        if (lowerSpecValue > r.value) {
          LLindex = rr + 0.5
        }
        if (upperSpecValue >= r.value) {
          ULindex = rr + 0.5
        }
      })
      if (ULindex) {
        array.push({ value: ULindex, text: 'Upper Spec Value' })
      }
      if (LLindex) {
        array.push({ value: LLindex, text: 'Lower Spec Value' })
      }
      return array
    },
    doLineChartOptions (testItemName, XaxisName, XaxisStandard, XaxisValue, distributionCoverageList, lines, upperSpecValue, lowerSpecValue) {
      return {
        bindto: '#' + testItemName,
        data: {
          x: 'x',
          columns: [
            XaxisName,
            XaxisStandard,
            XaxisValue
          ],
          axes: {
            常態分佈: 'y2'
          },
          type: 'bar',
          types: {
            常態分佈: 'spline'
          }
        },
        axis: {
          x: {
            type: 'category',
            tick: {
              rotate: 90,
              multiline: false
            },
            padding: {
              top: 0,
              right: 0.1,
              bottom: 0,
              left: 0.2
            }
          },
          y: {
            tick: {
              format: function (value) { return value }
            },
            label: {
              text: '統計次數',
              position: 'outer-middle'
            },
            padding: {
              top: 20,
              right: 0.1,
              bottom: 0,
              left: 0.2
            }
          },
          y2: {
            tick: {
              format: function (value) { return value }
            },
            label: {
              text: '常態分佈',
              position: 'outer-middle'
            },
            show: true
          }
        },
        tooltip: {
          order: function (t1, t2) {
            return t1.id < t2.id
          }
          // format: {
          //   title: (d, value) => {
          //     return `${this.barXaxisName[d + 1]}`
          //   },
          //   value: (value, ratio, id) => {
          //     if (id === '常態分佈') {
          //       return `${value.toFixed(3)}`
          //     } else {
          //       return `${value}`
          //     }
          //   }
          // },
          // order: 'asc'
        },
        grid: {
          x: {
            show: true,
            lines: lines
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'bottom',
          padding: 16,
          show: false
        },
        size: {
          width: ((window.innerWidth - this.shrink) / 3) - 100,
          height: 400
        },
        spline: {
          interpolation: {
            type: 'monotone'
          }
        }
      }
    },
    doChartLineView (index, testItemName, distributionCoverageList, upperSpecValue, lowerSpecValue, average, standardDeviation) {
      const XaxisName = ['x']
      const XaxisStandard = ['常態分佈']
      const XaxisValue = ['統計次數']
      let lines = null
      let options = null
      if (distributionCoverageList.length > 0) {
        distributionCoverageList.forEach((r) => {
          if (r.define.type === 'Sigma') {
            XaxisName.push(r.define.name)
            if (standardDeviation === 0) {
              XaxisStandard.push(r.amount.toFixed(3))
            } else {
              XaxisStandard.push(new NormalDistribution(average, standardDeviation).pdf(r.define.value).toFixed(3))
            }
            XaxisValue.push(r.amount)
          }
        })
      }
      setTimeout(() => {
        lines = this.doStandardUpperLower(distributionCoverageList, upperSpecValue, lowerSpecValue)
      }, 0)
      setTimeout(() => {
        options = this.doLineChartOptions(testItemName, XaxisName, XaxisStandard, XaxisValue, distributionCoverageList, lines, upperSpecValue, lowerSpecValue)
      }, 0)
      setTimeout(() => {
        this.stationDistributionChartLine[index].$emit('init', options)
      }, 0)
    },
    doSubmitAndGetStatisticItem () {
      if (new Date(this.summaryEndDate).getTime() < new Date(this.summaryStartDate).getTime()) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '結尾時間不得早於起始時間'
      } else if ((new Date(this.summaryEndDate).getTime() - new Date(this.summaryStartDate).getTime()) > 86400000 * 90) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '日期區間不得超過90天'
      } else {
        this.loadingView = true
        this.getEvaluationSpec()
        const body = {
          modelPk: this.summaryModelAndFactory,
          modelName: this.summaryModelAndFactoryMenu[this.summaryModelAndFactoryMenu.map(function (e) { return e.value }).indexOf(this.summaryModelAndFactory)].text,
          stationPk: this.summaryProductStation,
          stationName: this.summaryProductStationMenu[this.summaryProductStationMenu.map(function (e) { return e.value }).indexOf(this.summaryProductStation)].text,
          manufacturingDateStart: this.summaryStartDate,
          manufacturingDateEnd: this.summaryEndDate,
          statisticItemList: this.summaryStatisticItem
        }
        this.$http.get('/api/product/manufacturing/stationYieldQualityData?' + qs.stringify(body)).then(res => res.data)
          .then(res => {
            this.loadingView = false
            if (res.status === 'success') {
              this.stationCpkView = false
              this.stationYieldQualityView = false
              this.deviceTestItemView = false
              this.deviceTestItemIsSubmit = false
              this.deviceSelectListView = false
              this.deviceListView = false
              this.chartPieView = false
              this.responseDateStart = this.summaryStartDate
              this.responseDateEnd = this.summaryEndDate
              this.responseModelAndFactory = this.summaryModelAndFactory
              this.responseProductStation = this.summaryProductStation
              this.responseStatisticItem = this.summaryStatisticItem
              this.responseYieldRate = res.data.yieldRate
              this.responseTotalAmount = String(res.data.totalAmount)
              this.evaluationSpecColor = (this.responseYieldRate >= this.evaluationSpecStandard) ? 'light-green' : 'pink'
              this.evaluationSpecBgColor = (this.responseYieldRate >= this.evaluationSpecStandard) ? 'green' : 'red'
              this.evaluationSpecIcon = (this.responseYieldRate >= this.evaluationSpecStandard) ? 'mdi-check' : 'mdi-alert'
              if (this.summaryStatisticItem.indexOf('yield_rate') !== -1) {
                this.tableData.deviceList.responseData = []
                this.tableData.deviceList.checkList = []
                this.tableData.deviceList.keyword = ''
                this.tableData.deviceList.pagination = {
                  page: 1,
                  rowsPerPage: 10,
                  sortBy: '',
                  descending: null
                }
                this.tableData.deviceSelectList.responseData = []
                this.tableData.deviceSelectList.checkList = []
                this.tableData.deviceSelectList.keyword = ''
                this.tableData.deviceSelectList.pagination = {
                  page: 1,
                  rowsPerPage: 10,
                  sortBy: '',
                  descending: null
                }
                this.tableData.yieldRate.responseData = res.data.detail
                if (this.summaryStatisticItem.indexOf('cpk') === -1) {
                  setTimeout(() => {
                    this.chartPie.$emit('init', this.options_pie)
                  }, 0)
                  this.chartPieView = true
                }
                this.stationYieldQualityView = true
              }
              if (this.summaryStatisticItem.indexOf('cpk') !== -1) {
                if (res.data.testItemList.length > 0) {
                  this.summaryTestItemMenu = []
                  this.summaryTestItem = []
                  this.getCpkGradeList()
                  res.data.testItemList.forEach((n) => {
                    if (this.summaryTestItemMenu.map((e) => { return e.text }).indexOf(n.name) === -1) {
                      this.summaryTestItemMenu.push({ text: n.name, value: n.pk })
                    } else {
                      this.summaryTestItemMenu[this.summaryTestItemMenu.map((e) => { return e.text }).indexOf(n.name)].value += ',' + n.pk
                    }
                  })
                  this.deviceTestItemView = true
                } else {
                  setTimeout(() => {
                    this.chartPie.$emit('init', this.options_pie)
                  }, 0)
                  this.chartPieView = true
                  this.deviceTestItemIsSubmit = true
                  alert('輸入區間，查無可統計CPK的測項')
                }
              } else {
                this.deviceTestItemIsSubmit = true
              }
            } else { // failure
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
          .catch(err => {
            this.loadingView = false
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = err
          })
      }
    },
    doSubmitAndGetStationCpk () {
      this.loadingView = true
      const body = {
        modelPk: this.responseModelAndFactory,
        stationPk: this.responseProductStation,
        manufacturingDateStart: this.responseDateStart,
        manufacturingDateEnd: this.responseDateEnd,
        specificTestItem: !this.summaryTestItemAllSelect,
        testItemList: this.summaryTestItem.join().split(',')
      }
      this.$http.get('/api/product/manufacturing/stationCpk?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.tableData.stationCpk.responseData = res.data
            this.tableData.stationCpk.responseData.forEach((n, index) => {
              this.tableData.stationCpk.responseData[index].level = this.doCpkGradeName(n.cpk)
            })
            this.tableData.stationCpk.responseDataCompile = []
            this.tableData.stationCpk.responseData.forEach((t, tt) => {
              if (this.getFilterResult(t)) {
                this.tableData.stationCpk.responseDataCompile.push(t)
                this.doTableSortDetail()
              }
            })
            this.tableData.stationCpk.pages = Math.ceil(this.tableData.stationCpk.responseDataCompile.length / this.tableData.stationCpk.pagination.rowsPerPage)
            this.tableData.stationCpk.pageslist = []
            this.tableData.stationCpk.pagination = {
              page: 1,
              rowsPerPage: 10,
              sortBy: '',
              descending: null
            }
            if (this.tableData.stationCpk.pagination.rowsPerPage) {
              for (let i = 0; i < Math.ceil(this.tableData.stationCpk.responseDataCompile.length / this.tableData.stationCpk.pagination.rowsPerPage); i++) {
                this.tableData.stationCpk.pageslist.push(i + 1)
              }
            }
            if (this.responseStatisticItem.indexOf('yield_rate') !== -1) {
              setTimeout(() => {
                this.chartPie.$emit('init', this.options_pie)
              }, 0)
              this.chartPieView = true
            }
            this.filterSettingDetailList = new Array(6)
            this.filterSettingList = new Array(6)
            this.tableData.stationCpk.checkALL = null
            this.tableData.stationCpk.checkList = []
            this.stationDistributionChartLine = []
            this.stationDistributionDetailTable = []
            this.deviceTestItemIsSubmit = true
            this.stationCpkView = true
          } else { // failure
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
        .catch(err => {
          this.loadingView = false
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = err
        })
    },
    doSubmitAndGetDeviceStatistic (pk, result) {
      this.loadingView = true
      const body = {
        modelPk: this.responseModelAndFactory,
        stationPk: this.responseProductStation,
        manufacturingDateStart: this.responseDateStart,
        manufacturingDateEnd: this.responseDateEnd,
        result: result
      }
      this.$http.get('/api/product/manufacturing/deviceStatistic?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.tableData.deviceList.keyword = ''
            this.tableData.deviceSelectList.keyword = ''
            this.tableData.deviceList.checkList = JSON.parse(JSON.stringify(this.tableData.deviceSelectList.checkList))
            this.tableData.deviceList.responseData = res.data
            this.tableData.deviceList.pagination.page = 1
            this.tableData.deviceList.pages = Math.ceil(this.tableData.deviceList.responseData.length / this.tableData.deviceList.pagination.rowsPerPage)
            this.tableData.deviceList.pageslist = []
            if (this.tableData.deviceList.pagination.rowsPerPage) {
              for (let i = 0; i < Math.ceil(this.tableData.deviceList.responseData.length / this.tableData.deviceList.pagination.rowsPerPage); i++) {
                this.tableData.deviceList.pageslist.push(i + 1)
              }
            }
            this.responseTestResult = result
            this.chartPieView = false
            this.deviceSelectListView = false
            this.deviceListView = true
            this.stationYieldQualityView = true
          } else { // failure
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
        .catch(err => {
          this.loadingView = false
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = err
        })
    },
    doSubmitAndGetStationDistributionInfo () {
      this.loadingView = true
      const body = {
        modelPk: this.responseModelAndFactory,
        stationPk: this.responseProductStation,
        manufacturingDateStart: this.responseDateStart,
        manufacturingDateEnd: this.responseDateEnd,
        testItemList: this.tableData.stationCpk.checkList
      }

      this.$http.get('/api/product/manufacturing/stationDistributionInfo?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          this.nowTime = new Date().getTime()
          if (res.status === 'success') {
            if (res.data.length > 0) {
              this.stationDistributionChartLine = []
              this.stationDistributionDetailTable = []
              res.data.forEach((x, xx) => {
                this.stationDistributionChartLine.push(new Vue())
                this.stationDistributionDetailTable.push(x)
                setTimeout(() => {
                  this.doChartLineView(xx, x.testItemName, x.distributionCoverageList, x.upperSpecValue, x.lowerSpecValue, x.average, x.standardDeviation)
                }, 0)
              })
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = '目前暫無資料'
            }
            setTimeout(() => {
              this.loadingView = false
            }, 1000)
          } else { // failure
            this.loadingView = false
            alert('系統錯誤，請洽系統管理人員\n錯誤訊息：' + res.errMsg)
          }
        })
        .catch(err => {
          this.loadingView = false
          alert('系統錯誤，請洽系統管理人員\n錯誤訊息：' + err)
        })
    },
    doDownload () {
      this.processingView = true
      const body = {
        modelPk: this.responseModelAndFactory,
        stationPk: this.responseProductStation,
        manufactureStart: this.responseDateStart,
        manufactureEnd: this.responseDateEnd,
        exportItem: this.summaryExportItem
      }
      this.$http.post('/api/product/manufacturing/stationYieldQualityDataFile', body, { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                alert(res.data.data)
                // resolve(Promise.reject(res.data.data))
              }
              reader.onerror = () => {
                reject(res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '無原始檔案'
          }
        })
    }
  }
}
</script>
<style>
</style>
