<template>
  <v-content>
    <v-container
      fill-height
      fluid
      class="m-0 p-0">
      <v-layout
        align-center
        justify-center>
        <v-flex
          xs12>
          <!-- <h1>{{ viewName }}</h1> -->
          <!-- <pre style="word-wrap: break-word; white-space: pre-wrap;">{{ viewLog }}</pre> -->
          <pre style="height: 100vh;margin-bottom: 0;">{{ viewLog }}</pre>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
export default {
  name: 'ViewThermalLog',
  data: function () {
    return {
      summaryTtrsPk: '',
      summaryMemo: '',
      // viewName: '',
      viewLog: ''
    }
  },
  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  mounted () {
    this.$store.dispatch('getAuthorize', '/viewThermalLog')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          if (this.$route.params.ttrsPk) {
            this.summaryTtrsPk = Number(this.$route.params.ttrsPk)
          }
          if (this.$route.params.memo) {
            this.summaryMemo = this.$route.params.memo
          }
          this.loadData()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    loadData () {
      this.$http.post('/api/thermal/viewDailyBurnInOriginalData?ttrsPk=' + this.summaryTtrsPk).then(res => res.data)
        .then(res => {
          if (res.status !== 'failure') {
            this.viewLog = res.data
            // this.viewName = res.headers['content-disposition'].split(';')[1].split('=')[1]
          } else {
            this.viewLog = '無原始檔案'
          }
        })
    }
  }
}
</script>

<style>
html {
  overflow-y: hidden!important;
}
</style>
