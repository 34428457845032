<template>
  <v-container
    csc
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
        lg12
      >
        <material-card
          title="料件資料搜尋"
          class="card-padding v-card__first"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  style="margin-top: -5px;margin-bottom: -15px;"
                  xs12
                  lg12>
                  <v-radio-group
                    v-model="operationType"
                    class="align-items-center"
                    row>
                    <v-radio
                      label="搜尋上傳紀錄"
                      value="uploadRecord"/>
                    <v-radio
                      label="搜尋詳細資料"
                      value="detailData"/>
                  </v-radio-group>
                </v-flex>
                <v-flex
                  flex-small
                  border-primary
                  xs12
                  lg12
                  style="border-top: 2px dashed;"/>
                <v-flex
                  v-if="operationType === 'uploadRecord'"
                  flex-small
                  d-flex
                  align-items-center
                  xs12
                  lg4>
                  <p class="m-0">上傳時間</p>
                  <v-menu
                    v-model="uploadedStartDateMenu"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    style="max-width: 170px;margin-left: 10px;"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="uploadedStartDate"
                      label=""
                      prepend-icon="event"
                    />
                    <v-date-picker
                      v-model="uploadedStartDate"
                      :min="startdate"
                      :max="new Date() | date-format"
                      :color="theme==='dark'?'danger':'general'"
                      type="date"
                      no-title
                      scrollable
                      actions/>
                  </v-menu>
                  <p class="m-0 text-center px-3">到</p>
                  <v-menu
                    v-model="uploadedEndDateMenu"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    style="max-width: 170px;margin-left: 10px;"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="uploadedEndDate"
                      label=""
                      prepend-icon="event"
                    />
                    <v-date-picker
                      v-model="uploadedEndDate"
                      :min="uploadedStartDate"
                      :max="new Date() | date-format"
                      :color="theme==='dark'?'danger':'general'"
                      type="date"
                      no-title
                      scrollable
                      actions/>
                  </v-menu>
                </v-flex>
                <v-flex
                  v-if="operationType === 'uploadRecord'"
                  flex-small
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="type"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="typeMenu"
                    label="類別"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  v-if="operationType === 'uploadRecord'"
                  flex-small
                  xs12
                  sm6
                  lg2>
                  <v-text-field
                    v-model="fileName"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="檔名"/>
                </v-flex>
                <v-flex
                  v-if="operationType === 'uploadRecord'"
                  flex-small
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="status"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="statusMenu"
                    label="狀態"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  v-if="operationType === 'uploadRecord'"
                  xs12
                  lg2
                  text-xs-right
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmitBeforeForUploadRecord()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
                  </v-btn>
                </v-flex>
                <v-flex
                  v-if="operationType === 'detailData'"
                  lg4>
                  <v-layout
                    style="margin-top: -9px;"
                    wrap>
                    <v-flex
                      flex-small
                      xs12
                      sm6
                      lg4>
                      <v-autocomplete
                        v-model="model"
                        :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                        :items="modelMenu"
                        label="CFR_Model_1"
                        class="v-text-field__slot"
                        persistent-hint
                        outlined
                      />
                    </v-flex>
                    <v-flex
                      flex-small
                      xs12
                      sm6
                      lg4>
                      <v-text-field
                        v-model="modelYear"
                        :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                        label="Model_Year"/>
                    </v-flex>
                    <v-flex
                      flex-small
                      xs12
                      sm6
                      lg4>
                      <v-autocomplete
                        v-model="partName"
                        :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                        :items="partNameMenu"
                        label="Part Name"
                        class="v-text-field__slot"
                        persistent-hint
                        outlined
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex
                  v-if="operationType === 'detailData'"
                  flex-small
                  xs12
                  sm6
                  lg2>
                  <v-text-field
                    v-model="partNo"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="Part Number"/>
                </v-flex>
                <v-flex
                  v-if="operationType === 'detailData'"
                  flex-small
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="vender"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="venderMenu"
                    label="Vender"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  v-if="operationType === 'detailData'"
                  flex-small
                  xs12
                  sm6
                  lg2>
                  <v-text-field
                    v-model="spec"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="Spec"/>
                </v-flex>
                <v-flex
                  v-if="operationType === 'detailData'"
                  xs12
                  lg2
                  text-xs-right
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmitBeforeForDetailData()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
                  </v-btn>
                  <v-btn
                    class="mr-0 ml-3 font-weight-light"
                    color="pink"
                    @click="doAddWindows()"
                  >
                    <v-icon class="mr-2">mdi-plus</v-icon>新增
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        flex_table_width_auto
        xs12
        lg12>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          :title="'料件資料「'+operationTypeName+'」查詢結果列表'"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <v-select
            v-model="pagination.current.rowsPerPage"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          />
          <input
            v-show="false"
            id="upload-file"
            type="file"
            data-target="file-uploader"
            persistent-hint
            @change="setFile($event)">
          <v-btn
            v-if="operationType === 'uploadRecord'"
            class="mix-btn black text-light"
            style="right: 8rem;"
            @click="uploadFile(6)">
            <v-icon class="float-left">mdi-upload</v-icon>Material Spec
          </v-btn>
          <v-btn
            v-if="operationType === 'uploadRecord'"
            class="mix-btn black text-light"
            style="right: 18rem;"
            @click="uploadFile(8)">
            <v-icon class="float-left">mdi-upload</v-icon>Active Part
          </v-btn>
          <!--
          <v-btn
            v-if="operationType === 'uploadRecord'"
            class="mix-btn black text-light"
            style="right: 26.7rem;"
            @click="uploadFile(9)">
            <v-icon class="float-left">mdi-upload</v-icon>Active Part(補)
          </v-btn>
          -->
          <!-- <v-btn
            class="mix-btn black text-light"
            style="right: 12.5rem;"
            @click="uploadFile(1)">
            <v-icon class="float-left">mdi-upload</v-icon>Active
          </v-btn>
          <v-btn
            class="mix-btn black text-light"
            style="right: 18.25rem;"
            @click="uploadFile(2)">
            <v-icon class="float-left">mdi-upload</v-icon>Sales
          </v-btn>
          <v-btn
            class="mix-btn black text-light"
            style="right: 23.5rem;"
            @click="uploadFile(3)">
            <v-icon class="float-left">mdi-upload</v-icon>EMS
          </v-btn>
          <v-btn
            class="mix-btn black text-light"
            style="right: 28.1rem;"
            @click="uploadFile(5)">
            <v-icon class="float-left">mdi-upload</v-icon>SFIS
          </v-btn> -->
          <v-data-table
            :headers="responseHeaders"
            :items="responseData"
            :pagination.sync="pagination.current"
            :rows-per-page-items ="rowsAmount"
            class="height-row-3 table-align-left"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr v-if="operationType === 'uploadRecord'">
                <td>{{ item.fileName }}</td>
                <td>{{ item.uploadedDate }}</td>
                <td>{{ item.finishedDate }}</td>
                <td>{{ item.type }}</td>
                <td>{{ item.status }}</td>
                <td>
                  <div>
                    <v-btn
                      class="mx-0 font-weight-bold small"
                      color="orange"
                      @click="doDownload(item.pk)">
                      <v-icon class="mr-2">mdi-download</v-icon>下載
                    </v-btn>
                  </div>
                </td>
              </tr>
              <tr v-if="operationType === 'detailData'">
                <td>{{ item.model }}</td>
                <td>{{ item.modelYear }}</td>
                <td>{{ item.partName }}</td>
                <td>{{ item.partNo }}</td>
                <td>{{ item.vender }}</td>
                <td>{{ item.spec }}</td>
                <td>
                  <div>
                    <v-btn
                      class="ml-0 mr-2 font-weight-bold small"
                      color="deep-purple"
                      @click="doEditWindows(item.pk, item.model, item.modelYear, item.partName, item.partNo, item.vender, item.spec)">
                      <v-icon class="mr-2">mdi-pencil</v-icon>修改
                    </v-btn>
                    <v-btn
                      class="mx-0 font-weight-bold small"
                      color="red"
                      @click="doDelete(item.pk)">
                      <v-icon class="mr-2">mdi-close</v-icon>刪除
                    </v-btn>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div
            v-if="pagination.current.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="pagination.page"
              :length="pages"/>
          </div>
          <v-autocomplete
            v-if="pagination.current.rowsPerPage != -1"
            v-model="pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          />
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="windowsForAddOrEdit"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="25%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="windowsForAddOrEdit"
            class="mt-3 mb-3 border-0"
            color="deep-orange">
            <strong class="h4">{{ (action==='add')?'新增':'修改' }}料件資料詳細資料</strong>
          </v-alert>
          <v-flex
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0">CFR_MODEL_1</p>
            <v-text-field
              v-model="windows.model"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              style="max-width: 205px;"
              label=""/>
          </v-flex>
          <v-flex
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0">MODEL_YEAR</p>
            <v-autocomplete
              v-model="windows.type"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              :items="[
                { text: 'NR', value: 'NR' },
                { text: 'DT', value: 'DT' }
              ]"
              label=""
              class="v-text-field__slot"
              style="max-width: 75px;margin-right: 10px;"
              persistent-hint
              outlined
            />
            <v-text-field
              v-model="windows.modelYear"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              style="max-width: 120px;"
              label=""/>
          </v-flex>
          <v-flex
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0">Part Name</p>
            <v-text-field
              v-model="windows.partName"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              style="max-width: 205px;"
              label=""/>
          </v-flex>
          <v-flex
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0">Part Number</p>
            <v-text-field
              v-model="windows.partNo"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              style="max-width: 205px;"
              label=""/>
          </v-flex>
          <v-flex
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0">Vender</p>
            <v-text-field
              v-model="windows.vender"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              style="max-width: 205px;"
              label=""/>
          </v-flex>
          <v-flex
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0">Spec</p>
            <v-text-field
              v-model="windows.spec"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              style="max-width: 205px;"
              label=""/>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="red"
            small
            @click="windowsForAddOrEdit=false">
            <v-icon class="mr-2">mdi-close</v-icon>取消
          </v-btn>
          <v-btn
            v-if="action==='add'"
            color="blue"
            small
            @click="doAdd()">
            <v-icon class="mr-2">mdi-plus</v-icon>新增
          </v-btn>
          <v-btn
            v-if="action==='edit'"
            color="blue"
            small
            @click="doEdit()">
            <v-icon class="mr-2">mdi-content-save</v-icon>儲存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import qs from 'qs'
import $ from 'jquery'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'MaterialSpecMgmt',
  data () {
    return {
      action: 'add',
      windowsForAddOrEdit: false,
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      operationType: 'uploadRecord',
      operationTypeName: '上傳紀錄',
      uploadedStartDateMenu: false,
      uploadedStartDate: '',
      uploadedStartDate_check: '',
      uploadedEndDateMenu: false,
      uploadedEndDate: '',
      uploadedEndDate_check: '',
      fileName: '',
      fileName_check: '',
      type: '',
      type_check: '',
      typeMenu: [],
      status: '',
      status_check: '',
      statusMenu: [],
      model: '',
      model_check: '',
      modelMenu: [],
      modelYear: '',
      modelYear_check: '',
      partName: '',
      partName_check: '',
      partNameMenu: [],
      partNo: '',
      partNo_check: '',
      vender: '',
      vender_check: '',
      venderMenu: [],
      spec: '',
      spec_check: '',
      file: [
        {
          file: '',
          name: '',
          url: ''
        }
      ],
      fileType: -1,
      windows: {
        pk: 0,
        model: '',
        type: '',
        modelYear: '',
        partName: '',
        partNo: '',
        vender: '',
        spec: ''
      },
      submit: false,
      IsSubmitPage: false,
      responseData: [],
      responseHeaders: [
        {
          align: '',
          class: '',
          sortable: true,
          text: '原始檔名',
          value: 'fileName'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '上傳時間',
          value: 'uploadedDate'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '處理完成時間',
          value: 'finishedDate'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '類別',
          value: 'type'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '狀態',
          value: 'status'
        }, {
          align: '',
          class: '',
          sortable: false,
          text: '操作',
          value: 'operation'
        }
      ],
      // rowsAmount: [25, 50, 100, { text: 'All', value: -1 }],
      rowsAmount: [10, 25, 50, 100],
      pagination: {
        page: 1,
        current: {
          descending: true,
          page: 1,
          rowsPerPage: 25,
          sortBy: 'uploadedDate',
          totalItems: 0
        }
      },
      pages: 1
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    // pages () {
    //   if(this.responseData.length > 0) {
    //     return this.pagination.rowsPerPage ? Math.ceil(this.responseData.length / this.pagination.rowsPerPage) : 10
    //   }
    // },
    pageslist () {
      let i = 0
      const list = []
      if (this.pagination.current.rowsPerPage) {
        for (i = 0; i < Math.ceil(this.pagination.current.totalItems / this.pagination.current.rowsPerPage); i++) {
          list.push(i + 1)
        }
      }
      return list
    }
  },
  watch: {
    startDate (val) {
      this.startDateMenu = false
    },
    endDate (val) {
      this.endDateMenu = false
    },
    operationType (val) {
      if (val === 'uploadRecord') {
        this.operationTypeName = '上傳紀錄'
        this.responseHeaders = [
          {
            align: '',
            class: '',
            sortable: true,
            text: '原始檔名',
            value: 'fileName'
          }, {
            align: '',
            class: '',
            sortable: true,
            text: '上傳時間',
            value: 'uploadedDate'
          }, {
            align: '',
            class: '',
            sortable: true,
            text: '處理完成時間',
            value: 'finishedDate'
          }, {
            align: '',
            class: '',
            sortable: true,
            text: '類別',
            value: 'type'
          }, {
            align: '',
            class: '',
            sortable: true,
            text: '狀態',
            value: 'status'
          }, {
            align: '',
            class: '',
            sortable: false,
            text: '操作',
            value: 'operation'
          }
        ]
        this.pagination = {
          page: 1,
          current: {
            descending: true,
            page: 1,
            rowsPerPage: 25,
            sortBy: 'uploadedDate',
            totalItems: 0
          }
        }
        this.pages = 1
        this.doSubmitBeforeForUploadRecord(true)
        this.snackbar = true
        this.snackbarColor = 'green'
        this.snackbarMsg = '已切換至「上傳紀錄搜尋」條件設定及結果列表'
      } else if (val === 'detailData') {
        this.operationTypeName = '詳細資料'
        this.responseHeaders = [
          {
            align: '',
            class: '',
            sortable: true,
            text: 'Model',
            value: 'model'
          }, {
            align: '',
            class: '',
            sortable: true,
            text: 'Model Year',
            value: 'modelYear'
          }, {
            align: '',
            class: '',
            sortable: true,
            text: 'Part Name',
            value: 'partName'
          }, {
            align: '',
            class: '',
            sortable: true,
            text: 'Part Number',
            value: 'partNo'
          }, {
            align: '',
            class: '',
            sortable: true,
            text: 'Vender',
            value: 'vender'
          }, {
            align: '',
            class: '',
            sortable: true,
            text: 'Spec',
            value: 'spec'
          }, {
            align: '',
            class: '',
            sortable: false,
            text: '操作',
            value: 'operation'
          }
        ]
        this.pagination = {
          page: 1,
          current: {
            descending: true,
            page: 1,
            rowsPerPage: 25,
            sortBy: 'model',
            totalItems: 0
          }
        }
        this.pages = 1
        this.doSubmitBeforeForDetailData(true)
        this.snackbar = true
        this.snackbarColor = 'green'
        this.snackbarMsg = '已切換至「詳細資料搜尋」條件設定及結果列表'
      }
    },
    // perPageRows (val) {
    //   if (val === -1) {
    //     if (confirm('完整顯示將可能導致效能降低，是否仍完整顯示?')) {
    //       this.pagination.current.rowsPerPage = val
    //       this.pages = Math.ceil(this.pagination.current.totalItems / this.pagination.current.rowsPerPage)
    //       this.pagination.page = 1
    //     } else {
    //       setTimeout(() => {
    //         this.perPageRows = this.pagination.current.rowsPerPage
    //       }, 100)
    //     }
    //   } else {
    //     this.pagination.current.rowsPerPage = val
    //     this.pages = Math.ceil(this.pagination.current.totalItems / this.pagination.current.rowsPerPage)
    //     this.pagination.page = 1
    //   }
    // },
    pagination: {
      handler (newName, oldName) {
        if (this.submit && !this.IsSubmitPage) {
          if (this.operationType === 'uploadRecord') {
            this.doSubmitForUploadRecord()
          } else if (this.operationType === 'detailData') {
            this.doSubmitForDetailData()
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/pqm/cscDataParsingMgmt/uploadParsingDataListView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'csc') {
              this.startdate = n.value
            }
          })
          this.typeMenu = [
            { text: 'All', value: 'All' },
            { text: 'ActivePart', value: 'ActivePart' },
            { text: 'Spec', value: 'Spec' }
          ]
          this.$http.get('/api/pqm/getSelectOptionData/uploadParsingFileStatus').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.statusMenu = [{ text: 'All', value: 'All' }].concat(res.data)
              }
            })
          this.$http.get('/api/pqm/cfrAnalysisMgmt/getYearlyCfrAnalysisViewSelectOptionData/model').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.modelMenu = res.data
              }
            })
          this.$http.get('/api/pqm/cscDataParsingMgmt/MaterialSpecMgmt/partName').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.partNameMenu = res.data
              }
            })
          this.$http.get('/api/pqm/cscDataParsingMgmt/MaterialSpecMgmt/vender').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.venderMenu = res.data
              }
            })
          this.doSubmitForUploadRecord()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    doAddWindows () {
      if (this.operationType === 'detailData') {
        this.action = 'add'
        this.windows = {
          pk: 0,
          model: '',
          type: '',
          modelYear: '',
          partName: '',
          partNo: '',
          vender: '',
          spec: ''
        }
        this.windowsForAddOrEdit = true
      }
    },
    doEditWindows (pk, model, modelYear, partName, partNo, vender, spec) {
      if (this.operationType === 'detailData') {
        this.action = 'edit'
        this.windows = {
          pk: pk,
          model: model,
          type: modelYear.slice(0, modelYear.length - 5),
          modelYear: modelYear.slice(modelYear.length - 4, modelYear.length),
          partName: partName,
          partNo: partNo,
          vender: vender,
          spec: spec
        }
        this.windowsForAddOrEdit = true
      }
    },
    doSubmitBeforeForUploadRecord (toggle) {
      this.uploadedStartDate_check = this.uploadedStartDate
      this.uploadedEndDate_check = this.uploadedEndDate
      this.fileName_check = this.fileName
      this.type_check = (this.type === 'All' || this.type === '') ? ['Spec', 'ActivePart'] : this.type
      this.status_check = this.status
      this.pagination.page = 1
      if (!toggle) {
        this.doSubmitForUploadRecord()
      }
    },
    doSubmitBeforeForDetailData (toggle) {
      this.model_check = this.model
      this.modelYear_check = this.modelYear
      this.partName_check = this.partName
      this.partNo_check = this.partNo
      this.vender_check = this.vender
      this.spec_check = this.spec
      this.pagination.page = 1
      if (!toggle) {
        this.doSubmitForDetailData()
      }
    },
    doSubmitForUploadRecord () {
      this.loadingView = true
      this.submit = true
      const body = {
        pageNum: this.pagination.page,
        pageRows: this.pagination.current.rowsPerPage,
        sortColumn: (this.pagination.current.descending !== null) ? this.pagination.current.sortBy : '',
        sortType: this.pagination.current.descending ? 'DESC' : ((this.pagination.current.descending !== null) ? 'ASC' : ''),
        uploadedStartDate: this.uploadedStartDate_check,
        uploadedEndDate: this.uploadedEndDate_check,
        fileName: this.fileName_check,
        status: this.status_check,
        type: (this.type_check === 'All' || this.type_check === '') ? ['Spec', 'ActivePart'] : [this.type_check]

      }
      this.$http.post('/api/pqm/cscDataParsingMgmt/uploadParsingDataList', body).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.responseData = res.data.data
            this.IsSubmitPage = true
            this.pagination.current.totalItems = res.data.totalRows
          }
          return res
        })
        .then(res => {
          if (res.status === 'success') {
            this.pages = Math.ceil(res.data.totalRows / this.pagination.current.rowsPerPage)
            this.IsSubmitPage = false
          }
          this.loadingView = false
        })
    },
    doSubmitForDetailData () {
      this.loadingView = true
      this.submit = true
      const body = {
        pageNum: this.pagination.page,
        pageRows: this.pagination.current.rowsPerPage,
        sortColumn: (this.pagination.current.descending !== null) ? this.pagination.current.sortBy : '',
        sortType: this.pagination.current.descending ? 'DESC' : ((this.pagination.current.descending !== null) ? 'ASC' : ''),
        model: this.model_check,
        modelYear: this.modelYear_check,
        partName: this.partName_check,
        partNo: this.partNo_check,
        vender: this.vender_check,
        spec: this.spec_check
      }
      this.$http.get('/api/pqm/cscDataParsingMgmt/MaterialSpecMgmt/partSpecDefinition?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.responseData = res.data.data
            this.IsSubmitPage = true
            this.pagination.current.totalItems = res.data.totalRows
          }
          return res
        })
        .then(res => {
          if (res.status === 'success') {
            this.pages = Math.ceil(res.data.totalRows / this.pagination.current.rowsPerPage)
            this.IsSubmitPage = false
          }
          this.loadingView = false
        })
    },
    uploadFile (type) {
      if (!this.file[0].url) {
        this.fileType = type
        $('#upload-file').click()
      }
    },
    cancelFile () {
      this.file[0].file = this.file[0].name = this.file[0].url = ''
      $('#upload-file').val('')
    },
    setFile (e) {
      const files = e.target.files || e.dataTransfer.files
      this.file[0].url = window.URL.createObjectURL(e.srcElement.files.item(0))
      this.file[0].file = files[0]
      this.file[0].name = e.srcElement.files.item(0).name
      this.doUpload()
    },
    doUpload () {
      this.processingView = true
      const formData = new FormData()
      formData.append('file', this.file[0].file)
      this.$http.post(`/api/pqm/cscDataParsingMgmt/uploadParsingFile/${this.fileType}`, formData).then(res => res.data)
        .then(res => {
          this.processingView = false
          if (res.status === 'success') {
            this.snackbar = true
            this.snackbarColor = 'green'
            this.snackbarMsg = '檔案上傳成功'
            this.cancelFile()
            this.doSubmit()
          } else { // failure
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            this.cancelFile()
          }
        })
      this.fileType = -1
    },
    doAdd () {
      this.submit = true
      this.windowsForAddOrEdit = false
      this.processingView = true
      const body = {
        model: this.windows.model,
        modelYear: this.windows.type + ' ' + this.windows.modelYear,
        partName: this.windows.partName,
        partNo: this.windows.partNo,
        vender: this.windows.vender,
        spec: this.windows.spec
      }
      this.$http.post('/api/pqm/cscDataParsingMgmt/MaterialSpecMgmt/partSpecDefinition', body).then(res => res.data)
        .then(res => {
          this.processingView = false
          if (res.status === 'success') {
            this.snackbar = true
            this.snackbarColor = 'green'
            this.snackbarMsg = '新增成功'
            this.doSubmit()
          } else { // failure
            this.windowsForAddOrEdit = true
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
    },
    doEdit () {
      this.submit = true
      this.windowsForAddOrEdit = false
      this.processingView = true
      const body = {
        model: this.windows.model,
        modelYear: this.windows.type + ' ' + this.windows.modelYear,
        partName: this.windows.partName,
        partNo: this.windows.partNo,
        vender: this.windows.vender,
        spec: this.windows.spec
      }
      this.$http.put(`/api/pqm/cscDataParsingMgmt/MaterialSpecMgmt/partSpecDefinition/${this.windows.pk}`, body).then(res => res.data)
        .then(res => {
          this.processingView = false
          if (res.status === 'success') {
            this.snackbar = true
            this.snackbarColor = 'green'
            this.snackbarMsg = '修改成功'
            this.doSubmit()
          } else { // failure
            this.windowsForAddOrEdit = true
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
    },
    doDelete (pk) {
      if (confirm('是否確認刪除?')) {
        this.processingView = true
        this.$http.delete(`/api/pqm/cscDataParsingMgmt/MaterialSpecMgmt/partSpecDefinition/${pk}`).then(res => res.data)
          .then(res => {
            this.processingView = false
            if (res.status === 'success') {
              this.snackbar = true
              this.snackbarColor = 'green'
              this.snackbarMsg = '刪除成功'
              this.doSubmit()
            } else { // failure
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      }
    },
    doDownload (key) {
      this.processingView = true
      this.$http.post(`/api/pqm/cscDataParsingMgmt/downloadParsingFile/${key}`, null, { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.status === 'success') {
                  alert(res.data.data)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '無檔案可供下載'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    }
  }
}
</script>
<style>
</style>
