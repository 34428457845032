<template>
  <v-container
    krypart
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
      >
        <material-card
          title="面板環境潔淨度管制"
          class="card-mix card-shrink card-padding v-card__first"
          color="general"
        >
          <v-btn
            class="mix-btn mx-0 font-weight-light small"
            @click="chartSearchData=[];chartColumnsData = { Chart_ParticleMeasure_Array: [], Chart_ParticleMeasure_Assembly: [], Chart_ParticleMeasure_Bonding: [] }; chartWeeksData = { Chart_ParticleMeasure_Array: [], Chart_ParticleMeasure_Assembly: [], Chart_ParticleMeasure_Bonding: [] }"
          >清除重置</v-btn>
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout
                wrap
                align-items-center>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryVendorName"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryVendorNameMenu"
                    label="廠商"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryPartNumber"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryPartNumberMenu"
                    label="料號"
                    class="v-text-field__slot"
                    color="white"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg3>
                  <v-autocomplete
                    v-model="summaryType"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="['Array', 'Assembly', 'Bonding']"
                    :disabled="chartSearchData.filter((element) => element.show === true).length>0"
                    label="類別"
                    class="v-text-field__slot"
                    color="white"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                  />
                </v-flex>
                <v-flex
                  flex-small
                  d-flex
                  align-items-center
                  xs12
                  lg4>
                  <v-autocomplete
                    v-model="summaryWeeksStart"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAllYearWeeksList"
                    label="起始時間"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  >
                    <template v-slot:item="data">
                      <div
                        :class="{'weeks-list-data-tip':contentDateListWeeks.indexOf(data.item.value)!==-1}"
                        class="v-list__tile__content">
                        <div class="v-list__tile__title">
                          {{ data.item.text }}
                        </div>
                      </div>
                    </template>
                  </v-autocomplete>
                  <p class="m-0 text-center px-3">到</p>
                  <v-autocomplete
                    v-model="summaryWeeksEnd"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAllYearWeeksList"
                    label="結尾時間"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  >
                    <template v-slot:item="data">
                      <div
                        :class="{'weeks-list-data-tip':contentDateListWeeks.indexOf(data.item.value)!==-1}"
                        class="v-list__tile__content">
                        <div class="v-list__tile__title">
                          {{ data.item.text }}
                        </div>
                      </div>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex
                  xs12
                  lg1
                  text-xs-right
                >
                  <v-btn
                    :disabled="summaryWeeksStart===''||summaryWeeksEnd===''||summaryVendorName===''||summaryPartNumber===''"
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>加入條件
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        v-show="chartSearchData.filter((element) => element.show === true).length>0"
        lg3
      >
        <material-card
          :title="'Particle Measure 條件'"
          class="card-padding v-card__first"
          color="deep-orange"
        >
          <v-input
            v-for="(search,s) in chartSearchData"
            v-show="search.show"
            :key="'s'+s"
            :messages="search.manufactureStart +' 至 '+ search.manufactureEnd"
            class="px-3 py-2 ml-4"
          >
            <div
              :style="'height: 20px; width: 20px; margin-left: -1.75rem; margin-bottom: -10px; background-color:'+colorList[s]"
              class="avatar mr-2 text-center"><span style="color:#000000;font-weight: 900!important;">{{ search.lineName }}</span></div>
            <div style="margin-bottom: -10px;">{{ search.vendorName }} / {{ search.partNumber }}</div>
            <v-icon
              class="ml-2"
              style="cursor: pointer; margin-bottom: -10px;"
              @click="delChartSearchData(search.lineName)">mdi-close-circle</v-icon>
          </v-input>
        </material-card>
      </v-flex>
      <v-flex
        v-show="chartSearchData.filter((element) => element.show === true).length>0"
        lg9
      >
        <v-layout wrap>
          <material-card
            v-if="summaryType.includes('Array')"
            :title="'Particle Measure Array 圖表'"
            class="card-padding v-card__first"
            color="deep-orange"
          >
            <div class="data-area c3-tip-weeks">
              <vue-c3
                :key="time+1"
                :handler="chartData.Chart_ParticleMeasure_Array"/>
            </div>
          </material-card>
          <material-card
            v-if="summaryType.includes('Assembly')"
            :title="'Particle Measure Assembly 圖表'"
            class="card-padding v-card__first"
            color="deep-orange"
          >
            <div class="data-area c3-tip-weeks">
              <vue-c3
                :key="time+2"
                :handler="chartData.Chart_ParticleMeasure_Assembly"/>
            </div>
          </material-card>
          <material-card
            v-if="summaryType.includes('Bonding')"
            :title="'Particle Measure Bonding 圖表'"
            class="card-padding v-card__first"
            color="deep-orange"
          >
            <div class="data-area c3-tip-weeks">
              <vue-c3
                :key="time+3"
                :handler="chartData.Chart_ParticleMeasure_Bonding"/>
            </div>
          </material-card>
        </v-layout>
      </v-flex>
      <!-- <v-flex
        v-show="processingView"
        lg8
      >
        <material-card
          title="Yield Rate"
          class="card-padding v-card__first"
          color="red-3"
        >
          <div class="data-area">
            <vue-c3 :handler="chartData.Chart_YieldRate"/>
          </div>
        </material-card>
      </v-flex>
      <v-flex
        v-show="processingView"
        lg4
      >
        <material-card
          title="Particle Measure Array"
          class="card-padding v-card__first"
          color="yellow-2"
        >
          <div class="data-area">
            <vue-c3 :handler="chartData.Chart_ParticleMeasure_Array"/>
          </div>
        </material-card>
      </v-flex>
      <v-flex
        v-show="processingView"
        lg4
      >
        <material-card
          title="Particle Measure Assembly"
          class="card-padding v-card__first"
          color="yellow-1"
        >
          <div class="data-area">
            <vue-c3 :handler="chartData.Chart_ParticleMeasure_Assembly"/>
          </div>
        </material-card>
      </v-flex>
      <v-flex
        v-show="processingView"
        lg4
      >
        <material-card
          title="Particle Measure Bonding"
          class="card-padding v-card__first"
          color="yellowgreen-4"
        >
          <div class="data-area">
            <vue-c3 :handler="chartData.Chart_ParticleMeasure_Bonding"/>
          </div>
        </material-card>
      </v-flex> -->
    </v-layout>
    <v-dialog
      v-if="chartSearchData.length===0"
      v-model="processloadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-dialog
      v-if="chartSearchData.length===0"
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import qs from 'qs'
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'PanelParticleMeasureAnalyzer',
  components: {
    VueC3
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      overload: false,
      overloadMsg: '',
      processingView: false,
      time: new Date().getTime(),
      summaryNoData: true,
      summaryLoading: {
        Chart_ParticleMeasure_Array: false,
        Chart_ParticleMeasure_Assembly: false,
        Chart_ParticleMeasure_Bonding: false
      },
      summaryMonth: this.$moment(new Date()).format('YYYY-MM'),
      summaryWeeksStart: Number(this.$moment(new Date()).format('YYYY')) * 100 + Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6),
      summaryWeeksEnd: Number(this.$moment(new Date()).format('YYYY')) * 100 + Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6),
      summaryVendorNameMenu: [],
      summaryPartNumberMenu: [],
      summaryVendorName: '',
      summaryPartNumber: '',
      summaryType: [],
      summaryYearWeeksList: [],
      summaryAllYearWeeksList: [],
      tempVendorName: '',
      tempPartNumber: '',
      contentDateList: [],
      responseVendorName: '',
      responsePartNumber: '',
      responseDateStart: '',
      responseDateEnd: '',
      responseWeeksStart: '',
      responseWeeksEnd: '',
      lineName: 0,
      chartSearchData: [],
      chartColumnsData: {
        Chart_ParticleMeasure_Array: [],
        Chart_ParticleMeasure_Assembly: [],
        Chart_ParticleMeasure_Bonding: []
      },
      chartWeeksData: {
        Chart_ParticleMeasure_Array: [],
        Chart_ParticleMeasure_Assembly: [],
        Chart_ParticleMeasure_Bonding: []
      },
      chartData: {
        Chart_ParticleMeasure_Array: new Vue(),
        Chart_ParticleMeasure_Assembly: new Vue(),
        Chart_ParticleMeasure_Bonding: new Vue()
      },
      chartSetting: {
        Chart_ParticleMeasure_Array: {
          chartPath: 'particleMeasureStatisticData',
          widthRate: 9,
          legendShow: true,
          queryType: 'Array',
          type: 'line',
          types: {}, // {常態分佈: 'spline'}
          axes: {}, // {常態分佈: 'y2'}
          yKey: 'value',
          yType: 'number',
          y2show: false
        },
        Chart_ParticleMeasure_Assembly: {
          chartPath: 'particleMeasureStatisticData',
          widthRate: 9,
          legendShow: true,
          queryType: 'Assembly',
          type: 'line',
          types: {}, // {常態分佈: 'spline'}
          axes: {}, // {常態分佈: 'y2'}
          yKey: 'value',
          yType: 'number',
          y2show: false
        },
        Chart_ParticleMeasure_Bonding: {
          chartPath: 'particleMeasureStatisticData',
          widthRate: 9,
          legendShow: true,
          queryType: 'Bonding',
          type: 'line',
          types: {}, // {常態分佈: 'spline'}
          axes: {}, // {常態分佈: 'y2'}
          yKey: 'value',
          yType: 'number',
          y2show: false
        }
      },
      WeekList: ['日', '一', '二', '三', '四', '五', '六'],
      colorList: [
        '#1f77b4',
        '#ff7f0e',
        '#aec7e8',
        '#ffbb78',
        '#2ca02c',
        '#98df8a',
        '#d62728',
        '#ff9896',
        '#9467bd',
        '#c5b0d5',
        '#8c564b',
        '#c49c94',
        '#e377c2',
        '#f7b6d2',
        '#7f7f7f',
        '#c7c7c7',
        '#bcbd22',
        '#dbdb8d',
        '#17becf',
        '#9edae5',
        '#FF0000',
        '#00FF00',
        '#0000FF',
        '#FFFF00',
        '#FF00FF',
        '#00FFFF',
        '#000000',
        '#FFFFFF'
      ]
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    processloadingView () {
      if (!this.summaryLoading.Chart_ParticleMeasure_Array && !this.summaryLoading.Chart_ParticleMeasure_Assembly && !this.summaryLoading.Chart_ParticleMeasure_Bonding) {
        return false
      } else {
        return true
      }
    },
    responseDataCompileFailList () {
      const array = []
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.responseCpkData)) {
        value.map(function (e) { return e.item }).forEach((n, nn) => {
          n.forEach((i, ii) => {
            if (i.value !== null && !this.doCpkGrade(i.value)) {
              if (array.map(function (e) { return e.type }).indexOf(key) === -1) {
                array.push({ type: key, list: [{ name: i.testItemName, value: i.value }] })
              } else {
                array[array.map(function (e) { return e.type }).indexOf(key)].list.push({ name: i.testItemName, value: i.value })
              }
            }
          })
        })
      }
      return array
    },
    contentDateListWeeks () {
      const array = []
      this.summaryYearWeeksList.forEach((n, nn) => {
        n.forEach((w, ww) => {
          if (ww === n.length - 1 && Number(this.$moment(w.start).format('DD')) > 25) {
            for (let d = new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn) + '-' + this.$moment(w.start).format('MM-DD')).getTime(); d <= new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn + 1) + '-' + this.$moment(w.end).format('MM-DD')).getTime(); d = d + 86400000) {
              // console.log(this.$moment(d).format('YYYY-MM-DD'))
              if (this.contentDateList.indexOf(this.$moment(d).format('YYYY-MM-DD')) !== -1) {
                array.push(Number((Number(this.$moment(this.startdate).format('YYYY')) + nn) + (ww < 10 ? '0' + String(ww) : String(ww))))
              }
            }
          }
          for (let d = new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn) + '-' + this.$moment(w.start).format('MM-DD')).getTime(); d <= new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn) + '-' + this.$moment(w.end).format('MM-DD')).getTime(); d = d + 86400000) {
            // console.log(this.$moment(d).format('YYYY-MM-DD'))
            if (this.contentDateList.indexOf(this.$moment(d).format('YYYY-MM-DD')) !== -1) {
              array.push(Number((Number(this.$moment(this.startdate).format('YYYY')) + nn) + (ww < 10 ? '0' + String(ww) : String(ww))))
            }
          }
        })
      })
      return array
    }
  },
  watch: {
    processloadingView (val) {
      if (!val && !this.overload) {
        this.processingView = true
      } else if (!val && this.overload) {
        alert(this.overloadMsg)
      }
    },
    summaryVendorName (val) {
      this.getPartNumber(val)
    },
    summaryPartNumber (val) {
      this.contentDateList = []
      if (val) this.getContentDateList(this.summaryVendorName, val)
    },
    summaryType (val) {
      this.time = new Date().getTime()
      this.chartSearchData = []
      this.chartColumnsData = { Chart_ParticleMeasure_Array: [], Chart_ParticleMeasure_Assembly: [], Chart_ParticleMeasure_Bonding: [] }
      this.chartWeeksData = { Chart_ParticleMeasure_Array: [], Chart_ParticleMeasure_Assembly: [], Chart_ParticleMeasure_Bonding: [] }
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/keypart/lcdModule/panelParticleMeasureAnalyzer')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'lcdModule') {
              this.startdate = n.value
            }
          })
          if (this.$route.params.searchWeeksStart && this.$route.params.searchWeeksEnd && this.$route.params.searchVendorName && this.$route.params.searchPartNumber) {
            this.summaryWeeksStart = Number(this.$route.params.searchWeeksStart)
            this.summaryWeeksEnd = Number(this.$route.params.searchWeeksEnd)
            this.tempVendorName = this.$route.params.searchVendorName
            this.tempPartNumber = this.$route.params.searchPartNumber
          }
          this.$http.get('/api/keypart/lcdModule/vendorName').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.summaryVendorNameMenu = []
                res.data.forEach((n) => {
                  this.summaryVendorNameMenu.push({ text: n, value: n })
                })
                if (this.tempVendorName !== '') {
                  this.summaryVendorName = this.tempVendorName
                }
              }
            })
          this.loadData()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    async loadData () {
      this.loadingView = true
      await this.YearWeeksList()
      await this.AllYearWeeksList()
    },
    YearWeeksList () {
      const Arr = []
      const YearStart = this.$moment(this.startdate).format('YYYY')
      const YearEnd = this.$moment(new Date()).format('YYYY')
      let NewYear = false
      let YearEndWeek = 0

      for (let y = 0; y <= (YearEnd - YearStart + 1); y++) {
        Arr[y] = []
        let w = 0
        let n = 0
        let day = new Date(Number(YearStart) + y + '-01-01').getTime()
        if (y === 0 && this.$moment(this.startdate).format('MM-DD') !== '01-01') {
          day = new Date(Number(YearStart) + y + '-' + this.$moment(this.startdate).format('MM-DD')).getTime()
          w = Math.ceil((((new Date(this.$moment(this.startdate).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(this.startdate).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(this.startdate).format('YYYY'), 0, 1)).getDay()) / 7)
          if (new Date(this.$moment(this.startdate).format('YYYY') + '-01-01').getDay() > 0) {
            w = w - 1
          }
          Arr[y][w] = { start: this.$moment(this.startdate).format('YYYY-MM-DD'), end: '' }
          if (new Date(this.$moment(this.startdate).format('YYYY-MM-DD')).getDay() === 6) {
            Arr[y][w].end = this.$moment(this.startdate).format('YYYY-MM-DD')
          } else if (new Date(this.$moment(this.startdate).format('YYYY-MM-DD')).getDay() === 0) {
            w = w - 1
          }
        } else if (new Date(Number(YearStart) + y + '-01-01').getDay() !== 0) {
          n = 6 - new Date(Number(YearStart) + y + '-01-01').getDay()
          Arr[y][1] = { start: '', end: '' }
        }
        for (let d = (day + (86400000 * n)); d <= (new Date(Number(YearStart) + y + '-12-31').getTime()); d = d + 86400000) {
          if (y > 0 && new Date(d).getDay() === 6) {
            if (Arr[y][1].start === '') {
              Arr[y - 1][YearEndWeek].end = this.$moment(d).format('YYYY-MM-DD')
              Arr[y][0] = { start: Arr[y - 1][YearEndWeek].start, end: this.$moment(d).format('YYYY-MM-DD') }
              w = 0
              YearEndWeek = w
              NewYear = true
            }
          }
          if (new Date(d).getDay() === 0) {
            w++
            YearEndWeek = w
            NewYear = false
            Arr[y][w] = { start: this.$moment(d).format('YYYY-MM-DD'), end: '' }
          } else if (!NewYear && new Date(d).getDay() === 6) {
            Arr[y][w].end = this.$moment(d).format('YYYY-MM-DD')
          }
        }
      }
      this.summaryYearWeeksList = Arr
    },
    AllYearWeeksList () {
      const Arr = []
      const YearStart = this.$moment(this.startdate).format('YYYY')
      let YearEnd = this.$moment(this.summaryMonth).format('YYYY')
      let LastYear = 0
      if (new Date(this.$moment(this.summaryMonth).format('YYYY') + '-01-01').getDay() > 0) {
        LastYear = 1
      }
      const WeeksEnd = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1)).getDay()) / 7) - LastYear
      if (WeeksEnd === 0) {
        YearEnd--
      }
      for (let y = 0; y <= (YearEnd - YearStart); y++) {
        for (let w = 1; w < this.summaryYearWeeksList[y].length; w++) {
          if (y === (YearEnd - YearStart) && w === WeeksEnd) {
            Arr.push({ text: (Number(YearStart) + y) + '　w' + w + '　( ' + this.$moment(this.summaryYearWeeksList[y][w].start).format('MM-DD') + ' ～ ' + this.$moment(this.summaryYearWeeksList[y][w].end).format('MM-DD') + ' )', value: (Number(YearStart) + y) * 100 + w })
            break
          }
          if (this.summaryYearWeeksList[y][w]) {
            Arr.push({ text: (Number(YearStart) + y) + '　w' + w + '　( ' + this.$moment(this.summaryYearWeeksList[y][w].start).format('MM-DD') + ' ～ ' + this.$moment(this.summaryYearWeeksList[y][w].end).format('MM-DD') + ' )', value: (Number(YearStart) + y) * 100 + w })
          }
        }
      }
      Arr.sort(function (a, b) { return b.value - a.value })
      this.summaryAllYearWeeksList = Arr
      this.loadingView = false
    },
    responseCpkDataCompileGroup (groupType, data) {
      const array = []
      const d = data.sort((a, b) => a.testItemName.localeCompare(b.testItemName))

      d.forEach((i, ii) => {
        if (array.map(function (e) { return e.buttonCode }).indexOf(i[groupType]) === -1) {
          array.push({ buttonCode: i[groupType], item: [i] })
        } else {
          array[array.map(function (e) { return e.buttonCode }).indexOf(i[groupType])].item.push(i)
        }
      })
      return array
    },
    getNameTag (val, type, isTag) {
      if (type === 'brackets') {
        if (isTag) {
          return val.substr((val.indexOf('(') + 1), (val.indexOf(')') - val.indexOf('(') - 1))
        } else {
          return val.substr(0, val.indexOf('('))
        }
      } else if (type === 'b-line') {
        if (isTag) {
          return val.substr((val.lastIndexOf('_') + 1), val.length)
        } else {
          return val.substr(0, (val.lastIndexOf('_') + 1))
        }
      } else if (type === 'space') {
        if (isTag) {
          return val.substr((val.lastIndexOf(' ') + 1), val.length)
        } else {
          return val.substr(0, (val.lastIndexOf(' ') + 1))
        }
      }
    },
    getPartNumber (vendor) {
      const body = {
        vendorName: vendor
      }
      this.$http.get('/api/keypart/lcdModule/partNumber?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.summaryPartNumberMenu = []
            this.summaryPartNumber = ''
            res.data.forEach((n) => {
              this.summaryPartNumberMenu.push({ text: n, value: n })
            })
            if (this.tempPartNumber !== '') {
              this.summaryPartNumber = this.tempPartNumber
              this.tempVendorName = ''
              this.tempPartNumber = ''
              this.doSubmit()
            }
          }
        })
    },
    getContentDateList (vendor, partNumber) {
      const body = {
        vendorName: vendor,
        partNumber: partNumber
      }
      this.$http.get('/api/keypart/lcdModule/manufacturingDate?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.contentDateList = res.data
          }
        })
    },
    delChartSearchData (lineName) {
      this.chartSearchData[this.chartSearchData.findIndex((element) => element.lineName === lineName)].show = false

      if (this.summaryType.includes('Array')) {
        this.chartData.Chart_ParticleMeasure_Array.$emit('dispatch', chart => {
          const options = {
            ids: lineName
          }
          chart.unload(options)
        })
      }

      if (this.summaryType.includes('Assembly')) {
        this.chartData.Chart_ParticleMeasure_Assembly.$emit('dispatch', chart => {
          const options = {
            ids: lineName
          }
          chart.unload(options)
        })
      }

      if (this.summaryType.includes('Bonding')) {
        this.chartData.Chart_ParticleMeasure_Bonding.$emit('dispatch', chart => {
          const options = {
            ids: lineName
          }
          chart.unload(options)
        })
      }
    },
    async doSubmit () {
      if (this.summaryWeeksEnd < this.summaryWeeksStart) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '結尾時間不得早於起始時間'
      } else if (this.chartSearchData.filter((element) => element.show === true).map((item) => item.vendorName + '.' + item.partNumber).findIndex((element) => element === this.summaryVendorName + '.' + this.summaryPartNumber) !== -1) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '已有相同條件的廠商及料號組合'
      } else {
        this.processingView = false
        this.summaryNoData = true
        this.summaryLoading = {
          Chart_ParticleMeasure_Array: 'Array'.includes(this.summaryType),
          Chart_ParticleMeasure_Assembly: 'Assembly'.includes(this.summaryType),
          Chart_ParticleMeasure_Bonding: 'Bonding'.includes(this.summaryType)
        }
        this.overload = false
        this.overloadMsg = ''
        let wwS = 0
        let wwE = 0
        if (Number.isInteger(this.summaryWeeksStart)) {
          wwS = String(this.summaryWeeksStart).substr(4)
        } else {
          wwS = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6)
        }
        if (Number.isInteger(this.summaryWeeksEnd)) {
          wwE = String(this.summaryWeeksEnd).substr(4)
        } else {
          wwE = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6)
        }
        const manufactureStart = this.$moment(this.summaryYearWeeksList[String(this.summaryWeeksStart).substr(0, 4) - this.$moment(this.startdate).format('YYYY')][Number(wwS)].start).format('YYYY-MM-DD')
        const manufactureEnd = this.$moment(this.summaryYearWeeksList[String(this.summaryWeeksEnd).substr(0, 4) - this.$moment(this.startdate).format('YYYY')][Number(wwE)].end).format('YYYY-MM-DD')
        const vendorName = this.summaryVendorName
        const partNumber = this.summaryPartNumber

        this.lineName = this.chartSearchData.length + 1
        this.chartSearchData = [...this.chartSearchData, { show: true, lineName: this.lineName, vendorName: vendorName, partNumber: partNumber, manufactureStart: manufactureStart, manufactureEnd: manufactureEnd }]

        await this.doSubmitChart('Chart_ParticleMeasure_Array', vendorName, partNumber, manufactureStart, manufactureEnd)
        await this.doSubmitChart('Chart_ParticleMeasure_Assembly', vendorName, partNumber, manufactureStart, manufactureEnd)
        await this.doSubmitChart('Chart_ParticleMeasure_Bonding', vendorName, partNumber, manufactureStart, manufactureEnd)
      }
    },
    doSubmitChart (type, vendorName, partNumber, manufactureStart, manufactureEnd) {
      if (this.summaryType.includes(this.chartSetting[type].queryType)) {
        const body = {
          manufactureStart: manufactureStart,
          manufactureEnd: manufactureEnd,
          vendorName: vendorName,
          partNumber: partNumber,
          type: this.chartSetting[type].queryType
        }

        this.$http.get(`/api/keypart/lcdModule/${this.chartSetting[type].chartPath}?` + qs.stringify(body)).then(res => res.data)
          .then(res => {
            if (res.status === 'success') {
              if (res.data.length > 0 || typeof res.data === 'object') {
                this.summaryNoData = false

                this.chartColumnsData[type] = [...this.chartColumnsData[type].filter((line) => (this.chartSearchData.filter((element) => element.show === true).map((item) => { return item.lineName }).includes(line[0]))), [this.lineName, ...res.data.map((item) => { return item.value })]]
                this.chartWeeksData[type] = [...this.chartWeeksData[type].filter((line) => (this.chartSearchData.filter((element) => element.show === true).map((item) => { return item.lineName }).includes(line[0]))), [this.lineName, ...res.data.map((item) => { return item.week })]]
                if (this.chartSearchData.length <= 1) {
                  this.chartData[type].$emit('init', this.doChartOptions(type, this.chartColumnsData[type]))
                } else {
                  this.chartData[type].$emit('dispatch', chart => {
                    const options = {
                      columns: this.chartColumnsData[type]
                    }
                    chart.load(options)
                  })
                }

                this.summaryLoading[type] = false
              }
            } else { // failure
              this.overload = true
              this.overloadMsg = '系統錯誤，請洽系統管理人員\n錯誤訊息：' + res.errMsg
              this.summaryLoading[type] = false
            }
          })
          .catch(err => {
            this.overload = true
            this.overloadMsg = '系統錯誤，請洽系統管理人員\n錯誤訊息：' + err
            this.summaryLoading[type] = false
          })
      }
    },
    doChartOptions (type, columns) {
      return {
        data: {
          // x: 'x',
          columns: columns,
          axes: this.chartSetting[type].axes,
          // axes: {
          //   常態分佈: 'y2'
          // },
          type: this.chartSetting[type].type,
          types: this.chartSetting[type].types,
          // types: {
          //   常態分佈: 'spline'
          // }
          colors: this.chartSetting[type].colors
        },
        color: {
          pattern: this.colorList
        },
        axis: {
          x: {
            // type: 'category',
            padding: {
              top: 0,
              right: 0.1,
              bottom: 0,
              left: 0.2
            },
            tick: {
              rotate: 0,
              multiline: false
            }
          },
          y: {
            tick: {
              format: function (value) { return value }
            },
            label: {
              text: '數量',
              position: 'outer-middle'
            },
            padding: {
              bottom: 5
            }
          },
          y2: {
            tick: {
              format: function (value) { return value }
            },
            label: {
              text: '比率',
              position: 'outer-middle'
            },
            padding: {
              bottom: 5
            },
            show: this.chartSetting[type].y2show
          }
        },
        tooltip: {
          // order: function (t1, t2) {
          //   return t1.id < t2.id
          // }
          format: {
            // title: (d, value) => {
            //   return `${d}-${value}` // chartWeeksData
            // },
            value: (value, ratio, id) => {
              return `[${this.chartWeeksData[type].filter((element) => element[0] === id)[0][this.chartColumnsData[type][this.chartColumnsData[type].findIndex((columns) => columns[0] === id)].findIndex((item) => item === value)]}] ${value}`
            }
          }
          // order: 'asc'
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'bottom',
          padding: 16,
          show: this.chartSetting[type].legendShow
        },
        size: {
          width: ((window.innerWidth - this.shrink) * this.chartSetting[type].widthRate / 12) - 64,
          height: 300
        },
        spline: {
          interpolation: {
            type: 'monotone'
          }
        }
      }
    }
  }
}

</script>
<style>
</style>
