<template>
  <v-container
    fundamental
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
        lg9
      >
        <material-card
          title="裝置生產歷程查詢"
          class="card-padding v-card__first"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout
                wrap
                d-flex
                align-items-center>
                <v-flex
                  xs12
                  lg6>
                  <v-text-field
                    v-model="summarySN"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="SN"/>
                </v-flex>
                <v-flex
                  xs12
                  lg6
                  text-right
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        xs12
        lg3
      />
      <v-flex
        v-if="submit"
        xs12
        lg3>
        <material-stats-card
          :value="responsePartNumber"
          style="margin:10px 0 !important;"
          class="v-offset-none"
          icon="mdi-factory"
          title="90P/N"/>
      </v-flex>
      <v-flex
        v-if="submit"
        xs12
        lg6>
        <material-stats-card
          :value="responseSN"
          style="margin:10px 0 !important;"
          class="v-offset-none"
          icon="mdi-factory"
          title="S/N"/>
      </v-flex>
      <v-flex
        v-if="submit"
        flex_table_width_auto
        xs12
        lg9>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="查詢結果"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <v-btn
            class="mix-btn black text-light"
            style="right: 1rem;"
            @click="doGroupDownload(responseSN)">
            <v-icon class="floata-left">mdi-download</v-icon>匯出全部歷程
          </v-btn>
          <ul class="timeline">
            <li
              v-for="(item,i) in responseDataCompile"
              :key="'i'+i"
              :class="{'pass': item.result==='pass','fail': item.result==='failed','period': item.result===null}"
              class="timeline-item">
              <div class="timeline-info">
                <span>{{ $moment(item.trace_start_time).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
              <div class="timeline-marker"/>
              <div class="timeline-content">
                <v-chip
                  v-if="item.result===null"
                  class="timeline-title">{{ item.trace_start_time }}</v-chip>
                <h3
                  v-if="item.result!==null"
                  class="timeline-title">
                  <v-btn
                    v-if="item.result==='failed'"
                    class="font-weight-bold small p-2 mx-2"
                    color="orange"
                    @click="doDownload(responseSN, item.stationName, item.logPathPk)">
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                  <span>{{ item.stationName }}</span>
                  <v-btn
                    v-if="item.result==='pass'"
                    class="font-weight-bold small p-2 mx-2"
                    color="orange"
                    @click="doDownload(responseSN, item.stationName, item.logPathPk)">
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </h3>
                <p v-if="item.result!==null">{{ item.errorMessage }}</p>
              </div>
            </li>
          </ul>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'DeviceManufacturedHistoryView',
  data () {
    return {
      submit: false,
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      summarySN: '',
      responsePartNumber: '',
      responseSN: '',
      responseData: []
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    responseDataCompile () {
      const Array = []
      this.responseData.forEach(e => {
        if (Array.map(function (t) { return t.trace_start_time }).indexOf(this.$moment(e.trace_start_time).format('YYYY-MM-DD')) === -1) {
          Array.push({ errorMessage: null, result: null, stationName: null, trace_start_time: this.$moment(e.trace_start_time).format('YYYY-MM-DD') })
          Array.push(e)
        } else {
          Array.push(e)
        }
      })
      return Array
    }
  },
  watch: {
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/product/analyze/deviceManufacturedHistoryView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          // doing
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    doSubmit () {
      if (this.summarySN === '') {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '請輸入SN'
      } else {
        this.loadingView = true
        this.$http.get(`/api/product/device/deviceHistory/${this.summarySN}`).then(res => res.data)
          .then(res => {
            this.loadingView = false
            if (res.status === 'success') {
              this.submit = true
              this.responseData = res.data.stationList.sort(function (a, b) {
                return parseFloat(new Date(a.trace_start_time).getTime()) - parseFloat(new Date(b.trace_start_time).getTime())
              })
              // this.responseData = res.data.stationList
              this.responsePartNumber = res.data.partNumber
              this.responseSN = res.data.sn
              this.responseModelName = res.data.modelName
              this.responseVendorName = res.data.vendorName
            } else { // failure
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
          .catch(err => {
            this.loadingView = false
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = err
          })
      }
    },
    doDownload (sn, station, pk) {
      this.processingView = true
      this.$http.get(`/api/product/device/exportDeviceProductionHistory/${sn}/${station}/${pk}`, { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.status === 'success') {
                  alert(res.data.data)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '無檔案可供下載'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    },
    doGroupDownload (sn) {
      this.processingView = true
      this.$http.get(`/api/product/device/exportDeviceProductionHistory/${sn}`, { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.status === 'success') {
                  alert(res.data.data)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '無檔案可供下載'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    }
  }
}
</script>
<style>
</style>
