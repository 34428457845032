<template>
  <v-container
    fundamental
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
        lg9
      >
        <material-card
          title="查詢機種"
          class="card-mix card-padding v-card__first"
          color="general"
        >
          <v-btn
            class="mix-btn black text-light"
            style="right: 11rem;"
            @click="doSampleDownload()">
            <v-icon class="float-left">mdi-export</v-icon>匯出範本
          </v-btn>
          <v-btn
            class="mix-btn black text-light"
            style="right: 1rem;"
            @click="uploadFile(0)">
            <v-icon class="float-left">mdi-expand-all</v-icon>匯入機種工站關聯
          </v-btn>
          <input
            v-show="false"
            id="upload-file"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            type="file"
            label="檔名"
            @change="setFile($event)">
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout
                wrap
                d-flex
                align-items-center>
                <v-flex
                  xs12
                  sm6
                  lg3>
                  <v-text-field
                    v-model="summaryModelName"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="機種名稱"/>
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg3>
                  <v-autocomplete
                    v-model="summaryFactory"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryFactoryMenu"
                    label="生產工廠"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryProductType"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryProductTypeMenu"
                    label="產品種類"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryManufacturingType"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryManufacturingTypeMenu"
                    label="生產類型"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  lg2
                  text-right
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmitBefore()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        xs12
        lg3
      />
      <v-flex
        v-if="!processingView"
        flex_table_width_auto
        xs12
        lg9>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="符合篩選條件的機種資訊"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <v-text-field
            ref="keyword"
            v-model="keywordFilter"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            class="mix-input"
            style="right: 8.5rem;"
            append-icon="search"
            label="關鍵字過濾"
            single-line
            hide-details/>
          <v-select
            v-model="pagination.current.rowsPerPage"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          />
          <v-data-table
            :headers="responseHeaders"
            :items="responseData"
            :pagination.sync="pagination.current"
            :rows-per-page-items ="rowsAmount"
            class="height-row-3 table-align-left"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr>
                <td>{{ item.modelName }}</td>
                <td>{{ item.productType }}</td>
                <td>
                  <v-chip
                    v-if="item.factory.name"
                    class="px-1 cursor"
                    @click="doStationListWindows(item.pk,item.modelName,item.factory.name)">
                    <v-icon class="small mr-1">mdi-eye</v-icon>{{ item.factory.name }}
                  </v-chip>
                </td>
                <td>{{ item.manufacturingType }}</td>
                <td>
                  <div>
                    <v-btn
                      v-if="!item.uploadedData"
                      class="ml-0 mr-2 font-weight-bold small"
                      color="deep-purple"
                      @click="doEditWindows(item.pk, item.modelName, item.factory.pk, item.productType, item.manufacturingType)">
                      <v-icon class="mr-2">mdi-pencil</v-icon>修改
                    </v-btn>
                    <!-- <v-btn
                      v-if="item.uploadedData"
                      class="ml-0 mr-2 font-weight-bold small"
                      color="deep-purple"
                      @click="doEditWindows(item.pk, item.modelName, item.factoryList.map(function(e) { return e.pk }), item.productType, item.manufacturingType)">
                      <v-icon class="mr-2">mdi-pencil</v-icon>修改
                    </v-btn> -->
                  </div>
                </td>
                <td>{{ $moment(item.lastModifiedDate).format('YYYY-MM-DD HH:mm:ss') }}</td>
              </tr>
            </template>
          </v-data-table>
          <div
            v-if="pagination.current.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="pagination.page"
              :length="pages"/>
          </div>
          <v-autocomplete
            v-if="pagination.current.rowsPerPage != -1"
            v-model="pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          />
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="windowsForAddOrEdit"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="25%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="windowsForAddOrEdit"
            class="mt-3 mb-3 border-0"
            color="deep-orange">
            <strong class="h4">{{ (action==='add')?'新增':'修改' }}生產工站</strong>
          </v-alert>
          <v-flex
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0 w-25">機種名稱</p>
            <v-text-field
              v-model="windows.modelName"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              placeholder="輸入機種名稱"
              label=""
              class="w-75"/>
          </v-flex>
          <v-flex
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0 w-25">生產工廠</p>
            <v-autocomplete
              v-model="windows.factory"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              :items="factoryMenu"
              placeholder="--請選擇--"
              label=""
              class="w-75 v-text-field__slot"
              persistent-hint
              outlined
            />
          </v-flex>
          <v-flex
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0 w-25">產品種類</p>
            <v-autocomplete
              v-model="windows.productType"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              :items="productTypeMenu"
              placeholder="--請選擇--"
              label=""
              class="w-75 v-text-field__slot"
              persistent-hint
              outlined
            />
          </v-flex>
          <v-flex
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0 w-25">生產類型</p>
            <v-autocomplete
              v-model="windows.manufacturingType"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              :items="manufacturingTypeMenu"
              placeholder="--請選擇--"
              label=""
              class="w-75 v-text-field__slot"
              persistent-hint
              outlined
            />
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="red"
            small
            @click="windowsForAddOrEdit=false">
            <v-icon class="mr-2">mdi-close</v-icon>取消
          </v-btn>
          <!-- <v-btn
            v-if="action==='add'"
            :disabled="windows.modelName==='' || windows.factory==='' || windows.productType==='' || windows.manufacturingType===''"
            color="blue"
            small
            @click="doAdd()">
            <v-icon class="mr-2">mdi-plus</v-icon>新增
          </v-btn> -->
          <v-btn
            v-if="action==='edit'"
            :disabled="windows.modelName==='' || windows.factory==='' || windows.productType==='' || windows.manufacturingType===''"
            color="blue"
            small
            @click="doEdit()">
            <v-icon class="mr-2">mdi-content-save</v-icon>儲存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="windowsForStationList"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="25%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="windowsForStationList"
            class="mt-3 mb-3 border-0"
            color="deep-orange">
            <strong class="h4">{{ stationInfo.modelName }} ｜ {{ stationInfo.factoryName }} 工站一覽</strong>
          </v-alert>
          <v-flex
            xs12
            lg12>
            <p
              v-for="(station,s) in stationList"
              :key="'s'+s"
              class="station-name">
              {{ station }}
            </p>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="red"
            small
            @click="windowsForStationList=false">
            <v-icon class="mr-2">mdi-close</v-icon>關閉
          </v-btn>
          <!-- <v-btn
            v-if="action==='add'"
            color="blue"
            small
            @click="doAdd()">
            <v-icon class="mr-2">mdi-plus</v-icon>新增
          </v-btn> -->
          <v-btn
            v-if="action==='edit'"
            color="blue"
            small
            @click="doEdit()">
            <v-icon class="mr-2">mdi-content-save</v-icon>儲存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import qs from 'qs'
import $ from 'jquery'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'ProjectDefinitionMgmtView',
  data () {
    return {
      action: 'add',
      windowsForAddOrEdit: false,
      windowsForStationList: false,
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      summaryModelName: '',
      summaryFactory: 'All',
      summaryProductType: 'All',
      summaryManufacturingType: 'All',
      summaryFactoryMenu: [],
      summaryFactoryDetailMenu: [],
      summaryProductTypeMenu: [],
      summaryManufacturingTypeMenu: [],
      factoryMenu: [],
      productTypeMenu: [],
      manufacturingTypeMenu: [],
      windows: {
        pk: 0,
        modelName: '',
        factory: '',
        productType: '',
        manufacturingType: ''
      },
      stationList: [],
      stationInfo: {
        factoryPk: 0,
        factoryName: '',
        modelName: ''
      },
      file: [
        {
          file: '',
          name: '',
          url: ''
        }
      ],
      submit: false,
      IsSubmitPage: false,
      timer: 0,
      keywordFilter: '',
      keywordTimer: 0,
      responseData: [],
      responseHeaders: [
        {
          align: '',
          class: '',
          sortable: true,
          text: '機種名稱',
          value: 'modelName'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '產品種類',
          value: 'productType'
        }, {
          align: '',
          class: '',
          sortable: false,
          text: '生產工廠',
          value: '生產工廠'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '生產類型',
          value: 'manufacturingType'
        }, {
          align: '',
          class: '',
          sortable: false,
          text: '操作',
          value: 'operation'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '修改時間',
          value: 'lastModifiedDate'
        }
      ],
      // rowsAmount: [25, 50, 100, { text: 'All', value: -1 }],
      rowsAmount: [10, 25, 50, 100],
      pagination: {
        page: 1,
        current: {
          descending: null,
          page: 1,
          rowsPerPage: 25,
          sortBy: null,
          totalItems: 0
        }
      },
      pages: 1
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    // pages () {
    //   if(this.responseData.length > 0) {
    //     return this.pagination.rowsPerPage ? Math.ceil(this.responseData.length / this.pagination.rowsPerPage) : 10
    //   }
    // },
    pageslist () {
      let i = 0
      const list = []
      if (this.pagination.current.rowsPerPage) {
        for (i = 0; i < Math.ceil(this.pagination.current.totalItems / this.pagination.current.rowsPerPage); i++) {
          list.push(i + 1)
        }
      }
      return list
    }
  },
  watch: {
    // perPageRows (val) {
    //   if (val === -1) {
    //     if (confirm('完整顯示將可能導致效能降低，是否仍完整顯示?')) {
    //       this.pagination.current.rowsPerPage = val
    //       this.pages = Math.ceil(this.pagination.current.totalItems / this.pagination.current.rowsPerPage)
    //       this.pagination.page = 1
    //     } else {
    //       setTimeout(() => {
    //         this.perPageRows = this.pagination.current.rowsPerPage
    //       }, 100)
    //     }
    //   } else {
    //     this.pagination.current.rowsPerPage = val
    //     this.pages = Math.ceil(this.pagination.current.totalItems / this.pagination.current.rowsPerPage)
    //     this.pagination.page = 1
    //   }
    // },
    keywordFilter (val) {
      this.keywordTimer = 0
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(() => {
        if (this.keywordTimer > 0) {
          clearInterval(this.timer)
          this.keywordTimer = 0
          this.doSubmit()
          setTimeout(() => {
            this.$refs.keyword.focus()
          }, 0)
        } else {
          this.keywordTimer++
        }
      }, 300)
    },
    pagination: {
      handler (newName, oldName) {
        if (this.submit && !this.IsSubmitPage) {
          this.doSubmit()
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/product/fundamental/product/projectDefinitionMgmtView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          this.doSubmit()
          this.getFactory()
          this.getProductType()
          this.getManufacturingType()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    getFactory () {
      this.$http.get('/api/product/fundamental/factory').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.summaryFactoryDetailMenu = res.data
            this.summaryFactoryMenu = [{ text: 'All', value: 'All' }]
            this.factoryMenu = []
            res.data.forEach((n) => {
              this.summaryFactoryMenu.push({ text: n.name, value: n.pk })
              this.factoryMenu.push({ text: n.name, value: n.pk })
            })
          }
        })
    },
    getProductType () {
      this.$http.get('/api/product/fundamental/productType').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.summaryProductTypeMenu = [{ text: 'All', value: 'All' }].concat(res.data)
            this.productTypeMenu = res.data
          }
        })
    },
    getManufacturingType () {
      this.$http.get('/api/product/fundamental/productManufacturingType').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.summaryManufacturingTypeMenu = [{ text: 'All', value: 'All' }].concat(res.data)
            this.manufacturingTypeMenu = res.data
          }
        })
    },
    // doAddWindows () {
    //   this.action = 'add'
    //   this.windows = {
    //     pk: 0,
    //     modelName: '',
    //     factory: '',
    //     productType: '',
    //     manufacturingType: ''
    //   }
    //   this.windowsForAddOrEdit = true
    // },
    doEditWindows (pk, modelName, factory, productType, manufacturingType) {
      this.action = 'edit'
      this.windows = {
        pk: pk,
        modelName: modelName,
        factory: factory,
        productType: productType,
        manufacturingType: manufacturingType
      }
      this.windowsForAddOrEdit = true
    },
    doStationListWindows (pk, modelName, factoryName) {
      this.$http.get(`/api/product/fundamental/productStation/${pk}`).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.stationList = res.data.map(function (e) { return e.name })
            this.stationInfo = {
              factoryName: factoryName,
              modelName: modelName
            }
            this.windowsForStationList = true
          }
        })
    },
    uploadFile (type) {
      $('#upload-file').click()
    },
    cancelFile () {
      this.file[0].file = this.file[0].name = this.file[0].url = ''
      $('#upload-file').val('')
    },
    setFile (e) {
      const files = e.target.files || e.dataTransfer.files
      this.file[0].url = window.URL.createObjectURL(e.srcElement.files.item(0))
      this.file[0].file = files[0]
      this.file[0].name = e.srcElement.files.item(0).name
      this.doImportProductStation()
    },
    doSubmitBefore () {
      if (this.pagination.page !== 1) {
        this.pagination.page = 1
      } else {
        this.doSubmit()
      }
    },
    doSubmit () {
      this.submit = true
      this.loadingView = true
      const body = {
        pageNum: this.pagination.page,
        pageRows: this.pagination.current.rowsPerPage,
        sortColumn: (this.pagination.current.descending !== null) ? this.pagination.current.sortBy : '',
        sortType: this.pagination.current.descending ? 'DESC' : ((this.pagination.current.descending !== null) ? 'ASC' : ''),
        modelName: this.summaryModelName,
        factoryPk: this.summaryFactory === 'All' ? '' : this.summaryFactory,
        productType: this.summaryProductType === 'All' ? '' : this.summaryProductType,
        manufacturingType: this.summaryManufacturingType === 'All' ? '' : this.summaryManufacturingType,
        filterKeyword: this.keywordFilter
      }
      this.$http.get('/api/product/fundamental/productStation?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.responseData = res.data.pagingList
            this.IsSubmitPage = true
            this.pagination.current.totalItems = res.data.totalRows
          }
          return res
        })
        .then(res => {
          if (res.status === 'success') {
            this.pages = Math.ceil(res.data.totalRows / this.pagination.current.rowsPerPage)
            this.IsSubmitPage = false
          }
        })
    },
    // doAdd () {
    //   this.submit = true
    //   this.windowsForAddOrEdit = false
    //   this.processingView = true
    //   // let factoryList = []
    //   // this.windows.factory.forEach((n, nn) => {
    //   //   if (this.summaryFactoryDetailMenu.map(function(e) { return e.pk }).indexOf(n) !== -1) {
    //   //     factoryList.push(this.summaryFactoryDetailMenu[this.summaryFactoryDetailMenu.map(function(e) { return e.pk }).indexOf(n)])
    //   //   }
    //   // })
    //   const body = {
    //     pk: this.windows.pk,
    //     modelName: this.windows.modelName,
    //     uploadedData: true,
    //     productType: this.windows.productType,
    //     manufacturingType: this.windows.manufacturingType,
    //     factory: this.windows.factory,
    //   }
    //   this.$http.post('/api/product/fundamental/productStation', body).then(res => res.data)
    //     .then(res => {
    //       this.processingView = false
    //       if (res.status === 'success') {
    //         this.snackbar = true
    //         this.snackbarColor = 'green'
    //         this.snackbarMsg = '新增成功'
    //         this.doSubmit()
    //       } else { // failure
    //         this.windowsForAddOrEdit = true
    //         this.snackbar = true
    //         this.snackbarColor = 'red'
    //         this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
    //       }
    //     })
    // },
    doEdit () {
      this.submit = true
      this.windowsForAddOrEdit = false
      this.processingView = true
      // const factoryList = []
      // this.windows.factory.forEach((n, nn) => {
      //   if (this.summaryFactoryDetailMenu.map(function (e) { return e.pk }).indexOf(n) !== -1) {
      //     factoryList.push(this.summaryFactoryDetailMenu[this.summaryFactoryDetailMenu.map(function (e) { return e.pk }).indexOf(n)])
      //   }
      // })
      const body = {
        pk: this.windows.pk,
        modelName: this.windows.modelName,
        uploadedData: true,
        productType: this.windows.productType,
        manufacturingType: this.windows.manufacturingType,
        factory: this.windows.factory
      }
      this.$http.put('/api/product/fundamental/productStation/', body).then(res => res.data)
        .then(res => {
          this.processingView = false
          if (res.status === 'success') {
            this.snackbar = true
            this.snackbarColor = 'green'
            this.snackbarMsg = '修改成功'
            this.doSubmit()
          } else { // failure
            this.windowsForAddOrEdit = true
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
    },
    doImportProductStation () {
      if (this.file[0].file) {
        this.processingView = true
        const formData = new FormData()
        formData.append('file', this.file[0].file)
        formData.append('sourceFields', ['device_isn'])
        this.$http.post('/api/product/fundamental/importProductStation', formData).then(res => res.data)
          .then(res => {
            this.processingView = false
            this.cancelFile()
            if (res.status === 'success') {
              this.snackbar = true
              this.snackbarColor = 'green'
              this.snackbarMsg = '檔案匯入成功'
              this.doSubmit()
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      } else {
        this.processingView = false
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '檔名或檔案不得為空'
      }
    },
    doSampleDownload () {
      this.processingView = true
      this.$http.get('/api/product/fundamental/importProductStationSampleFile', { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.status === 'success') {
                  alert(res.data.data)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '無檔案可供下載'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    }
  }
}
</script>
<style>
</style>
