<template>
  <v-container
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
      >
        <material-card
          :title="'電池生產品質週報'"
          class="card-padding v-card__first"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout
                wrap
                align-items-center>
                <!-- <v-flex
                  xs12
                  lg4>
                  <v-autocomplete
                    v-model="summaryWeeksStart"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAllYearWeeksList"
                    label="UPLOAD_WEEK"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  lg4>
                  <v-autocomplete
                    v-model="summaryWeeksEnd"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAllYearWeeksList"
                    label="UPLOAD_WEEK"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex> -->
                <v-flex
                  flex-small
                  d-flex
                  align-items-center
                  xs12
                  lg5>
                  <v-autocomplete
                    v-model="summaryWeeksStart"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAllYearWeeksList"
                    label="起始時間"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  >
                    <template v-slot:item="data">
                      <div
                        :class="{'weeks-list-data-tip':contentDateListWeeks.indexOf(data.item.value)!==-1}"
                        class="v-list__tile__content">
                        <div class="v-list__tile__title">
                          {{ data.item.text }}
                        </div>
                      </div>
                    </template>
                  </v-autocomplete>
                  <p class="m-0 text-center px-3">到</p>
                  <v-autocomplete
                    v-model="summaryWeeksEnd"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAllYearWeeksList"
                    label="結尾時間"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  >
                    <template v-slot:item="data">
                      <div
                        :class="{'weeks-list-data-tip':contentDateListWeeks.indexOf(data.item.value)!==-1}"
                        class="v-list__tile__content">
                        <div class="v-list__tile__title">
                          {{ data.item.text }}
                        </div>
                      </div>
                    </template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex
                  xs12
                  sm12
                  lg4>
                  <v-autocomplete
                    v-model="summaryModelName"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryModelNameMenu"
                    label="MODEL_NAME"
                    class="v-text-field__slot"
                    color="white"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                    @change="summaryModelNameMenuChange"
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm12
                  lg7
                >
                  <v-autocomplete
                    v-model="summaryPartNumber"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryPartNumberMenu"
                    label="PART_NUMBER"
                    class="v-text-field__slot"
                    color="white"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm12
                  lg1
                  text-lg-right
                  text-xs-right
                >
                  <v-btn
                    :disabled="summaryWeeksStart===''||summaryWeeksEnd===''"
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    查詢
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex>
        <material-card
          v-for="(partNumtitle, index) in showpartNumber"
          v-show="tabShow"
          :key="'a'+ index"
          :title="'MODEL_NAME: ' + showmodelName[index] + ' ｜ PART_NUMBER: ' + partNumtitle"
          color="deep-orange"
          class="v-card__first"
        >
          <!-- <v-flex>PART_NUMBER: {{ partNumtitle }}</v-flex> -->
          <v-layout
            row
            wrap
          >
            <v-flex
              v-for="(itemMain, index) in tabData.filter(word => word.partNumber == partNumtitle )"
              :key="'b'+ index"
              lg4>
              <v-card :class="{'detail-list-dark' : theme==='dark' , 'detail-list-light' : theme==='light'}">
                <v-card-title style="padding:5px;">
                  <v-flex
                    style="border-bottom:solid 1px #D3D3D3; font-weight: bold;"
                    lg12>
                    {{ itemMain.testItemName }}
                  </v-flex>
                </v-card-title>
                <v-card-text class="detail-layout">
                  <v-flex
                    style="align-self: flex-start;padding: 9px 0px !important;"
                    lg12
                  >
                    <v-list
                      :class="{'detail-list-dark' : theme==='dark' , 'detail-list-light' : theme==='light'}"
                      dense
                    >
                      <v-list-tile>
                        <v-list-tile-content style="overflow: inherit;">Cell_TYPE:</v-list-tile-content>
                        <v-list-tile-content
                          class="align-end"
                          style="text-align: end;">{{ itemMain.cellType }}</v-list-tile-content>
                      </v-list-tile>
                      <v-list-tile>
                        <v-list-tile-content>SPEC:</v-list-tile-content>
                        <v-list-tile-content class="align-end">{{ itemMain.lowerSpecValue }} ~ {{ itemMain.upperSpecValue }}</v-list-tile-content>
                      </v-list-tile>
                      <v-list-tile>
                        <v-list-tile-content>CPK:</v-list-tile-content>
                        <v-list-tile-content
                          v-if= "itemMain.cpk ==='Bad' || itemMain.cpk ==='Ordinary'"
                          class="align-end">
                          <v-list-tile-title style="text-align: right;">
                            <v-icon style="color: #ff9800;">mdi-alert-decagram</v-icon>
                            {{ itemMain.cpkvalue }} ({{ itemMain.cpk }})
                          </v-list-tile-title>
                        </v-list-tile-content>
                        <v-list-tile-content
                          v-if= "itemMain.cpk ==='Excellent'"
                          class="align-end">{{ itemMain.cpkvalue }} ({{ itemMain.cpk }})</v-list-tile-content>
                        <v-list-tile-content
                          v-if= "itemMain.cpk ==='Good'"
                          class="align-end">{{ itemMain.cpkvalue }} ({{ itemMain.cpk }})</v-list-tile-content>
                      </v-list-tile>
                    </v-list>
                  </v-flex>
                  <v-flex
                    lg12
                    style="display: flex;justify-content: space-evenly;"
                  >
                    <v-data-table
                      :headers="distributionTableHeaders"
                      :items="itemMain.table"
                      multi-sort
                      hide-actions
                    >
                      <template
                        slot="headerCell"
                        slot-scope="{ header }"
                      >
                        <span
                          class="subheading font-weight-light text-general text--darken-3"
                          v-text="header.text"
                        />
                      </template>
                      <template
                        slot="items"
                        slot-scope="{ item }"
                      >
                        <tr>
                          <td>
                            {{ item.sigma }}
                          </td>
                          <td>
                            {{ Number(item.value).toFixed(2) }}
                          </td>
                          <td>
                            {{ item.histogram }}
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-flex>
                </v-card-text>
              </v-card>

              <v-flex
                d-flex
                xs12
                lg12>
                <vue-c3 :handler="itemMain.handler" />
              </v-flex>
            </v-flex>
          </v-layout>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import NormalDistribution from 'normal-distribution'
import axios from 'axios'
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'BatteryManufacturingReport',
  components: {
    VueC3
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      tabShow: false,
      summaryMonth: this.$moment(new Date()).format('YYYY-MM'),
      summaryWeeksStart: Number(this.$moment(new Date()).format('YYYY')) * 100 + Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6),
      summaryWeeksEnd: Number(this.$moment(new Date()).format('YYYY')) * 100 + Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6),
      summaryModelNameMenu: [],
      summaryModelName: [],
      summaryPartNumberMenu: [],
      summaryPartNumber: [],
      summaryWeeksList: [],
      summaryYearWeeksList: [],
      showmodelName: [],
      showpartNumber: [],
      tabData: [],
      summaryVendorMenu: [],
      summaryVendor: 'All',
      summaryWeeksRange: '',
      summaryWeeksUnfoldList: [],
      summaryAllYearWeeksList: [],
      contentDateList: [],
      chartLineView: false,
      chartLineViewNum: 0,
      chartLineViewName: '',
      DataStatistcStart: '',
      DataStatistcEnd: '',
      barXaxisName: [],
      barXaxisValue: [],
      ULIndex: 9,
      LLIndex: -1,
      pagination: {
        rowsPerPage: 1,
        sortBy: 'TotalFailRate', // The field that you're sorting by
        descending: true
      },
      distributionTableHeaders: [
        {
          sortable: false,
          text: '標準差'
        }, {
          sortable: false,
          text: '分佈'
        }, {
          sortable: false,
          text: '數量'
        }
      ],
      handler_line: new Vue(),
      SelectTab: 'tab-4',
      tempload: true,
      CpkLv: []
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    contentDateListWeeks () {
      const array = []
      this.summaryYearWeeksList.forEach((n, nn) => {
        n.forEach((w, ww) => {
          if (ww === n.length - 1 && Number(this.$moment(w.start).format('DD')) > 25) {
            for (let d = new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn) + '-' + this.$moment(w.start).format('MM-DD')).getTime(); d <= new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn + 1) + '-' + this.$moment(w.end).format('MM-DD')).getTime(); d = d + 86400000) {
              // console.log(this.$moment(d).format('YYYY-MM-DD'))
              if (this.contentDateList.indexOf(this.$moment(d).format('YYYY-MM-DD')) !== -1) {
                array.push(Number((Number(this.$moment(this.startdate).format('YYYY')) + nn) + (ww < 10 ? '0' + String(ww) : String(ww))))
              }
            }
          }
          for (let d = new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn) + '-' + this.$moment(w.start).format('MM-DD')).getTime(); d <= new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn) + '-' + this.$moment(w.end).format('MM-DD')).getTime(); d = d + 86400000) {
            // console.log(this.$moment(d).format('YYYY-MM-DD'))
            if (this.contentDateList.indexOf(this.$moment(d).format('YYYY-MM-DD')) !== -1) {
              array.push(Number((Number(this.$moment(this.startdate).format('YYYY')) + nn) + (ww < 10 ? '0' + String(ww) : String(ww))))
            }
          }
        })
      })
      return array
    }
  },
  watch: {
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/keypart/battery/BatteryManufacturingReport')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'battery') {
              this.startdate = n.value
            }
          })
          this.$http.get('/api/keypart/battery/uploadFileDate').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.contentDateList = res.data
              }
            })
          this.loadModeNameList()
          this.loadData()
          const _obj = this.$route.query
          if (Object.keys(_obj).length > 0) {
            // let y = this.$moment(new Date()).format('YYYY').substr(0, 2)
            // this.summaryWeeksStart = parseInt(`${y}${_obj['uploadWeekStart'].replace('W', '')}`)
            // this.summaryWeeksEnd = parseInt(`${y}${_obj['uploadWeekEnd'].replace('W', '')}`)
            // this.summaryModelName = _obj['modelNameList'].split(',')
            // this.summaryModelNameMenuChange()
            // if (_obj['partNumberList'].split(',').length > 0) {
            //   let p = _obj['partNumberList'].split(',')
            //   let c = _obj['cellTypeList'].split(',')
            //   p.forEach((element, index) => {
            //     this.summaryPartNumber.push(`${element}|${c[index]}`)
            //   })
            // }
            this.summaryWeeksStart = Number(_obj.uploadWeekStart)
            this.summaryWeeksEnd = Number(_obj.uploadWeekEnd)
            // let params = {
            //   'params': {
            //     uploadWeekStart: _obj['uploadWeekStart'],
            //     uploadWeekEnd: _obj['uploadWeekEnd']
            //     // modelNameList: _obj['modelNameList'],
            //     // partNumberList: _obj['partNumberList'],
            //     // cellTypeList: _obj['cellTypeList'],
            //     // targetTestItemList: _obj['targetTestItemList']
            //   }
            // }
            // this.doSubmit(true, params)
            this.doSubmit()
          }
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    async loadData () {
      await this.YearWeeksList()
      await this.AllYearWeeksList()
    },
    YearWeeksList () {
      const Arr = []
      const YearStart = this.$moment(this.startdate).format('YYYY')
      const YearEnd = this.$moment(new Date()).format('YYYY')
      let NewYear = false
      let YearEndWeek = 0

      for (let y = 0; y <= (YearEnd - YearStart + 1); y++) {
        Arr[y] = []
        let w = 0
        let n = 0
        let day = new Date(Number(YearStart) + y + '-01-01').getTime()
        if (y === 0 && this.$moment(this.startdate).format('MM-DD') !== '01-01') {
          day = new Date(Number(YearStart) + y + '-' + this.$moment(this.startdate).format('MM-DD')).getTime()
          w = Math.ceil((((new Date(this.$moment(this.startdate).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(this.startdate).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(this.startdate).format('YYYY'), 0, 1)).getDay()) / 7)
          if (new Date(this.$moment(this.startdate).format('YYYY') + '-01-01').getDay() > 0) {
            w = w - 1
          }
          Arr[y][w] = { start: this.$moment(this.startdate).format('YYYY-MM-DD'), end: '' }
          if (new Date(this.$moment(this.startdate).format('YYYY-MM-DD')).getDay() === 6) {
            Arr[y][w].end = this.$moment(this.startdate).format('YYYY-MM-DD')
          } else if (new Date(this.$moment(this.startdate).format('YYYY-MM-DD')).getDay() === 0) {
            w = w - 1
          }
        } else if (new Date(Number(YearStart) + y + '-01-01').getDay() !== 0) {
          n = 6 - new Date(Number(YearStart) + y + '-01-01').getDay()
          Arr[y][1] = { start: '', end: '' }
        }
        for (let d = (day + (86400000 * n)); d <= (new Date(Number(YearStart) + y + '-12-31').getTime()); d = d + 86400000) {
          if (y > 0 && new Date(d).getDay() === 6) {
            if (Arr[y][1].start === '') {
              Arr[y - 1][YearEndWeek].end = this.$moment(d).format('YYYY-MM-DD')
              Arr[y][0] = { start: Arr[y - 1][YearEndWeek].start, end: this.$moment(d).format('YYYY-MM-DD') }
              w = 0
              YearEndWeek = w
              NewYear = true
            }
          }
          if (new Date(d).getDay() === 0) {
            w++
            YearEndWeek = w
            NewYear = false
            Arr[y][w] = { start: this.$moment(d).format('YYYY-MM-DD'), end: '' }
          } else if (!NewYear && new Date(d).getDay() === 6) {
            Arr[y][w].end = this.$moment(d).format('YYYY-MM-DD')
          }
        }
      }
      this.summaryYearWeeksList = Arr
    },
    AllYearWeeksList () {
      const Arr = []
      const YearStart = this.$moment(this.startdate).format('YYYY')
      let YearEnd = this.$moment(this.summaryMonth).format('YYYY')
      let LastYear = 0
      if (new Date(this.$moment(this.summaryMonth).format('YYYY') + '-01-01').getDay() > 0) {
        LastYear = 1
      }
      const WeeksEnd = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1)).getDay()) / 7) - LastYear
      if (WeeksEnd === 0) {
        YearEnd--
      }
      for (let y = 0; y <= (YearEnd - YearStart); y++) {
        for (let w = 1; w < this.summaryYearWeeksList[y].length; w++) {
          if (y === (YearEnd - YearStart) && w === WeeksEnd) {
            Arr.push({ text: (Number(YearStart) + y) + '　w' + w + '　( ' + this.$moment(this.summaryYearWeeksList[y][w].start).format('MM-DD') + ' ～ ' + this.$moment(this.summaryYearWeeksList[y][w].end).format('MM-DD') + ' )', value: (Number(YearStart) + y) * 100 + w })
            break
          }
          if (this.summaryYearWeeksList[y][w]) {
            Arr.push({ text: (Number(YearStart) + y) + '　w' + w + '　( ' + this.$moment(this.summaryYearWeeksList[y][w].start).format('MM-DD') + ' ～ ' + this.$moment(this.summaryYearWeeksList[y][w].end).format('MM-DD') + ' )', value: (Number(YearStart) + y) * 100 + w })
          }
        }
      }
      Arr.sort(function (a, b) { return b.value - a.value })
      this.summaryAllYearWeeksList = Arr
    },
    doSubmit () {
    // doSubmit (urlflg, p) {
      this.tabShow = false
      this.tempload = true
      this.loadingView = true
      this.loadCpkEvaluationList()
      // let _uploadWeekStart = 'W' + String(this.summaryWeeksStart).substr(2)
      // let _uploadWeekEnd = 'W' + String(this.summaryWeeksEnd).substr(2)
      // let _targetTestItemList = ['FCC', 'RSOC', 'Static_Delta_of_cells_voltage']
      let params = {}
      // if (!urlflg) {
      let wwS = 0
      let wwE = 0
      if (Number.isInteger(this.summaryWeeksStart)) {
        wwS = String(this.summaryWeeksStart).substr(4)
      } else {
        wwS = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6)
      }
      if (Number.isInteger(this.summaryWeeksEnd)) {
        wwE = String(this.summaryWeeksEnd).substr(4)
      } else {
        wwE = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6)
      }
      const _uploadWeekStart = this.$moment(this.summaryYearWeeksList[String(this.summaryWeeksStart).substr(0, 4) - this.$moment(this.startdate).format('YYYY')][Number(wwS)].start).format('YYYY-MM-DD')
      const _uploadWeekEnd = this.$moment(this.summaryYearWeeksList[String(this.summaryWeeksEnd).substr(0, 4) - this.$moment(this.startdate).format('YYYY')][Number(wwE)].end).format('YYYY-MM-DD')

      // if (this.summaryModelName.length === 0) {
      //   this.snackbar = true
      //   this.snackbarColor = 'red'
      //   this.snackbarMsg = '請選擇MODEL_Name'
      //   this.loadingView = false
      //   return
      // } else if (this.summaryPartNumber.length === 0) {
      //   this.snackbar = true
      //   this.snackbarColor = 'red'
      //   this.snackbarMsg = '請選擇PART_NUMBER'
      //   this.loadingView = false
      //   return
      // }
      if (this.summaryWay === 'condition' && (this.summaryWeeksEnd < this.summaryWeeksStart)) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '結尾時間不得早於起始時間'
        return
      } else if (this.summaryWay === 'condition' && (this.summaryWeeksEnd - this.summaryWeeksStart > 3)) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '日期區間不得超過4週(28天)'
        return
      }
      const _PartNumber = []
      const _CellType = []
      this.summaryPartNumber.forEach(e => {
        _PartNumber.push(e.split('|')[0])
        _CellType.push(e.split('|')[1])
      })

      params = {
        params: {
          uploadWeekStart: _uploadWeekStart,
          uploadWeekEnd: _uploadWeekEnd,
          modelNameList: this.summaryModelName.join(','),
          partNumberList: _PartNumber.join(','),
          cellTypeList: _CellType.join(',')
          // targetTestItemList: _targetTestItemList.join(',')
        }
      }
      // } else {
      //   params = p
      // }

      this.showpartNumber = []
      this.showmodelName = []
      this.tabData = []
      this.$http.get('/api/keypart/battery/weeklyUploadBatteryManufacturingAnalysisDataList', params).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.loadingView = false
            if (res.data.length > 0) {
              this.tabShow = true
              res.data.forEach(e => {
                this.showmodelName.push(e.modelName)
                this.showpartNumber.push(e.partNumber)
                e.statisticItemList.forEach(x => {
                  this.get_Upper_lower(x.distributionCoverageList, x.upperSpecValue, x.lowerSpecValue, x.average)
                  const _options = this.createVueCharts(
                    x.testItemName,
                    x.distributionCoverageList,
                    x.average.toFixed(3),
                    x.standardDeviation.toFixed(3),
                    x.upperSpecValue,
                    x.lowerSpecValue)
                  const _item = {
                    testItemName: x.testItemName,
                    partNumber: e.partNumber,
                    cellType: e.cellType,
                    modelName: e.modelName,
                    upperSpecValue: x.upperSpecValue,
                    lowerSpecValue: x.lowerSpecValue,
                    cpk: this.chkCpkresult(x.cpk),
                    cpkvalue: x.cpk,
                    table: this.createVueTableData(x.distributionCoverageList),
                    handler: new Vue(),
                    chartoption: _options
                  }
                  this.tabData.push(_item)
                })
              })
              setTimeout(() => {
                this.tabData.forEach(e => {
                  e.handler.$emit('init', e.chartoption)
                })
                this.tempload = false
                this.loadingView = false
              }, 500)
            } else {
              this.tabShow = false
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = '目前暫無資料。'
            }
          } else {
            this.loadingView = false
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
    },
    createVueTableData (distributionCoverageList) {
      const _data = []
      distributionCoverageList.forEach(e => {
        const _define = e.define
        if (_define.type === 'Sigma') {
          _data.push(
            {
              sigma: _define.name,
              value: _define.value.toFixed(3),
              histogram: e.amount
            }
          )
        }
      })
      return _data
    },
    createVueCharts (testItemName, distributionCoverageList, average, standardDeviation, upperSpecValue, lowerSpecValue) {
      return {
        bindto: '#' + testItemName,
        size: {
          height: 308,
          width: 500
        },
        data: {
          x: 'x',
          columns: this.createVueChart_columns(distributionCoverageList, average, standardDeviation),
          axes: {
            常態分佈: 'y2'
          },
          type: 'bar',
          types: {
            常態分佈: 'spline'
          }
        },
        axis: {
          x: {
            type: 'category',
            tick: {
              rotate: 90,
              multiline: false
            },
            height: 80
          },
          y2: {
            show: false
          }
        },
        grid: {
          x: {
            lines: [
              { value: this.ULIndex },
              { value: this.LLIndex }
            ]
          }
        }
      }
    },
    createVueChart_columns (distributionCoverageList, average, standardDeviation) {
      const _data = [
        ['x'],
        ['常態分佈'],
        ['統計次數']
      ]
      distributionCoverageList = distributionCoverageList.sort(function (a, b) {
        return a.define.sigma - b.define.sigma
      })
      distributionCoverageList.forEach(e => {
        const normDist = new NormalDistribution(average, standardDeviation)
        const _define = e.define
        // console.log('standardDeviation', standardDeviation)
        if (standardDeviation === '0.000') {
          if (_define.type === 'Sigma') {
            _data[0].push(_define.name)
            _data[1].push(e.amount)
            _data[2].push(e.amount)
          }
        } else {
          if (_define.type === 'Sigma') {
            _data[0].push(_define.name)
            _data[1].push(normDist.pdf(_define.value).toFixed(3))
            _data[2].push(e.amount)
          }
        }
      })
      return _data
    },
    compareNumbers (a, b) {
      return a - b
    },
    get_Upper_lower (distributionCoverageList, upperSpecValue, lowerSpecValue, average) {
      const item = []
      // let olength = distributionCoverageList.length
      distributionCoverageList.forEach(e => {
        item.push(e.define.value)
      })
      item.sort(this.compareNumbers)
      this.ULIndex = item.indexOf(upperSpecValue)
      this.LLIndex = item.indexOf(lowerSpecValue)
      this.avg = item.indexOf(average)
      if (this.ULIndex >= 4) {
        if (this.ULIndex >= 7) {
          this.ULIndex = 6.41
        } else {
          if (this.ULIndex > this.avg) {
            this.ULIndex = this.ULIndex - (this.ULIndex - this.avg)
            if (item[this.ULIndex] > upperSpecValue) {
              this.ULIndex = this.ULIndex + 0.4
            } else {
              this.ULIndex = this.ULIndex - 0.4
            }
          } else {
            // this.ULIndex = this.ULIndex
          }
        }
      } else {
        if (this.ULIndex === 0) {
          this.ULIndex = 1.8
        } else {
          // this.ULIndex = this.ULIndex
        }
      }
      if (this.LLIndex <= 4) {
        if (this.LLIndex === 0) {
          this.LLIndex = 0.1
        } else {
          this.LLIndex = this.LLIndex + 0.1
        }
      }

      if (this.LLIndex <= 4) {
        this.LLIndex = this.LLIndex - 0.5
      } else {
        if (this.LLIndex >= 7) {
          this.LLIndex = 6.4
        } else {
          // this.LLIndex = this.LLIndex
        }
      }
    },
    loadModeNameList () {
      this.$http.get('/api/keypart/battery/modelName').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.summaryModelNameMenu = res.data
          }
        })
    },
    summaryModelNameMenuChange () {
      this.summaryPartNumber = []
      this.summaryPartNumberMenu = []
      if (this.summaryModelName.length > 0) {
        const m = this.summaryModelName.join(',')
        axios.get(`/api/keypart/battery/partNumberInfo/${m}`).then(res => res.data)
          .then(res => {
            if (res.status === 'success') {
              res.data.forEach(e => {
                this.summaryPartNumberMenu.push({ text: `${e.partNumber} | ${e.cellType}`, value: `${e.partNumber}|${e.cellType}` })
              })
            }
          })
      }
    },
    loadCpkEvaluationList () {
      this.$http.get('/api/keypart/support/cpkEvaluationList').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.CpkLv = res.data
          }
        })
    },
    chkCpkresult (cpk) {
      let result = ''
      // eslint-disable-next-line array-callback-return
      this.CpkLv.some(e => {
        if (e.upperSpecLimit == null && cpk >= e.lowerSpecLimit) {
          result = e.evaluation
          return true
        }
        if (e.upperSpecLimit > cpk && cpk >= e.lowerSpecLimit) {
          result = e.evaluation
          return true
        }
        if (e.lowerSpecLimit == null && cpk < e.lowerSpecLimit) {
          result = e.evaluation
          return true
        }
        result = e.evaluation
      })
      // console.log('r', result)
      return result
    }
  }
}
</script>
<style>
  .v-tabs__wrapper .v-tabs__div a.v-tabs__item.v-tabs__item--active{
    background: #59abec;
    font-size: large;
  }
  .vuec3-chart .c3-xgrid-line:nth-child(1) line {
      stroke: red;
      stroke-width: 3;
      stroke-dasharray: 5;
  }
  .vuec3-chart .c3-xgrid-line:nth-child(2) line {
      stroke: green;
      stroke-width: 3;
      stroke-dasharray: 5;
  }
  .vuec3-chart .c3-xgrid-line.grid4 line {
      stroke: pink;
  }
  .c3-xgrid-line.grid4 text {
      fill: pink;
  }
  .detail-list-dark {
    background: #555!important;
  }
  .detail-list-light {
    background: #f9f9f9!important;
  }
  .detail-layout {
    display: flex;
    flex-flow: row;
    align-items: flex-start;
  }
</style>
