<template>
  <v-container
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
      >
        <material-card
          :title="'電池CPK分析'"
          class="card-shrink card-padding v-card__first"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  xs12
                  sm12
                  lg4>
                  <v-autocomplete
                    v-model="summaryModelName"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryModelNameMenu"
                    label="MODEL_NAME"
                    class="v-text-field__slot"
                    color="white"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm12
                  lg8
                >
                  <v-autocomplete
                    v-model="summaryPartNumber"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryPartNumberMenu"
                    label="PART_NUMBER"
                    class="v-text-field__slot"
                    color="white"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                  />
                </v-flex>
              </v-layout>
              <v-layout
                wrap
                align-items-center>
                <!-- <v-flex
                  xs12
                  lg4>
                  <v-autocomplete
                    v-model="summaryWeeksStart"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAllYearWeeksList"
                    label="MANUFACTURE_WEEK"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  lg4>
                  <v-autocomplete
                    v-model="summaryWeeksEnd"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAllYearWeeksList"
                    label="MANUFACTURE_WEEK"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex> -->
                <v-flex
                  flex-small
                  d-flex
                  align-items-center
                  xs12
                  lg5>
                  <v-autocomplete
                    v-model="summaryWeeksStart"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAllYearWeeksList"
                    label="起始時間"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  >
                    <template v-slot:item="data">
                      <div
                        :class="{'weeks-list-data-tip':contentDateListWeeks.indexOf(data.item.value)!==-1}"
                        class="v-list__tile__content">
                        <div class="v-list__tile__title">
                          {{ data.item.text }}
                        </div>
                      </div>
                    </template>
                  </v-autocomplete>
                  <p class="m-0 text-center px-3">到</p>
                  <v-autocomplete
                    v-model="summaryWeeksEnd"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAllYearWeeksList"
                    label="結尾時間"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  >
                    <template v-slot:item="data">
                      <div
                        :class="{'weeks-list-data-tip':contentDateListWeeks.indexOf(data.item.value)!==-1}"
                        class="v-list__tile__content">
                        <div class="v-list__tile__title">
                          {{ data.item.text }}
                        </div>
                      </div>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex lg1/>
                <v-flex
                  d-flex
                  xs12
                  lg5>
                  <v-checkbox
                    v-model="chkFCC"
                    class="mt-1 flex"
                    label="FCC"/>
                  <v-checkbox
                    v-model="chkRSOC"
                    class="mt-1 flex"
                    label="RSOC"/>
                  <v-checkbox
                    v-model="chkStatic_Delta_of_cells_voltage"
                    class="mt-1 flex"
                    label="Static_Delta_of_cells_voltage"/>
                </v-flex>
                <v-flex
                  xs12
                  sm12
                  lg1
                  text-lg-right
                  text-xs-right
                >
                  <v-btn
                    class="ml-3"
                    color="black"
                    style="display: none"
                    @click="doDownload()"
                  >
                    匯出
                  </v-btn>
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    查詢
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex>
        <material-card
          v-show="tabShow"
          :title="''"
          color="deep-orange"
        >
          <v-tabs
            v-model="SelectTab"
            hide-slider
            dark
          >
            <v-tab
              v-show="schkFCC"
              href="#tab-1"
              @click="getHero('FCC')"
            >
              FCC
              <v-icon
                v-if="tabError['FCC']"
                style="color: #ff9800;">mdi-alert-decagram</v-icon>
            </v-tab>
            <v-tab
              v-show="schkRSOC"
              href="#tab-2"
              @click="getHero('RSOC')"
            >
              RSOC
              <v-icon
                v-if="tabError['RSOC']"
                style="color: #ff9800;">mdi-alert-decagram</v-icon>
            </v-tab>
            <v-tab
              v-show="schkStatic_Delta_of_cells_voltage"
              href="#tab-3"
              @click="getHero('Static_Delta_of_cells_voltage')"
            >
              Static_Delta_of_cells_voltage
              <v-icon
                v-if="tabError['Static_Delta_of_cells_voltage']"
                style="color: #ff9800;">mdi-alert-decagram</v-icon>
            </v-tab>
            <v-tab
              v-show="tempload"
              href="#tab-4"
            >
              <span>&nbsp;</span>
            </v-tab>
            <v-tabs-items v-model="SelectTab">
              <v-tab-item
                :value="'tab-1'">
                <v-layout
                  row
                  wrap
                >
                  <v-flex
                    v-for="(FCCitem, index) in tabData.FCC"
                    :key="'b'+ index"
                    lg6>
                    <v-card>
                      <v-card-title style="padding:5px;">
                        <v-flex
                          style="border-bottom:solid 1px #D3D3D3"
                          lg12>
                          PART_NUMBER: {{ FCCitem.partNumber }}
                        </v-flex>
                        <v-flex
                          style="align-self: flex-start;padding: 9px 0px !important;"
                          lg5
                        >
                          <v-list
                            dense
                          >
                            <v-list-tile>
                              <v-list-tile-content style="overflow: inherit;">Cell_TYPE:</v-list-tile-content>
                              <v-list-tile-content
                                class="align-end"
                                style="text-align: end;">{{ FCCitem.cellType }}</v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile>
                              <v-list-tile-content>SPEC:</v-list-tile-content>
                              <v-list-tile-content class="align-end">{{ FCCitem.lowerSpecValue }} ~ {{ FCCitem.upperSpecValue }}</v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile>
                              <v-list-tile-content>CPK:</v-list-tile-content>
                              <v-list-tile-content>
                                <v-list-tile-title style="text-align: right;">
                                  <v-icon
                                    v-if= "FCCitem.cpkStatus ==='unqualified'"
                                    style="color: #ff9800;">mdi-alert-decagram</v-icon>
                                  {{ FCCitem.cpkvalue }} ({{ FCCitem.cpk }})
                                </v-list-tile-title>
                              </v-list-tile-content>
                            </v-list-tile>
                          </v-list>
                        </v-flex>
                        <v-flex
                          lg7
                          style="display: flex;justify-content: space-evenly;"
                        >
                          <v-data-table
                            :items="FCCitem.table"
                            multi-sort
                            hide-actions
                          >
                            <template
                              slot="headerCell"
                              slot-scope="{ header }"
                            >
                              <span
                                class="subheading font-weight-light text-general text--darken-3"
                                v-text="header.text"
                              />
                            </template>
                            <template
                              slot="items"
                              slot-scope="{ item }"
                            >
                              <tr>
                                <td>
                                  {{ item.sigma }}
                                </td>
                                <td>
                                  {{ item.value }}
                                </td>
                                <td>
                                  {{ item.histogram }}
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-flex>
                      </v-card-title>
                    </v-card>

                    <v-flex
                      d-flex
                      xs12
                      lg12>
                      <vue-c3 :handler="FCCitem.handler" />
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-tab-item>
              <v-tab-item
                :value="'tab-2'">
                <v-layout
                  row
                  wrap
                >
                  <v-flex
                    v-for="(RSOCitem, index) in tabData.RSOC"
                    :key="'b'+ index"
                    lg6>
                    <v-card>
                      <v-card-title style="padding:5px;">
                        <v-flex
                          style="border-bottom:solid 1px #D3D3D3"
                          lg12>
                          PART_NUMBER: {{ RSOCitem.partNumber }}
                        </v-flex>
                        <v-flex
                          style="align-self: flex-start;padding: 9px 0px !important;"
                          lg5
                        >
                          <v-list
                            dense
                          >
                            <v-list-tile>
                              <v-list-tile-content style="overflow: inherit;">Cell_TYPE:</v-list-tile-content>
                              <v-list-tile-content
                                class="align-end"
                                style="text-align: end;">{{ RSOCitem.cellType }}</v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile>
                              <v-list-tile-content>SPEC:</v-list-tile-content>
                              <v-list-tile-content class="align-end">{{ RSOCitem.lowerSpecValue }} ~ {{ RSOCitem.upperSpecValue }}</v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile>
                              <v-list-tile-content>CPK:</v-list-tile-content>
                              <v-list-tile-content>
                                <v-list-tile-title style="text-align: right;">
                                  <v-icon
                                    v-if= "RSOCitem.cpkStatus ==='unqualified'"
                                    style="color: #ff9800;">mdi-alert-decagram</v-icon>
                                  {{ RSOCitem.cpkvalue }} ({{ RSOCitem.cpk }})
                                </v-list-tile-title>
                              </v-list-tile-content>
                            </v-list-tile>
                          </v-list>
                        </v-flex>
                        <v-flex
                          lg7
                          style="display: flex;justify-content: space-evenly;"
                        >
                          <v-data-table
                            :items="RSOCitem.table"
                            multi-sort
                            hide-actions
                          >
                            <template
                              slot="headerCell"
                              slot-scope="{ header }"
                            >
                              <span
                                class="subheading font-weight-light text-general text--darken-3"
                                v-text="header.text"
                              />
                            </template>
                            <template
                              slot="items"
                              slot-scope="{ item }"
                            >
                              <tr>
                                <td>
                                  {{ item.sigma }}
                                </td>
                                <td>
                                  {{ item.value }}
                                </td>
                                <td>
                                  {{ item.histogram }}
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-flex>
                      </v-card-title>
                    </v-card>

                    <v-flex
                      d-flex
                      xs12
                      lg12>
                      <vue-c3 :handler="RSOCitem.handler" />
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-tab-item>
              <v-tab-item
                :value="'tab-3'">
                <v-layout
                  row
                  wrap
                >
                  <v-flex
                    v-for="(SDocvitem, index) in tabData.Static_Delta_of_cells_voltage"
                    :key="'b'+ index"
                    lg6>
                    <v-card>
                      <v-card-title style="padding:5px;">
                        <v-flex
                          style="border-bottom:solid 1px #D3D3D3"
                          lg12>
                          PART_NUMBER: {{ SDocvitem.partNumber }}
                        </v-flex>
                        <v-flex
                          style="align-self: flex-start;padding: 9px 0px !important;"
                          lg6
                        >
                          <v-list
                            dense
                          >
                            <v-list-tile>
                              <v-list-tile-content style="overflow: inherit;">Cell_TYPE:</v-list-tile-content>
                              <v-list-tile-content
                                class="align-end"
                                style="text-align: end;">{{ SDocvitem.cellType }}</v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile>
                              <v-list-tile-content>SPEC:</v-list-tile-content>
                              <v-list-tile-content class="align-end">{{ SDocvitem.lowerSpecValue }} ~ {{ SDocvitem.upperSpecValue }}</v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile>
                              <v-list-tile-content>CPK:</v-list-tile-content>
                              <v-list-tile-title style="text-align: right;">
                                <v-icon
                                  v-if= "SDocvitem.cpkStatus ==='unqualified'"
                                  style="color: #ff9800;">mdi-alert-decagram</v-icon>
                                {{ SDocvitem.cpkvalue }} ({{ SDocvitem.cpk }})
                              </v-list-tile-title>
                            </v-list-tile>
                          </v-list>
                        </v-flex>
                        <v-flex
                          lg6
                          style="display: flex;justify-content: space-evenly;"
                        >
                          <v-data-table
                            :items="SDocvitem.table"
                            multi-sort
                            hide-actions
                          >
                            <template
                              slot="headerCell"
                              slot-scope="{ header }"
                            >
                              <span
                                class="subheading font-weight-light text-general text--darken-3"
                                v-text="header.text"
                              />
                            </template>
                            <template
                              slot="items"
                              slot-scope="{ item }"
                            >
                              <tr>
                                <td>
                                  {{ item.sigma }}
                                </td>
                                <td>
                                  {{ item.value }}
                                </td>
                                <td>
                                  {{ item.histogram }}
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-flex>
                      </v-card-title>
                    </v-card>

                    <v-flex
                      d-flex
                      xs12
                      lg12>
                      <vue-c3 :handler="SDocvitem.handler" />
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-tab-item>
              <v-tab-item
                :value="'tab-4'">
                <v-layout>
                  <h1>&nbsp;</h1>
                </v-layout>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import qs from 'qs'
import NormalDistribution from 'normal-distribution'
import axios from 'axios'
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'BatteryCPKAnalyzer',
  components: {
    VueC3
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      tabShow: false,
      summaryMonth: this.$moment(new Date()).format('YYYY-MM'),
      summaryWeeksStart: Number(this.$moment(new Date()).format('YYYY')) * 100 + Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6),
      summaryWeeksEnd: Number(this.$moment(new Date()).format('YYYY')) * 100 + Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6),
      summaryModelNameMenu: [],
      summaryModelName: [],
      summaryPartNumberMenu: [],
      summaryPartNumber: [],
      summaryWeeksList: [],
      summaryYearWeeksList: [],
      chkFCC: false,
      chkRSOC: false,
      chkStatic_Delta_of_cells_voltage: false,
      schkFCC: false,
      schkRSOC: false,
      schkStatic_Delta_of_cells_voltage: false,
      tabData: { FCC: [], RSOC: [], Static_Delta_of_cells_voltage: [] },
      tabError: { FCC: false, RSOC: false, Static_Delta_of_cells_voltage: false },
      summaryVendorMenu: [],
      summaryVendor: 'All',
      summaryWeeksRange: '',
      summaryWeeksUnfoldList: [],
      summaryAllYearWeeksList: [],
      contentDateList: [],
      chartLineView: false,
      chartLineViewNum: 0,
      chartLineViewName: '',
      DataStatistcStart: '',
      DataStatistcEnd: '',
      barXaxisName: [],
      barXaxisValue: [],
      ULIndex: 9,
      LLIndex: -1,
      pagination: {
        rowsPerPage: 1,
        sortBy: 'TotalFailRate', // The field that you're sorting by
        descending: true
      },
      handler_line: new Vue(),
      SelectTab: 'tab-4',
      tempload: true,
      CpkLv: []
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    contentDateListWeeks () {
      const array = []
      this.contentDateList.forEach((n, nn) => {
        array.push(Number(n.replace('W', '20')))
      })
      return array
    }
  },
  watch: {
    summaryModelName (val) {
      this.summaryPartNumber = []
      this.summaryPartNumberMenu = []
      if (val.length > 0) {
        const m = this.summaryModelName.join(',')
        axios.get(`/api/keypart/battery/partNumberInfo/${m}`).then(res => res.data)
          .then(res => {
            if (res.status === 'success') {
              res.data.forEach(e => {
                this.summaryPartNumberMenu.push({ text: `${e.partNumber} | ${e.cellType}`, value: `${e.partNumber}|${e.cellType}` })
              })
            }
          })
      }
    },
    summaryPartNumber (val) {
      this.contentDateList = []
      if (val.length > 0) this.getContentDateList(this.summaryModelName, val)
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/keypart/battery/batteryCPKAnalyzer')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'battery') {
              this.startdate = n.value
            }
          })
          this.loadModeNameList()
          this.loadData()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    async loadData () {
      await this.YearWeeksList()
      await this.AllYearWeeksList()
    },
    getHero (s) {
      const that = this
      setTimeout(function () {
        that.tabData[s].forEach(e => {
          e.handler.$emit('dispatch', chart => {
            chart.resize({
              height: 308,
              width: 500
            })
          })
        })
      }, 100)
    },
    YearWeeksList () {
      const Arr = []
      const YearStart = this.$moment(this.startdate).format('YYYY')
      const YearEnd = this.$moment(new Date()).format('YYYY')
      let NewYear = false
      let YearEndWeek = 0

      for (let y = 0; y <= (YearEnd - YearStart + 1); y++) {
        Arr[y] = []
        let w = 0
        let n = 0
        let day = new Date(Number(YearStart) + y + '-01-01').getTime()
        if (y === 0 && this.$moment(this.startdate).format('MM-DD') !== '01-01') {
          day = new Date(Number(YearStart) + y + '-' + this.$moment(this.startdate).format('MM-DD')).getTime()
          w = Math.ceil((((new Date(this.$moment(this.startdate).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(this.startdate).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(this.startdate).format('YYYY'), 0, 1)).getDay()) / 7)
          if (new Date(this.$moment(this.startdate).format('YYYY') + '-01-01').getDay() > 0) {
            w = w - 1
          }
          Arr[y][w] = { start: this.$moment(this.startdate).format('YYYY-MM-DD'), end: '' }
          if (new Date(this.$moment(this.startdate).format('YYYY-MM-DD')).getDay() === 6) {
            Arr[y][w].end = this.$moment(this.startdate).format('YYYY-MM-DD')
          } else if (new Date(this.$moment(this.startdate).format('YYYY-MM-DD')).getDay() === 0) {
            w = w - 1
          }
        } else if (new Date(Number(YearStart) + y + '-01-01').getDay() !== 0) {
          n = 6 - new Date(Number(YearStart) + y + '-01-01').getDay()
          Arr[y][1] = { start: '', end: '' }
        }
        for (let d = (day + (86400000 * n)); d <= (new Date(Number(YearStart) + y + '-12-31').getTime()); d = d + 86400000) {
          if (y > 0 && new Date(d).getDay() === 6) {
            if (Arr[y][1].start === '') {
              Arr[y - 1][YearEndWeek].end = this.$moment(d).format('YYYY-MM-DD')
              Arr[y][0] = { start: Arr[y - 1][YearEndWeek].start, end: this.$moment(d).format('YYYY-MM-DD') }
              w = 0
              YearEndWeek = w
              NewYear = true
            }
          }
          if (new Date(d).getDay() === 0) {
            w++
            YearEndWeek = w
            NewYear = false
            Arr[y][w] = { start: this.$moment(d).format('YYYY-MM-DD'), end: '' }
          } else if (!NewYear && new Date(d).getDay() === 6) {
            Arr[y][w].end = this.$moment(d).format('YYYY-MM-DD')
          }
        }
      }
      this.summaryYearWeeksList = Arr
    },
    AllYearWeeksList () {
      const Arr = []
      const YearStart = this.$moment(this.startdate).format('YYYY')
      let YearEnd = this.$moment(this.summaryMonth).format('YYYY')
      let LastYear = 0
      if (new Date(this.$moment(this.summaryMonth).format('YYYY') + '-01-01').getDay() > 0) {
        LastYear = 1
      }
      const WeeksEnd = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1)).getDay()) / 7) - LastYear
      if (WeeksEnd === 0) {
        YearEnd--
      }
      for (let y = 0; y <= (YearEnd - YearStart); y++) {
        for (let w = 1; w < this.summaryYearWeeksList[y].length; w++) {
          if (y === (YearEnd - YearStart) && w === WeeksEnd) {
            Arr.push({ text: (Number(YearStart) + y) + '　w' + w + '　( ' + this.$moment(this.summaryYearWeeksList[y][w].start).format('MM-DD') + ' ～ ' + this.$moment(this.summaryYearWeeksList[y][w].end).format('MM-DD') + ' )', value: (Number(YearStart) + y) * 100 + w })
            break
          }
          if (this.summaryYearWeeksList[y][w]) {
            Arr.push({ text: (Number(YearStart) + y) + '　w' + w + '　( ' + this.$moment(this.summaryYearWeeksList[y][w].start).format('MM-DD') + ' ～ ' + this.$moment(this.summaryYearWeeksList[y][w].end).format('MM-DD') + ' )', value: (Number(YearStart) + y) * 100 + w })
          }
        }
      }
      Arr.sort(function (a, b) { return b.value - a.value })
      this.summaryAllYearWeeksList = Arr
    },
    getContentDateList (vendor, partNumber) {
      const _PartNumber = []
      const _CellType = []
      this.summaryPartNumber.forEach(e => {
        _PartNumber.push(e.split('|')[0])
        _CellType.push(e.split('|')[1])
      })
      const body = {
        modelNameList: this.summaryModelName.join(','),
        partNumberList: _PartNumber.join(','),
        cellTypeList: _CellType.join(',')
      }
      this.$http.get('/api/keypart/battery/manufacturingDate?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.contentDateList = res.data
          }
        })
    },
    doSubmit () {
      this.tabShow = false
      this.tempload = true
      this.loadingView = true
      this.loadCpkEvaluationList()
      const _manufactureWeekStart = 'W' + String(this.summaryWeeksStart).substr(2)
      const _manufactureWeekEnd = 'W' + String(this.summaryWeeksEnd).substr(2)
      const _targetTestItemList = []
      this.schkFCC = false
      this.schkRSOC = false
      this.schkStatic_Delta_of_cells_voltage = false
      if (this.chkFCC) {
        _targetTestItemList.push('FCC')
        this.schkFCC = true
      }
      if (this.chkRSOC) {
        _targetTestItemList.push('RSOC')
        this.schkRSOC = true
      }
      if (this.chkStatic_Delta_of_cells_voltage) {
        _targetTestItemList.push('Static_Delta_of_cells_voltage')
        this.schkStatic_Delta_of_cells_voltage = true
      }
      if (this.summaryModelName.length === 0) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '請選擇MODEL_Name'
        this.loadingView = false
        return
      } else if (this.summaryPartNumber.length === 0) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '請選擇PART_NUMBER'
        this.loadingView = false
        return
      }
      const _PartNumber = []
      const _CellType = []
      this.summaryPartNumber.forEach(e => {
        _PartNumber.push(e.split('|')[0])
        _CellType.push(e.split('|')[1])
      })
      if (_targetTestItemList.length === 0) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '請選擇 FCC、RSOC、Static_Delta_of_cells_voltage 其中一項目'
        this.loadingView = false
        return
      }
      const params = {
        params: {
          manufactureWeekStart: _manufactureWeekStart,
          manufactureWeekEnd: _manufactureWeekEnd,
          modelNameList: this.summaryModelName.join(','),
          partNumberList: _PartNumber.join(','),
          cellTypeList: _CellType.join(','),
          targetTestItemList: _targetTestItemList.join(',')
        }
      }
      this.tabData = { FCC: [], RSOC: [], Static_Delta_of_cells_voltage: [] }
      this.tabError = { FCC: false, RSOC: false, Static_Delta_of_cells_voltage: false }
      this.$http.get('/api/keypart/battery/batteryManufacturingAnalysisDataList', params).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.loadingView = false
            if (res.data.length > 0) {
              this.tabShow = true
              res.data.forEach(e => {
                e.statisticItemList.forEach(x => {
                  this.get_Upper_lower(x.distributionCoverageList, x.upperSpecValue, x.lowerSpecValue, x.average)
                  const _options = this.createVueCharts(
                    x.testItemName,
                    x.distributionCoverageList,
                    x.average.toFixed(3),
                    x.standardDeviation.toFixed(3),
                    x.upperSpecValue,
                    x.lowerSpecValue)
                  const _item = {
                    partNumber: e.partNumber,
                    cellType: e.cellType,
                    modelName: e.modelName,
                    upperSpecValue: x.upperSpecValue,
                    lowerSpecValue: x.lowerSpecValue,
                    cpk: this.chkCpkresult(x.cpk),
                    cpkStatus: this.chkCpkStatus(x.cpk),
                    cpkvalue: x.cpk,
                    table: this.createVueTableData(x.distributionCoverageList),
                    handler: new Vue(),
                    chartoption: _options
                  }
                  this.tabData[x.testItemName].push(_item)
                  if (this.tabError[x.testItemName] === false) {
                    if (_item.cpkStatus === 'unqualified') {
                      this.tabError[x.testItemName] = true
                    }
                  }
                })
              })
              setTimeout(() => {
                this.tabData.FCC.forEach(e => {
                  e.handler.$emit('init', e.chartoption)
                })
                this.tabData.RSOC.forEach(e => {
                  e.handler.$emit('init', e.chartoption)
                })
                this.tabData.Static_Delta_of_cells_voltage.forEach(e => {
                  e.handler.$emit('init', e.chartoption)
                })
                if (_targetTestItemList[0] === 'FCC') {
                  this.SelectTab = 'tab-1'
                }
                if (_targetTestItemList[0] === 'RSOC') {
                  this.SelectTab = 'tab-2'
                }
                if (_targetTestItemList[0] === 'Static_Delta_of_cells_voltage') {
                  this.SelectTab = 'tab-3'
                }
                this.tempload = false
                this.loadingView = false
              }, 500)
            } else {
              this.tabShow = false
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = '目前暫無資料。'
            }
          }
        })
    },
    createVueTableData (distributionCoverageList) {
      const _data = []
      distributionCoverageList.forEach(e => {
        const _define = e.define
        if (_define.type === 'Sigma') {
          _data.push(
            {
              sigma: _define.name,
              value: _define.value.toFixed(3),
              histogram: e.amount
            }
          )
        }
      })
      return _data
    },
    createVueCharts (testItemName, distributionCoverageList, average, standardDeviation, upperSpecValue, lowerSpecValue) {
      return {
        bindto: '#' + testItemName,
        size: {
          height: 308,
          width: 500
        },
        data: {
          x: 'x',
          columns: this.createVueChart_columns(distributionCoverageList, average, standardDeviation),
          axes: {
            常態分佈: 'y2'
          },
          type: 'bar',
          types: {
            常態分佈: 'spline'
          }
        },
        axis: {
          x: {
            type: 'category',
            tick: {
              rotate: 90,
              multiline: false
            },
            height: 80
          },
          y2: {
            show: false
          }
        },
        grid: {
          x: {
            lines: [
              { value: this.ULIndex },
              { value: this.LLIndex }
            ]
          }
        }
      }
    },
    createVueChart_columns (distributionCoverageList, average, standardDeviation) {
      const _data = [
        ['x'],
        ['常態分佈'],
        ['統計次數']
      ]
      distributionCoverageList = distributionCoverageList.sort(function (a, b) {
        return a.define.sigma - b.define.sigma
      })
      distributionCoverageList.forEach(e => {
        const normDist = new NormalDistribution(average, standardDeviation)
        const _define = e.define
        if (_define.type === 'Sigma') {
          _data[0].push(_define.name)

          if (standardDeviation === '0.000') {
            _data[1].push(e.amount)
          } else {
            _data[1].push(normDist.pdf(_define.value).toFixed(3))
          }

          _data[2].push(e.amount)
        }
      })
      return _data
    },
    compareNumbers (a, b) {
      return a - b
    },
    get_Upper_lower (distributionCoverageList, upperSpecValue, lowerSpecValue, average) {
      const item = []
      // let olength = distributionCoverageList.length
      distributionCoverageList.forEach(e => {
        item.push(e.define.value)
      })
      item.sort(this.compareNumbers)
      this.ULIndex = item.indexOf(upperSpecValue)
      this.LLIndex = item.indexOf(lowerSpecValue)
      this.avg = item.indexOf(average)
      if (this.ULIndex >= 4) {
        if (this.ULIndex >= 7) {
          this.ULIndex = 6.41
        } else {
          if (this.ULIndex > this.avg) {
            this.ULIndex = this.ULIndex - (this.ULIndex - this.avg)
            if (item[this.ULIndex] > upperSpecValue) {
              this.ULIndex = this.ULIndex + 0.4
            } else {
              this.ULIndex = this.ULIndex - 0.4
            }
          } else {
            // this.ULIndex = this.ULIndex
          }
        }
      } else {
        if (this.ULIndex === 0) {
          this.ULIndex = 1.8
        } else {
          // this.ULIndex = this.ULIndex
        }
      }
      if (this.LLIndex <= 4) {
        if (this.LLIndex === 0) {
          this.LLIndex = 0.1
        } else {
          this.LLIndex = this.LLIndex + 0.1
        }
      }

      if (this.LLIndex <= 4) {
        this.LLIndex = this.LLIndex - 0.5
      } else {
        if (this.LLIndex >= 7) {
          this.LLIndex = 6.4
        } else {
          // this.LLIndex = this.LLIndex
        }
      }
    },
    loadModeNameList () {
      this.$http.get('/api/keypart/battery/modelName').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.summaryModelNameMenu = res.data
          }
        })
    },
    loadCpkEvaluationList () {
      this.$http.get('/api/keypart/support/cpkEvaluationList').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.CpkLv = res.data
          }
        })
    },
    chkCpkresult (cpk) {
      let result = ''
      // eslint-disable-next-line array-callback-return
      this.CpkLv.some(e => {
        if (e.upperSpecLimit == null && cpk >= e.lowerSpecLimit) {
          result = e.evaluation
          return true
        }
        if (e.upperSpecLimit > cpk && cpk >= e.lowerSpecLimit) {
          result = e.evaluation
          return true
        }
        if (e.lowerSpecLimit == null && cpk < e.lowerSpecLimit) {
          result = e.evaluation
          return true
        }
        result = e.evaluation
      })
      // console.log('r', result)
      return result
    },
    chkCpkStatus (cpk) {
      let result = ''
      // eslint-disable-next-line array-callback-return
      this.CpkLv.some(e => {
        if (e.upperSpecLimit == null && cpk >= e.lowerSpecLimit) {
          result = e.status
          return true
        }
        if (e.upperSpecLimit > cpk && cpk >= e.lowerSpecLimit) {
          result = e.status
          return true
        }
        if (e.lowerSpecLimit == null && cpk < e.lowerSpecLimit) {
          result = e.status
          return true
        }
        result = e.status
      })
      // console.log('r', result)
      return result
    },
    doDownload () {
      this.loadCpkEvaluationList()
      const _manufactureWeekStart = 'W' + String(this.summaryWeeksStart).substr(2)
      const _manufactureWeekEnd = 'W' + String(this.summaryWeeksEnd).substr(2)
      let _targetTestItemList = []
      if (this.chkFCC) { _targetTestItemList.push('FCC') }
      if (this.chkRSOC) { _targetTestItemList.push('RSOC') }
      if (this.chkStatic_Delta_of_cells_voltage) { _targetTestItemList.push('Static_Delta_of_cells_voltage') }
      if (this.summaryModelName.length === 0) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '請選擇MODEL_Name'
        this.loadingView = false
        return
      } else if (this.summaryPartNumber.length === 0) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '請選擇PART_NUMBER'
        this.loadingView = false
        return
      }
      const _PartNumber = []
      const _CellType = []
      this.summaryPartNumber.forEach(e => {
        _PartNumber.push(e.split('|')[0])
        _CellType.push(e.split('|')[1])
      })
      if (_targetTestItemList.length === 0) {
        _targetTestItemList = ['FCC', 'RSOC', 'Static_Delta_of_cells_voltage']
      }
      const params = {
        manufactureWeekStart: _manufactureWeekStart,
        manufactureWeekEnd: _manufactureWeekEnd,
        modelNameList: this.summaryModelName.join(','),
        partNumberList: _PartNumber.join(','),
        cellTypeList: _CellType.join(','),
        targetTestItemList: _targetTestItemList.join(',')
      }
      const p = `?manufactureWeekStart= ${params.manufactureWeekStart}&manufactureWeekEnd= ${params._manufactureWeekEnd}&modelNameList=${params.modelNameList}
        &partNumberList=${params.partNumberList}&cellTypeList=${params.cellTypeList}&targetTestItemList=${params.targetTestItemList}`
      this.processingView = true
      this.$http.get(`/api/keypart/battery/batteryManufacturingAnalysisDataFile${p}`, { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.status === 'success') {
                  alert(res.data.data)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '無檔案可供下載'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    }
  }
}
</script>
<style>
  .v-tabs__wrapper .v-tabs__div a.v-tabs__item.v-tabs__item--active{
    background: #59abec;
    font-size: large;
  }
  .vuec3-chart .c3-xgrid-line:nth-child(1) line {
      stroke: red;
      stroke-width: 3;
      stroke-dasharray: 5;
  }
  .vuec3-chart .c3-xgrid-line:nth-child(2) line {
      stroke: green;
      stroke-width: 3;
      stroke-dasharray: 5;
  }
  .vuec3-chart .c3-xgrid-line.grid4 line {
      stroke: pink;
  }
  .c3-xgrid-line.grid4 text {
      fill: pink;
  }
</style>
