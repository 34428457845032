<template>
  <v-container
    cfr
    issue_tracking
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        mt-3
        xs4
        md4
      >
        <material-card
          color="general"
          title="追蹤問題列表"
          class="card-mix card-padding pt-0 pb-3">
          <!-- <v-checkbox
            v-model="issueTrackToMe"
            class="mix-btn m-0"
            style="right: 15rem;"
            label="與我相關"
          /> -->
          <v-select
            v-model="issueTrackListRelated"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="[
              {text: '我建立的追蹤', value: ''},
              {text: '與我相關', value: userPk}
            ]"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 150px;right: 13rem;"
            persistent-hint
            outlined
          />
          <v-select
            v-model="issueTrackListState"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="[
              {text: '追蹤中', value: 'TRACKING'},
              {text: '已結案', value: 'FINISHED'},
              {text: '已作廢', value: 'OBSOLETE'}
            ]"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;right: 6.5rem;"
            persistent-hint
            outlined
          />
          <v-btn
            class="mix-btn pink text-light"
            style="right: 1rem;"
            @click="doIssueActionBefore('add', 0, '', true)">
            <v-icon class="float-left mr-1">mdi-plus</v-icon>新增
          </v-btn>
          <v-data-table
            :headers="headers"
            :items="responseIssueTrackListData"
            :pagination.sync="pagination"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td
                :class="{'text-danger':actionIssuePk===item.issuePk}"
                class="w-100"
                style="cursor: pointer;"
                @click="doIssueActionBefore('view', item.issuePk, item.issueName, item.allowEdit, false)">{{ item.issueName }}</td>
              <td>
                <v-btn
                  v-if="item.allowEdit"
                  :style="issueTrackListState!=='TRACKING'?'opacity:0;':''"
                  :disabled="issueTrackListState!=='TRACKING'"
                  class="m-0 font-weight-light small"
                  color="deep-purple"
                  @click="doIssueActionBefore('edit', item.issuePk, item.issueName, item.allowEdit, true)">
                  <v-icon class="mr-2">mdi-pencil</v-icon>編輯
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
      <v-flex
        v-if="actionIssueView"
        mt-3
        xs8
        md8
      >
        <material-card
          :title="action==='view'?('追蹤問題詳情及結果 - '+actionIssueName):(action==='edit'?('編輯追蹤問題設定 - '+actionIssueName):'新增追蹤問題設定')"
          color="deep-orange"
          class="card-mix card-padding pt-0 pb-3">
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout
                v-if="action==='view'"
                wrap>
                <v-flex
                  v-if="targetWay==='sn'"
                  flex-small
                  xs1
                  lg1>
                  <v-text-field
                    v-if="targetWay==='sn'"
                    :value="responseIssueTrackInfoData.deviceCount||' '"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="追蹤序號數量"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                    disabled
                  />
                </v-flex>
                <v-flex
                  :class="{'lg2' : targetWay==='sn' , 'lg3' : targetWay==='dateCondition'}"
                  flex-small>
                  <v-text-field
                    :value="getStatusName(responseIssueTrackInfoData.state)||' '"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="問題狀態"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                    disabled
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs3
                  lg3>
                  <v-text-field
                    :value="responseIssueTrackInfoData.updatedUser||' '"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :hint="responseIssueTrackInfoData.updatedDate||' '"
                    label="修改資訊"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                    disabled
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs3
                  lg3>
                  <v-text-field
                    :value="responseIssueTrackInfoData.createdUser||' '"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :hint="responseIssueTrackInfoData.createdDate||' '"
                    label="建立資訊"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                    disabled
                  />
                </v-flex>
                <!-- <v-flex
                  flex-small
                  xs6
                  lg6>
                  <v-text-field
                    :value="responseIssueTrackInfoData.updatedUser+' 修改於 '+responseIssueTrackInfoData.updatedDate"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :label="responseIssueTrackInfoData.createdUser+' 建立於 '+responseIssueTrackInfoData.createdDate"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                    disabled
                  />
                </v-flex> -->
                <v-flex
                  flex-small
                  xs1
                  lg1/>
                <v-flex
                  flex-small
                  align-center
                  justify-center
                  d-flex
                  xs2
                  lg2>
                  <v-btn
                    v-if="actionIssueAllowEdit && issueTrackListState==='TRACKING'"
                    class="mt-3 ml-3 mr-0 font-weight-light float-right"
                    color="teal"
                    @click="doChangeIssueTrackStatus(actionIssuePk, 'FINISHED', '結案')"
                  >
                    <v-icon class="mr-2">mdi-clipboard-check-outline</v-icon>結案問題
                  </v-btn>
                  <h4
                    v-if="!actionIssueAllowEdit && issueTrackListState==='TRACKING'"
                    class="text-white border border-white text-center">與我相關</h4>
                  <h4
                    v-if="issueTrackListState==='FINISHED'"
                    class="text-success border-success text-center">已結案</h4>
                  <h4
                    v-if="issueTrackListState==='OBSOLETE'"
                    class="text-gray border-gray text-center">已作廢</h4>
                </v-flex>
              </v-layout>
              <v-layout
                v-if="(action==='add'||action==='edit')"
                wrap>
                <v-flex
                  flex-small
                  xs12
                  lg12>
                  <v-text-field
                    v-model="actionIssueName"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light' , 'row-btn-150' : action==='edit'}"
                    label="問題名稱"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                  <v-btn
                    v-if="action==='edit'"
                    class="mt-3 ml-3 mr-0 font-weight-light float-right text-warning"
                    color="c"
                    @click="doChangeIssueTrackStatus(actionIssuePk, 'OBSOLETE', '作廢')"
                  >
                    <v-icon class="mr-2">mdi-delete</v-icon>作廢問題
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex
                  flex-small
                  xs12
                  lg12>
                  <v-autocomplete
                    v-model="problemGroup"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="problemGroupMenu"
                    :disabled="action==='view'"
                    label="Problem Group"
                    class="v-text-field__slot issue_tracking_menu_chips"
                    color="white"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                    chips
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg12>
                  <v-autocomplete
                    v-model="problem"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="problemMenu"
                    :disabled="action==='view'"
                    label="Problem"
                    class="v-text-field__slot issue_tracking_menu_chips"
                    color="white"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                    chips
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg12>
                  <v-autocomplete
                    v-model="mucModule"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="mucModuleMenu"
                    :disabled="action==='view'"
                    label="MucModule"
                    class="v-text-field__slot issue_tracking_menu_chips"
                    color="white"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                    chips
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg12>
                  <v-text-field
                    v-model="includeMemo"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light' , 'row-btn-150' : action==='edit'}"
                    :disabled="action==='view'"
                    label="問題說明包括關鍵字 (多組請以逗號區隔)"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg12>
                  <v-text-field
                    v-model="excludeMemo"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light' , 'row-btn-150' : action==='edit'}"
                    :disabled="action==='view'"
                    label="問題說明排除關鍵字 (多組請以逗號區隔)"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          v-if="(action==='add'||action==='edit')||(action==='view'&&targetWay==='dateCondition')"
          class="d-table-t0 no-data-left row-problem-total"
          style="margin-top: 20px!important;">
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  v-if="(action==='add'||action==='edit')"
                  d-flex
                  xs12
                  lg12>
                  <v-radio-group
                    v-model="targetWay"
                    class="align-items-center"
                    row>
                    <v-radio
                      label="依指定序號查詢"
                      value="sn"/>
                    <v-radio
                      label="依指定條件查詢"
                      value="dateCondition"/>
                  </v-radio-group>
                </v-flex>
                <v-flex
                  v-if="targetWay==='dateCondition'"
                  flex-small
                  xs12
                  lg2>
                  <v-autocomplete
                    v-model="modelYear"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="modelYearMenu"
                    :disabled="action==='view'"
                    label="開賣年度"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  v-if="targetWay==='dateCondition'"
                  flex-small
                  xs12
                  lg3>
                  <v-autocomplete
                    v-model="series"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="seriesMenu"
                    :disabled="action==='view'"
                    label="Series"
                    class="v-text-field__slot issue_tracking_menu_chips"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                    chips
                  />
                </v-flex>
                <v-flex
                  v-if="targetWay==='dateCondition'"
                  flex-small
                  xs12
                  lg3>
                  <v-autocomplete
                    v-model="modelGroup"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="modelGroupMenu"
                    :disabled="action==='view'"
                    label="modelGroup"
                    class="v-text-field__slot issue_tracking_menu_chips"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                    chips
                  />
                </v-flex>
                <v-flex
                  v-if="targetWay==='dateCondition'"
                  flex-small
                  xs12
                  lg4>
                  <v-autocomplete
                    v-model="model"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="modelMenu"
                    :disabled="action==='view'"
                    label="Model"
                    class="v-text-field__slot issue_tracking_menu_chips"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                    chips
                  />
                </v-flex>
                <v-flex
                  v-if="(action==='add'||action==='edit')&&targetWay==='sn'"
                  flex-small
                  xs12
                  lg12>
                  <v-autocomplete
                    v-model="deviceSnListSelect"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="deviceSnList"
                    :disabled="action==='view'"
                    label="追蹤序號清單"
                    class="v-text-field__slot issue_tracking_menu_chips row-btn-325"
                    color="white"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                    chips
                  />
                  <v-btn
                    class="mt-3 ml-3 mr-0 font-weight-light float-right"
                    color="black"
                    @click="resetDeviceSnListItem()"
                  >重置清單</v-btn>
                  <v-btn
                    class="mt-3 ml-3 mr-0 font-weight-light float-right"
                    color="red"
                    @click="cancelSelectDeviceSnListItem()"
                  >取消選擇</v-btn>
                  <v-btn
                    class="mt-3 ml-3 mr-0 font-weight-light float-right"
                    color="blue"
                    @click="selectAllDeviceSnListItem()"
                  >選擇全部</v-btn>
                </v-flex>
                <v-flex
                  v-if="(action==='add'||action==='edit')&&targetWay==='sn'"
                  flex-small
                  xs12
                  lg12>
                  <v-text-field
                    v-model="deviceSnInput"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="手輸序號清單 (多組請以逗號區隔)"
                    class="v-text-field__slot row-btn-450"
                    persistent-hint
                    outlined
                  />
                  <!-- <v-text-field
                    v-model="file[0].name"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="SN檔名"
                    disabled
                    @click="uploadFile()"/> -->
                  <v-btn
                    class="mt-3 ml-3 mr-0 font-weight-light float-right"
                    color="black"
                    @click="doSampleDownload()">
                    <v-icon class="float-left">mdi-file</v-icon>下載範本檔案
                  </v-btn>
                  <v-btn
                    class="mt-3 ml-3 mr-0 font-weight-light float-right"
                    color="black"
                    @click="uploadFile(0)">
                    <v-icon class="float-left">mdi-expand-all</v-icon>匯入序號清單
                  </v-btn>
                  <!-- <v-btn
                    class="mt-3 ml-3 mr-0 font-weight-light float-right"
                    color="black"
                    @click="cancelFile(0)">
                    <v-icon class="float-left">mdi-close</v-icon>清除檔案
                  </v-btn> -->
                  <v-btn
                    class="mt-3 ml-3 mr-0 font-weight-light float-right"
                    color="black"
                    @click="addDeviceSnListItem()">
                    <v-icon class="float-left">mdi-plus</v-icon>新增序號
                  </v-btn>
                  <input
                    v-show="false"
                    id="upload-file"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    type="file"
                    label="檔名"
                    @change="setFile($event)">
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          class="d-table-t0 no-data-left row-problem-total"
          style="margin-top: 20px!important;">
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  v-if="action==='view'"
                  flex-small
                  xs12
                  lg12>
                  <table class="statistic-table analyzer-table">
                    <thead>
                      <tr>
                        <th class="text-center">近期週數</th>
                        <th
                          v-for="(title,t) in responseIssueTrackInfoTableHeaders"
                          :key="'t'+t"
                          class="text-center">{{ title }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(row,r) in responseIssueTrackInfoTableData"
                        :key="'r'+r">
                        <td>{{ row.issue }}</td>
                        <td
                          v-for="(data,d) in row.reportData"
                          :key="'d'+d"
                          class="text-center">{{ data }}</td>
                      </tr>
                    </tbody>
                  </table>
                </v-flex>
                <v-flex
                  v-if="(action==='add'||action==='edit')"
                  flex-small
                  xs12
                  lg12>
                  <v-autocomplete
                    v-model="allowUser"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="allowUserMenu"
                    :disabled="action==='view'"
                    label="可觀看人員"
                    class="v-text-field__slot issue_tracking_menu_chips"
                    color="white"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                    chips
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg12>
                  <v-card-actions class="m-0 border-top border-secondary mt-3">
                    <v-spacer/>
                    <v-btn
                      color="red"
                      class="ml-3"
                      small
                      @click="actionIssueView=false;action=''">
                      <v-icon class="mr-2">mdi-close</v-icon>返回
                    </v-btn>
                    <v-btn
                      v-if="(action==='add'||action==='edit')"
                      color="lime"
                      class="ml-3 text-dark"
                      small
                      @click="doIssueActionBefore(action, actionIssuePk , actionIssueName, actionIssueAllowEdit, true)">
                      <v-icon class="mr-2">mdi-replay</v-icon>重置
                    </v-btn>
                    <v-btn
                      v-if="action==='edit'"
                      color="blue"
                      class="ml-3"
                      small
                      @click="doSaveIssueTrack(actionIssuePk)">
                      <v-icon class="mr-2">mdi-content-save</v-icon>儲存
                    </v-btn>
                    <v-btn
                      v-if="action==='add'"
                      color="pink"
                      class="ml-3"
                      small
                      @click="doAddIssueTrack()">
                      <v-icon class="mr-2">mdi-plus</v-icon>新增
                    </v-btn>
                    <v-btn
                      v-if="action==='view'"
                      color="black"
                      class="ml-3"
                      small
                      @click="doDownload(actionIssuePk)">
                      <v-icon class="mr-2">mdi-export</v-icon>匯出Raw Data
                    </v-btn>
                  </v-card-actions>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :top="true">
      {{ snackbarMsg }}
      <v-btn
        flat
        @click="snackbar = false">
        關閉
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import $ from 'jquery'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'IssueTrackingMgmt',
  components: {
  },
  data () {
    return {
      snackbar: false,
      snackbarColor: 'green',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      loadingOfSeries: false,
      loadingOfModelGroup: false,
      loadingOfModel: false,
      action: '',
      issueTrackListRelated: '',
      issueTrackListRelatedTemp: '',
      issueTrackListRelatedConfirm: false,
      issueTrackListState: 'TRACKING',
      issueTrackListStateTemp: 'TRACKING',
      issueTrackListStateConfirm: false,
      responseIssueTrackListData: [],
      responseIssueTrackInfoData: {},
      responseIssueTrackInfoTableData: [],
      responseIssueTrackInfoTableHeader: [],
      responseExcelToObjectData: [],
      responseExcelToListData: {},
      actionIssueView: false,
      actionIssuePk: 0,
      actionIssueName: '',
      actionIssueAllowEdit: false,
      problemGroup: [],
      problem: [],
      mucModule: [],
      includeMemo: '',
      excludeMemo: '',
      problemGroupMenu: [],
      problemMenu: [],
      mucModuleMenu: [],
      targetWay: 'sn',
      modelYear: '',
      modelYearMenu: [],
      model: [],
      modelTemp: [],
      modelMenu: [],
      modelGroup: [],
      modelGroupTemp: [],
      modelGroupMenu: [],
      series: [],
      seriesTemp: [],
      seriesMenu: [],
      deviceSnList: [],
      deviceSnListSelect: [],
      deviceSnInput: '',
      allowUserMenu: [],
      allowUser: [],
      file: [
        {
          file: '',
          name: '',
          url: ''
        }
      ],
      pagination: {
        rowsPerPage: -1,
        sortBy: '', // The field that you're sorting by
        descending: true
      },
      headers: [
        {
          sortable: true,
          text: '追蹤問題',
          value: 'name',
          class: ''
        },
        {
          sortable: false,
          text: '操作',
          value: 'operation',
          class: ''
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    userPk () {
      return JSON.parse(localStorage.getItem('user')).userPk
    }
  },
  watch: {
    modelYear (val) {
      this.series = []
      this.getSeries(val)
      this.getModelGroup(val, this.series)
      this.getModel(val, this.series, this.modelGroup)
    },
    series (val) {
      this.modelGroup = []
      this.getModelGroup(this.modelYear, val)
      this.getModel(this.modelYear, val, this.modelGroup)
    },
    modelGroup (val) {
      this.model = []
      this.getModel(this.modelYear, this.series, val)
    },
    targetWay (val) {
      this.modelYear = ''
      this.modelMenu = []
      this.model = []
      this.modelGroup = []
      this.modelGroupMenu = []
      this.series = []
      this.seriesMenu = []
      this.deviceSnList = []
      this.deviceSnListSelect = []
      this.deviceSnInput = ''
      this.file = [
        {
          file: '',
          name: '',
          url: ''
        }
      ]
    },
    issueTrackListRelated (val) {
      if (!this.issueTrackListRelatedConfirm) {
        if (this.action === 'add' || this.action === 'edit') {
          if (confirm('目前問題設定區塊內容將不會被儲存，是否繼續此操作?')) {
            this.getIssueTrackList(this.issueTrackListState, val)
          } else {
            this.issueTrackListRelatedConfirm = true
            setTimeout(() => {
              this.issueTrackListRelated = this.issueTrackListRelatedTemp
            }, 0)
          }
        } else {
          this.getIssueTrackList(this.issueTrackListState, val)
        }
      } else {
        this.issueTrackListRelatedConfirm = false
      }
    },
    issueTrackListState (val) {
      if (!this.issueTrackListStateConfirm) {
        if (this.action === 'add' || this.action === 'edit') {
          if (confirm('目前問題設定區塊內容將不會被儲存，是否繼續此操作?')) {
            this.getIssueTrackList(val, this.issueTrackListRelated)
          } else {
            this.issueTrackListStateConfirm = true
            setTimeout(() => {
              this.issueTrackListState = this.issueTrackListStateTemp
            }, 0)
          }
        } else {
          this.getIssueTrackList(val, this.issueTrackListRelated)
        }
      } else {
        this.issueTrackListStateConfirm = false
      }
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/pqm/cfrAnalysisMgmt/issueTrackingMgmtView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          // if (this.$route.params.action) {
          //   this.action = this.$route.params.action
          // }
          this.getIssueTrackList('TRACKING', '')
          this.$http.get('/api/pqm/cfr/getSelectOptionData/problemGroup').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                // this.problemGroupMenu = [{ text: 'All', value: 'All' }].concat(res.data)
                this.problemGroupMenu = res.data
              }
            })
          this.$http.get('/api/pqm/cfr/getSelectOptionData/problem/all').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                // this.problemMenu = [{ text: 'All', value: 'All' }].concat(res.data)
                this.problemMenu = res.data
              }
            })
          this.$http.get('/api/pqm/getSelectOptionData/partName/2').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                // this.mucModuleMenu = [{ text: 'All', value: 'All' }].concat(res.data)
                this.mucModuleMenu = res.data
              }
            })
          this.$http.get('/api/pqm/cfrAnalysisMgmt/modelYear').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.modelYearMenu = res.data
                this.modelYear = ''
              }
            })
          this.$http.get('/api/system/moduleNotificationList/userMail').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                res.data.forEach((n, nn) => {
                  this.allowUserMenu.push({ text: n.name + ' ｜ ' + n.email, value: n.email })
                })
              }
            })
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    getSeries (year) {
      if (year !== '' && !this.loadingOfSeries) {
        this.loadingOfSeries = true
        this.$http.get(`/api/pqm/cfr/series?year=${year}`).then(res => res.data)
          .then(res => {
            this.loadingOfSeries = false
            if (res.status === 'success') {
              this.seriesMenu = res.data
              this.series = this.seriesTemp || []
              setTimeout(() => {
                this.seriesTemp = []
              }, 2000)
            }
          })
      }
    },
    getModelGroup (year, series) {
      if (year !== '' && !this.loadingOfModelGroup) {
        this.loadingOfModelGroup = true
        this.$http.get(`/api/pqm/cfr/modelGroup?year=${year}&seriesList=${series}`).then(res => res.data)
          .then(res => {
            this.loadingOfModelGroup = false
            if (res.status === 'success') {
              this.modelGroupMenu = res.data
              this.modelGroup = this.modelGroupTemp || []
              setTimeout(() => {
                this.modelGroupTemp = []
              }, 2000)
            }
          })
      }
    },
    getModel (year, series, modelGroup) {
      if (year !== '' && !this.loadingOfModel) {
        this.loadingOfModel = true
        this.$http.get(`/api/pqm/cfr/model?year=${year}&seriesList=${series}&modelGroupList=${modelGroup}`).then(res => res.data)
          .then(res => {
            this.loadingOfModel = false
            if (res.status === 'success') {
              this.modelMenu = res.data
              this.model = this.modelTemp || []
              setTimeout(() => {
                this.modelTemp = []
              }, 2000)
            }
          })
      }
    },
    getStatusName (status) {
      switch (status) {
        case 'TRACKING':
          return '追蹤中'
          // break
        case 'FINISHED':
          return '已結案'
          // break
        case 'OBSOLETE':
          return '已作廢'
         //  break
        // default:
        //   return '其他'
        //   // break
      }
    },
    getIssueTrackList (status, related) {
      this.actionIssueView = false
      this.loadingView = true
      this.issueTrackListRelatedTemp = related
      this.issueTrackListStateTemp = status
      // this.$http.get(`/api/pqm/cfrAnalysisMgmt/issueTrack?userPk=${this.userPk}&state=${status}`).then(res => res.data)
      this.$http.get(`/api/pqm/cfrAnalysisMgmt/issueTrack?onlyMePk=${related}&state=${status}`).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.action = ''
            this.actionIssueView = false
            this.responseIssueTrackListData = res.data
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
      // this.loadingView = false
      // this.action = ''
      // this.actionIssueView = false
      // this.responseIssueTrackListData = [
      //   {
      //     issueName: 'A',
      //     state: 'TRACKING',
      //     createdDate: '',
      //     createdUser: '',
      //     updatedDate: '',
      //     updatedUser: '',
      //     deviceCount: '',
      //     issuePk: 1
      //   },
      //   {
      //     issueName: 'B',
      //     state: 'TRACKING',
      //     createdDate: '',
      //     createdUser: '',
      //     updatedDate: '',
      //     updatedUser: '',
      //     deviceCount: '',
      //     issuePk: 2
      //   }
      // ]
    },
    getIssueTrackInfo (action, pk, name, allow, noStatistic) {
      this.actionIssueView = false
      this.targetWay = 'sn'
      if (action === 'add') {
        this.problemGroup = []
        this.problem = []
        this.mucModule = []
        this.includeMemo = ''
        this.excludeMemo = ''
        this.modelYear = ''
        this.series = []
        this.modelGroup = []
        this.model = []
        this.deviceSnList = []
        this.deviceSnListSelect = []
        this.allowUser = []
        this.responseIssueTrackInfoData = {
          deviceCount: 0,
          state: 'TRACKING',
          createdDate: '',
          createdUser: '',
          updatedDate: '',
          updatedUser: '',
          issueName: '',
          problemGroup: [],
          problem: [],
          mucModule: [],
          includeMemo: '',
          excludeMemo: '',
          allowUser: []
        }
        this.responseIssueTrackInfoTableData = [
          {
            issue: '',
            reportData: []
          }
        ]
        this.responseIssueTrackInfoTableHeaders = []
        this.actionIssuePk = pk
        this.actionIssueName = name
        this.actionIssueAllowEdit = false
        this.action = action
        this.actionIssueView = true
      } else {
        this.loadingView = true
        this.$http.get(`/api/pqm/cfrAnalysisMgmt/issueTrack/${pk}?noStatistic=${noStatistic}`).then(res => res.data)
          .then(res => {
            this.loadingView = false
            if (res.status === 'success') {
              if (res.data.reportSummary.target.deviceIsnList.length === 0) {
                this.targetWay = 'dateCondition'
                this.processingView = true
              }
              this.problemGroup = res.data.reportSummary.tracingProblem.problemGroup
              this.problem = res.data.reportSummary.tracingProblem.problem
              this.mucModule = res.data.reportSummary.tracingProblem.mucModule
              this.includeMemo = res.data.reportSummary.tracingProblem.includeMemo.join(',')
              this.excludeMemo = res.data.reportSummary.tracingProblem.excludeMemo.join(',')
              this.deviceSnList = res.data.reportSummary.target.deviceIsnList
              this.deviceSnListSelect = res.data.reportSummary.target.deviceIsnList
              setTimeout(() => {
                this.modelYear = res.data.reportSummary.target.year
                this.seriesTemp = res.data.reportSummary.target.series
                this.modelGroupTemp = res.data.reportSummary.target.modelGroup
                this.modelTemp = res.data.reportSummary.target.model
              }, 0)
              this.allowUser = res.data.reportSummary.allowUser
              this.responseIssueTrackInfoData = res.data.reportSummary
              this.responseIssueTrackInfoTableData = res.data.reportRows
              this.responseIssueTrackInfoTableHeaders = res.data.reportColumns
              this.actionIssuePk = pk
              this.actionIssueName = name
              this.actionIssueAllowEdit = allow
              this.action = action
              this.actionIssueView = true
              setTimeout(() => {
                this.processingView = false
              }, 3000)
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
        // this.loadingView = false
        // this.responseIssueTrackInfoData = {
        //   deviceCount: 1,
        //   state: 'TRACKING',
        //   createdDate: '',
        //   createdUser: '',
        //   updatedDate: '',
        //   updatedUser: '',
        //   issueName: '',
        //   problemGroup: [],
        //   problem: [],
        //   mucModule: []
        // }
        // this.responseIssueTrackInfoTableData = [
        //   {
        //     issue: 'ASDQWE123',
        //     reportData: [1, 0, 2, 0]
        //   }
        // ]
        // this.responseIssueTrackInfoTableHeaders = ['W2101', 'W2102', 'W2103', 'W2104']
        // this.actionIssuePk = pk
        // this.actionIssueName = name
        // this.action = action
        // this.actionIssueView = true
      }
    },
    doIssueActionBefore (action, pk, name, allow, noStatistic) {
      if (this.action === 'add' || this.action === 'edit') {
        if (confirm('目前問題設定區塊內容將不會被儲存，是否繼續此操作?')) {
          this.getIssueTrackInfo(action, pk, name, allow, noStatistic)
        }
      } else {
        this.getIssueTrackInfo(action, pk, name, allow, noStatistic)
      }
    },
    doAddIssueTrack () {
      if (this.actionIssueName.length > 0) {
        if (this.targetWay === 'sn' && this.deviceSnListSelect.length === 0) {
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = '追蹤序號不得為空'
        } else if (this.targetWay === 'dateCondition' && (this.series.length === 0 && this.modelGroup.length === 0 && this.model.length === 0)) {
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = '年度以外機種條件不得為空'
        } else {
          this.processingView = true
          const body = {
            issueName: this.actionIssueName,
            target: {
              deviceSnList: this.deviceSnListSelect,
              year: this.modelYear,
              series: this.series,
              modelGroup: this.modelGroup,
              model: this.model
            },
            tracingProblem: {
              problemGroup: this.problemGroup,
              problem: this.problem,
              mucModule: this.mucModule,
              includeMemo: this.includeMemo.split(','),
              excludeMemo: this.excludeMemo.split(',')
            },
            allowUser: this.allowUser
          }
          this.$http.post('/api/pqm/cfrAnalysisMgmt/issueTrack', body).then(res => res.data)
            .then(res => {
              this.processingView = false
              if (res.status === 'success') {
                this.snackbar = true
                this.snackbarColor = 'green'
                this.snackbarMsg = '問題新增成功'
                this.action = ''
                this.actionIssueView = false
                this.getIssueTrackList(this.issueTrackListState, this.issueTrackListRelated)
              } else { // failure
                this.snackbar = true
                this.snackbarColor = 'red'
                this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
              }
            }).catch(err => {
              this.processingView = false
              this.snackbar = true
              this.snackbarColor = 'black'
              this.snackbarMsg = err
            })
        }
      } else {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '問題名稱不得為空'
      }
    },
    doSaveIssueTrack (pk) {
      if (this.actionIssueName.length > 0) {
        if (this.targetWay === 'sn' && this.deviceSnListSelect.length === 0) {
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = '追蹤序號不得為空'
        } else if (this.targetWay === 'dateCondition' && (this.series.length === 0 && this.modelGroup.length === 0 && this.model.length === 0)) {
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = '年度以外機種條件不得為空'
        } else {
          this.processingView = true
          const body = {
            issueName: this.actionIssueName,
            target: {
              deviceSnList: this.deviceSnListSelect,
              year: this.modelYear,
              series: this.series,
              modelGroup: this.modelGroup,
              model: this.model
            },
            tracingProblem: {
              problemGroup: this.problemGroup,
              problem: this.problem,
              mucModule: this.mucModule,
              includeMemo: this.includeMemo.split(','),
              excludeMemo: this.excludeMemo.split(',')
            },
            allowUser: this.allowUser
          }
          this.$http.put(`/api/pqm/cfrAnalysisMgmt/issueTrack/${pk}`, body).then(res => res.data)
            .then(res => {
              this.processingView = false
              if (res.status === 'success') {
                this.snackbar = true
                this.snackbarColor = 'green'
                this.snackbarMsg = '問題編輯成功'
                this.action = ''
                this.actionIssueView = false
                this.getIssueTrackList(this.issueTrackListState, this.issueTrackListRelated)
              } else { // failure
                this.snackbar = true
                this.snackbarColor = 'red'
                this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
              }
            }).catch(err => {
              this.processingView = false
              this.snackbar = true
              this.snackbarColor = 'black'
              this.snackbarMsg = err
            })
        }
      } else {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '問題名稱不得為空'
      }
    },
    doChangeIssueTrackStatus (pk, status, text) {
      if (confirm('是否確認' + text + '問題?')) {
        this.processingView = true
        const body = {
          state: status
        }
        this.$http.put(`/api/pqm/cfrAnalysisMgmt/issueTrackState/${pk}`, body).then(res => res.data)
          .then(res => {
            this.processingView = false
            if (res.status === 'success') {
              this.snackbar = true
              this.snackbarColor = 'green'
              this.snackbarMsg = '成功變更問題狀態為[已' + text + ']'
              this.action = ''
              this.actionIssueView = false
              this.getIssueTrackList(this.issueTrackListState, this.issueTrackListRelated)
            } else { // failure
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          }).catch(err => {
            this.processingView = false
            this.snackbar = true
            this.snackbarColor = 'black'
            this.snackbarMsg = err
          })
      }
    },
    resetDeviceSnListItem () {
      this.deviceSnList = []
      this.deviceSnListSelect = []
    },
    addDeviceSnListItem () {
      if (this.deviceSnInput.length > 0) {
        this.deviceSnList = this.deviceSnInput.split(',').concat(this.deviceSnList)
        this.deviceSnList = this.deviceSnList.filter((element, index, arr) => {
          return arr.indexOf(element) === index
        })
        this.deviceSnInput = ''
        this.selectAllDeviceSnListItem()
      } else {
        alert('請輸入序號')
      }
    },
    ImportDeviceSnListItem () {
      this.deviceSnList = this.responseExcelToListData.device_isn.concat(this.deviceSnList)
      this.deviceSnList = this.deviceSnList.filter((element, index, arr) => {
        return arr.indexOf(element) === index
      })
      this.selectAllDeviceSnListItem()
    },
    cancelSelectDeviceSnListItem () {
      this.deviceSnListSelect = []
    },
    selectAllDeviceSnListItem () {
      this.deviceSnListSelect = this.deviceSnList
    },
    conversionExcelFieldsToObject () {
      if (this.file[0].file) {
        this.processingView = true
        const formData = new FormData()
        formData.append('file', this.file[0].file)
        formData.append('sourceFields', ['device_isn'])
        this.$http.post('/api/pqm/cfrAnalysisMgmt/parseExcelFieldsToObject', formData).then(res => res.data)
          .then(res => {
            this.processingView = false
            this.cancelFile()
            if (res.status === 'success') {
              this.responseExcelToObjectData = res.data
              setTimeout(() => {
                this.ImportDeviceSnListItem()
              }, 0)
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      } else {
        this.processingView = false
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '檔名或檔案不得為空'
      }
    },
    conversionExcelFieldsToList () {
      if (this.file[0].file) {
        this.processingView = true
        const formData = new FormData()
        formData.append('file', this.file[0].file)
        formData.append('sourceFields', ['device_isn'])
        this.$http.post('/api/pqm/cfrAnalysisMgmt/parseExcelFieldsToList', formData).then(res => res.data)
          .then(res => {
            this.processingView = false
            this.cancelFile()
            if (res.status === 'success') {
              this.responseExcelToListData = res.data
              setTimeout(() => {
                this.ImportDeviceSnListItem()
              }, 0)
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      } else {
        this.processingView = false
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '檔名或檔案不得為空'
      }
    },
    uploadFile (type) {
      $('#upload-file').click()
    },
    cancelFile () {
      this.file[0].file = this.file[0].name = this.file[0].url = ''
      $('#upload-file').val('')
    },
    setFile (e) {
      const files = e.target.files || e.dataTransfer.files
      this.file[0].url = window.URL.createObjectURL(e.srcElement.files.item(0))
      this.file[0].file = files[0]
      this.file[0].name = e.srcElement.files.item(0).name
      setTimeout(() => {
        this.conversionExcelFieldsToList()
      }, 0)
    },
    doDownload (pk) {
      this.processingView = true
      this.$http.get(`/api/pqm/cfrAnalysisMgmt/issueTrackListFile?issuePk=${pk}`, { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.status === 'success') {
                  alert(res.data.data)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '無檔案可供下載'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    },
    doSampleDownload () {
      this.processingView = true
      this.$http.get('/api/pqm/cfrAnalysisMgmt/issueTrackDeviceIsnListSampleFile', { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.status === 'success') {
                  alert(res.data.data)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '無檔案可供下載'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    }
  }
}
</script>
<style>
</style>
