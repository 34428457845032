<template>
  <v-container
    csc
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
        lg6
      >
        <material-card
          title="使用者下載資源定義概覽"
          class="card-padding v-card__first"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  xs12
                  sm6
                  lg6>
                  <div>
                    使用者下載定義記錄 {{ responseData.length }} 筆
                  </div>
                </v-flex>
                <v-flex
                  xs12
                  lg6
                  text-xs-right
                >
                  <v-btn
                    class="mr-0 ml-3 font-weight-light"
                    color="blue"
                    @click="doAddWindows()"
                  >
                    新增
                    <!-- <v-icon class="mr-2">mdi-plus</v-icon>新增 -->
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        xs12
        lg12
      />
      <v-flex
        flex_table_width_auto
        xs12
        lg12>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="使用者下載定義列表"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <!-- <v-select
            v-model="pagination.current.rowsPerPage"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
          <v-data-table
            :headers="responseHeaders"
            :items="responseData"
            :pagination.sync="pagination.current"
            :rows-per-page-items ="rowsAmount"
            class="height-row-3 table-align-left"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr>
                <td>{{ item.fileOriginalName }}</td>
                <td>{{ item.displayLabel }}</td>
                <td>{{ item.fileDownloadName }}</td>
                <td>{{ item.updatedDate | dateFormat }}</td>
                <td>{{ item.updatedUserName }}</td>
                <td>
                  <div>
                    <v-btn
                      class="ml-0 mr-2 font-weight-bold small"
                      color="orange"
                      @click="doDownload(item.apiKey)">
                      <v-icon class="mr-2">mdi-download</v-icon>下載
                    </v-btn>
                    <v-btn
                      class="ml-0 mr-2 font-weight-bold small"
                      color="deep-purple"
                      @click="doEditWindows(item)">
                      <v-icon class="mr-2">mdi-pencil</v-icon>修改
                    </v-btn>
                    <v-btn
                      class="mx-0 font-weight-bold small"
                      color="red"
                      @click="doDelete(item.pk)">
                      <v-icon class="mr-2">mdi-close</v-icon>刪除
                    </v-btn>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
          <!-- <div
            v-if="pagination.current.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="pagination.page"
              :length="pages"/>
          </div> -->
          <!-- <v-autocomplete
            v-if="pagination.current.rowsPerPage != -1"
            v-model="pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="windowsForAddOrEdit"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card
          max-width="35%"
          style="margin:0 auto;">
          <v-card-text>
            <v-alert
              v-model="windowsForAddOrEdit"
              class="mt-3 mb-3 border-0"
              color="deep-orange">
              <strong class="h4">{{ (action==='add')?'新增':'修改' }}使用者下載定義維護</strong>
            </v-alert>
            <v-flex
              flex-small
              d-flex
              align-items-center
              xs12
              lg12>
              <v-flex
                flex-small
                align-items-left
                xs12
                lg5
              >
                <p class="m-0">
                  <span class="required">*</span>
                  <small>原始檔名</small>
                </p>
              </v-flex>
              <v-flex
                xs12
                lg7
              >
                <p v-show="action==='edit' && file[0].file.name==undefined">{{ windows.fileOriginalName }}</p>
                <p v-show="file[0].file.name!=undefined">{{ file[0].file.name }}</p>
                <input
                  v-show="false"
                  id="upload-file"
                  type="file"
                  data-target="file-uploader"
                  persistent-hint
                  @change="setFile($event)">
                <v-btn
                  class="ml-0 mr-1 mix-btn black text-light"
                  @click="uploadFile(0)">
                  <v-icon class="float-left">mdi-upload</v-icon>選擇上傳檔案
                </v-btn>
                <v-btn
                  v-show="file[0].file.name"
                  class="ml-0 mr-0 mix-btn black text-light"
                  style="width:50px;"
                  @click="cancelFile()">
                  <v-icon class="float-left">clear</v-icon>
                </v-btn>
                <p
                  v-show="fileErrorSelete"
                  class="v-messages theme--dark error--text" >請選取檔案</p>
              </v-flex>
            </v-flex>
            <v-flex
              flex-small
              d-flex
              align-items-center
              xs12
              lg12>
              <v-flex
                flex-small
                align-items-left
                xs12
                lg5
              >
                <p class="m-0">
                  <span class="required">*</span>
                  <small>前端顯示標籤</small>
                </p>
              </v-flex>
              <v-flex
                xs12
                lg7
              >
                <v-text-field
                  v-model="windows.displayLabel"
                  :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                  :rules="[(v) => !!v || '顯示標籤不可空白']"
                  placeholder="顯示於前端的檔案描述"
                  required
                  label=""/>
              </v-flex>
            </v-flex>
            <v-flex
              flex-small
              d-flex
              align-items-center
              xs12
              lg12>
              <v-flex
                flex-small
                align-items-left
                xs12
                lg5
              >
                <p class="m-0">
                  <span class="required">*</span>
                  <small>檔案下載名稱</small>
                </p>
              </v-flex>
              <v-flex
                xs12
                lg7
              >
                <v-text-field
                  v-model="windows.fileDownloadName"
                  :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                  :rules="[(v) => !!v || '檔案下載名稱不可空白']"
                  placeholder="連結下載後的檔案名稱"
                  required
                  label=""/>
              </v-flex>
            </v-flex>
            <v-flex
              flex-small
              d-flex
              align-items-center
              xs12
              lg12>
              <v-flex
                flex-small
                align-items-left
                xs12
                lg5
              >
                <p class="m-0">
                  <span class="no_required" />
                  <small>API_KEY</small>
                </p>
              </v-flex>
              <v-flex
                xs12
                lg7
              >
                <v-text-field
                  v-model="windows.apiKey"
                  :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                  placeholder="與前端元件綁定的鍵值(可忽略)"
                  label=""/>
              </v-flex>
            </v-flex>
            <h6
              v-show="action==='edit'"
              style="text-align: center;color: coral;">若APK_KEY有修改，請告知系統維護人員。</h6>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              color="red"
              small
              @click="windowsForAddOrEdit=false">
              <v-icon class="mr-2">mdi-close</v-icon>取消
            </v-btn>
            <v-btn
              v-if="action==='add'"
              color="blue"
              small
              @click="doAdd()">
              <v-icon class="mr-2">mdi-plus</v-icon>新增
            </v-btn>
            <v-btn
              v-if="action==='edit'"
              color="blue"
              small
              @click="doEdit()">
              <v-icon class="mr-2">mdi-content-save</v-icon>儲存
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import $ from 'jquery'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'DownloadResourceDefinitionMaintain',
  filters: {
    dateFormat: function (value) {
      const pad = (v) => {
        return (v < 10) ? '0' + v : v
      }

      const getDateString = (d) => {
        const year = d.getFullYear()
        const month = pad(d.getMonth() + 1)
        const day = pad(d.getDate())
        const hour = pad(d.getHours())
        const min = pad(d.getMinutes())
        const sec = pad(d.getSeconds())
        return `${year}-${month}-${day}  ${hour}:${min}:${sec}`
      }
      if (!value) {
        return ''
      }
      return getDateString(new Date(value))
    }
  },
  data () {
    return {
      action: 'add',
      windowsForAddOrEdit: false,
      snackbar: false,
      snackbarColor: 'red',
      postFileFormData: null,
      fileErrorSelete: false,
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      reasonCode: '',
      reasonCode_check: '',
      file: [
        {
          file: '',
          name: '',
          url: ''
        }
      ],
      windows: {
        pk: 0,
        reasonCode: '',
        displayLabel: '',
        fileDownloadName: '',
        apiKey: ''
      },
      submit: false,
      IsSubmitPage: false,
      responseData: [],
      responseHeaders: [
        {
          align: '',
          class: '',
          sortable: true,
          text: '原始檔名',
          value: 'fileOriginalName'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '前端顯示標籤',
          value: 'displayLabel'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '檔案下載名稱',
          value: 'fileDownloadName'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '上傳時間',
          value: 'createdDate'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '上傳使用者',
          value: 'createdUser'
        }, {
          align: '',
          class: '',
          sortable: false,
          text: '操作',
          value: 'operation'
        }
      ],
      // rowsAmount: [25, 50, 100, { text: 'All', value: -1 }],
      rowsAmount: [10, 25, 50, 100],
      pagination: {
        page: 1,
        current: {
          descending: null,
          page: 1,
          rowsPerPage: 25,
          sortBy: null,
          totalItems: 0
        }
      },
      pages: 1,
      totalRows: 0
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    // pages () {
    //   if(this.responseData.length > 0) {
    //     return this.pagination.rowsPerPage ? Math.ceil(this.responseData.length / this.pagination.rowsPerPage) : 10
    //   }
    // },
    pageslist () {
      let i = 0
      const list = []
      if (this.pagination.current.rowsPerPage) {
        for (i = 0; i < Math.ceil(this.pagination.current.totalItems / this.pagination.current.rowsPerPage); i++) {
          list.push(i + 1)
        }
      }
      return list
    }
  },
  watch: {
    // perPageRows (val) {
    //   if (val === -1) {
    //     if (confirm('完整顯示將可能導致效能降低，是否仍完整顯示?')) {
    //       this.pagination.current.rowsPerPage = val
    //       this.pages = Math.ceil(this.pagination.current.totalItems / this.pagination.current.rowsPerPage)
    //       this.pagination.page = 1
    //     } else {
    //       setTimeout(() => {
    //         this.perPageRows = this.pagination.current.rowsPerPage
    //       }, 100)
    //     }
    //   } else {
    //     this.pagination.current.rowsPerPage = val
    //     this.pages = Math.ceil(this.pagination.current.totalItems / this.pagination.current.rowsPerPage)
    //     this.pagination.page = 1
    //   }
    // },
    pagination: {
      handler (newName, oldName) {
        if (this.submit && !this.IsSubmitPage) {
          this.doSubmit()
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    // this.doSubmit()
    // 判定是否 存在此頁
    this.$store.dispatch('getAuthorize', '/pqm/cscDataParsingMgmt/downloadResourceDefinitionMaintainView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          this.doSubmit()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    paginate () {
      alert('x')
    },
    doAddWindows () {
      this.action = 'add'
      this.doClear()
      this.windowsForAddOrEdit = true
    },
    doEditWindows (item) {
      this.action = 'edit'
      this.doClear()
      this.windows = {
        fileOriginalName: item.fileOriginalName,
        pk: item.pk,
        displayLabel: item.displayLabel,
        fileDownloadName: item.fileDownloadName,
        apiKey: item.apiKey
      }
      this.windowsForAddOrEdit = true
    },
    doClear () {
      this.fileErrorSelete = false
      this.$refs.form.resetValidation()
      this.cancelFile()
      this.windows = {
        pk: '',
        displayLabel: '',
        fileDownloadName: '',
        apiKey: ''
      }
    },
    doSubmitBefore () {
      console.log('before')
      this.reasonCode_check = this.reasonCode
      this.level_check = this.level
      this.pagination.page = 1
      this.doSubmit()
    },
    doSubmit () {
      console.log('submit')
      this.submit = true
      this.loadingView = true
      const body = {
        pageNum: this.pagination.page,
        pageRows: this.pagination.current.rowsPerPage,
        sortColumn: (this.pagination.current.descending !== null) ? this.pagination.current.sortBy : '',
        sortType: this.pagination.current.descending ? 'DESC' : ((this.pagination.current.descending !== null) ? 'ASC' : ''),
        reasonCode: this.reasonCode_check,
        level: this.level_check
      }
      this.$http.get('/api/pqm/cscDataParsingMgmt/downloadResourceDefinitionList', body).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.responseData = res.data
            this.IsSubmitPage = true
            this.pagination.current.totalItems = 2
            // this.pagination.current.totalItems = res.data.totalRows
          }
          return res
        })
        .then(res => {
          if (res.status === 'success') {
            this.loadingView = false
            this.pages = 1
            // this.pages = Math.ceil(res.data.totalRows / this.pagination.current.rowsPerPage)
            this.IsSubmitPage = false
          }
        })
    },
    doAdd () {
      if (this.file[0].url) {
        this.fileErrorSelete = false
        if (this.$refs.form.validate()) {
          this.postFileFormData.append('apiKey', this.windows.apiKey || '')
          this.postFileFormData.append('fileDownloadName', this.windows.fileDownloadName)
          this.postFileFormData.append('displayLabel', this.windows.displayLabel)
          this.postFileFormData.append('pk', '')
          this.submit = true
          this.windowsForAddOrEdit = false
          this.processingView = true
          this.$http.put('/api/pqm/cscDataParsingMgmt/downloadResourceDefinition', this.postFileFormData).then(res => res.data)
            .then(res => {
              this.processingView = false
              if (res.status === 'success') {
                this.snackbar = true
                this.snackbarColor = 'green'
                this.snackbarMsg = '新增成功'
                this.doClear()
                this.doSubmit()
              } else { // failure
                this.windowsForAddOrEdit = true
                this.snackbar = true
                this.snackbarColor = 'red'
                this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
              }
            })
        }
      } else {
        this.fileErrorSelete = true
      }
    },
    doEdit () {
      if (this.$refs.form.validate()) {
        this.submit = true
        this.windowsForAddOrEdit = false
        this.processingView = true
        if (this.postFileFormData == null) {
          this.postFileFormData = new FormData()
        }

        this.postFileFormData.append('apiKey', this.windows.apiKey || '')
        this.postFileFormData.append('fileDownloadName', this.windows.fileDownloadName)
        this.postFileFormData.append('displayLabel', this.windows.displayLabel)
        this.postFileFormData.append('pk', this.windows.pk)

        this.$http.put('/api/pqm/cscDataParsingMgmt/downloadResourceDefinition', this.postFileFormData).then(res => res.data)
          .then(res => {
            this.processingView = false
            if (res.status === 'success') {
              this.snackbar = true
              this.snackbarColor = 'green'
              this.snackbarMsg = '修改成功'
              this.doSubmit()
              this.doClear()
            } else { // failure
              this.windowsForAddOrEdit = true
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      }
    },
    doDelete (pk) {
      if (confirm('是否確認刪除?')) {
        this.processingView = true
        this.$http.delete(`/api/pqm/cscDataParsingMgmt/downloadResource/${pk}`).then(res => res.data)
          .then(res => {
            this.processingView = false
            if (res.status === 'success') {
              this.snackbar = true
              this.snackbarColor = 'green'
              this.snackbarMsg = '刪除成功'
              this.doSubmit()
            } else { // failure
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      }
    },
    doDownload (apiKey) {
      this.$http.get('api/pqm/cscDataParsingMgmt/downloadResource/' + apiKey, { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                alert(res.data.data)
                resolve(Promise.reject(res.data.data))
              }
              reader.onerror = () => {
                reject(res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            let fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            fileName = decodeURI(fileName)
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '無原始檔案'
          }
        })
    },
    uploadFile (type) {
      $('#upload-file').click()
    },
    cancelFile () {
      this.file[0].file = this.file[0].name = this.file[0].url = ''
      this.postFileFormData = null
      $('#upload-file').val('')
    },
    setFile (e) {
      const files = e.target.files || e.dataTransfer.files
      this.file[0].url = window.URL.createObjectURL(e.srcElement.files.item(0))
      this.file[0].file = files[0]
      this.file[0].name = e.srcElement.files.item(0).name
      this.doUpload()
    },
    doUpload () {
      const formData = new FormData()
      formData.append('file', this.file[0].file)
      this.postFileFormData = formData
      this.fileErrorSelete = false
      this.fileType = -1
    }
  }
}
</script>
<style>
  .required{
    color:red;
    padding: 2px;
  }
  .no_required{
    color:red;
    padding: 7.5px;
  }
  .btn{
    padding: 8px;
  }
</style>
