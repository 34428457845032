<template>
  <v-container
    cfr
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
        lg12
      >
        <material-card
          title="料件不良率統計"
          class="card-padding v-card__first"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  flex-small
                  xs12
                  lg2>
                  <v-autocomplete
                    v-model="pnPrefixCandidate"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="pnPrefixCandidateMenu"
                    item-value="text"
                    item-text="text"
                    label="料件類別"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg3>
                  <v-text-field
                    v-model="pn"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="料件P/N"/>
                </v-flex>
                <v-flex
                  xs12
                  lg7
                  text-xs-right
                >
                  <v-btn
                    class="mx-0 mr-3 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>統計
                  </v-btn>
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="indigo"
                    @click="doDownload()"
                  >
                    <v-icon class="mr-2">mdi-export</v-icon>匯出Raw Data
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        v-if="submit"
        flex_table_width_auto
        xs12
        lg12>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="不良率統計結果列表"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <input
            v-model="filter"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            class="mix-btn text-light float-right p-1 border-white border-bottom"
            style="right: 4rem;"
            type="text"
            persistent-hint>
          <v-icon class="mix-btn text-light float-right px-0">mdi-filter</v-icon>
          <v-data-table
            :headers="statisticType==='normal'?responseHeaders:responseHeadersHd"
            :items="statisticData"
            class="height-row-3 table-align-left merge-table"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                :rowspan="header.rowspan"
                :colspan="header.children?header.children.length:1">
                {{ header.text }}
              </span>
              <!-- <tr>
                <th
                  v-for="(h1,i1) in h"
                  :key="i1"
                  class="text-center child-header td-border-style">
                  {{ h1.text }}
                </th>
              </tr> -->
              <!-- <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              /> -->
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr v-if="(filter==='')||(item.pn.indexOf(filter)>-1)||(item.vendor.indexOf(filter)>-1)||(item.model.indexOf(filter)>-1)">
                <td>{{ item.pn }}</td>
                <td>{{ item.vendor }}</td>
                <td v-if="statisticType==='normal'">{{ item.repairQty }}</td>
                <td v-if="statisticType==='normal'">{{ item.activeQty }}</td>
                <td v-if="statisticType==='normal'">{{ (item.cfr*100).toFixed(2) }}%</td>
                <td v-if="statisticType==='normal'">{{ (item.afr*100).toFixed(2) }}%</td>
                <td v-if="statisticType==='hd'">{{ item.ssdRepairQty }}</td>
                <td v-if="statisticType==='hd'">{{ item.ssdActiveQty }}</td>
                <td v-if="statisticType==='hd'">{{ (item.ssdCfr*100).toFixed(2) }}%</td>
                <td v-if="statisticType==='hd'">{{ (item.ssdAfr*100).toFixed(2) }}%</td>
                <td v-if="statisticType==='hd'">{{ item.hdRepairQty }}</td>
                <td v-if="statisticType==='hd'">{{ item.hdActiveQty }}</td>
                <td v-if="statisticType==='hd'">{{ (item.hdCfr*100).toFixed(2) }}%</td>
                <td v-if="statisticType==='hd'">{{ (item.hdAfr*100).toFixed(2) }}%</td>
                <td style="width:600px;">{{ item.model }}</td>
              </tr>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
// import $ from 'jquery'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'
export default {
  name: 'RepairPartsStatistic',
  data () {
    return {
      submit: false,
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      pn: '',
      pnPrefixCandidate: '',
      pnPrefixCandidateMenu: [],
      filter: '',
      statisticType: 'normal',
      responseData: [],
      responseHeaders: [
        {
          align: '',
          class: '',
          sortable: true,
          text: 'P/N',
          value: 'pn'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: 'Vendor',
          value: 'vendor'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '不良數',
          value: 'repairQty'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '激活數',
          value: 'activeQty'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: 'CFR(A)',
          value: 'cfr'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: 'AFR(2Y)',
          value: 'afr'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: 'Model',
          value: 'model'
        }
      ],
      responseHeadersHd: [
        {
          align: '',
          class: 'rowspan-2',
          sortable: true,
          text: 'P/N',
          value: 'pn'
        }, {
          align: '',
          class: 'rowspan-2',
          sortable: true,
          text: 'Vendor',
          value: 'vendor'
        }, {
          align: '',
          class: 'rowspan-children colspan-title-ssd',
          sortable: true,
          text: '不良數',
          value: 'ssdRepairQty'
        }, {
          align: '',
          class: 'rowspan-children',
          sortable: true,
          text: '激活數',
          value: 'ssdActiveQty'
        }, {
          align: '',
          class: 'rowspan-children',
          sortable: true,
          text: 'CFR(A)',
          value: 'ssdCfr'
        }, {
          align: '',
          class: 'rowspan-children',
          sortable: true,
          text: 'AFR(2Y)',
          value: 'ssdAfr'
        }, {
          align: '',
          class: 'rowspan-children colspan-title-hd',
          sortable: true,
          text: '不良數',
          value: 'hdRepairQty'
        }, {
          align: '',
          class: 'rowspan-children',
          sortable: true,
          text: '激活數',
          value: 'hdActiveQty'
        }, {
          align: '',
          class: 'rowspan-children',
          sortable: true,
          text: 'CFR(A)',
          value: 'hdCfr'
        }, {
          align: '',
          class: 'rowspan-children',
          sortable: true,
          text: 'AFR(2Y)',
          value: 'hdAfr'
        }, {
          align: '',
          class: 'rowspan-2',
          sortable: true,
          text: 'Model',
          value: 'model'
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    statisticData () {
      const data = []
      this.responseData.forEach((r, index) => {
        data.push({})
        data[index].pn = r.pn
        data[index].vendor = r.vendor
        data[index].model = r.model.sort().join(' , ')
        r.classification.forEach((c) => {
          if (c.class === 'ssd') {
            data[index].ssdRepairQty = c.repairQty
            data[index].ssdActiveQty = c.activeQty
            data[index].ssdCfr = c.cfr
            data[index].ssdAfr = c.afr
          } else if (c.class === 'hd') {
            data[index].hdRepairQty = c.repairQty
            data[index].hdActiveQty = c.activeQty
            data[index].hdCfr = c.cfr
            data[index].hdAfr = c.afr
          } else {
            data[index].repairQty = c.repairQty
            data[index].activeQty = c.activeQty
            data[index].cfr = c.cfr
            data[index].afr = c.afr
          }
        })
      })
      return data
    }
  },
  watch: {
    pnPrefixCandidate (val) {
      this.pn = this.pnPrefixCandidateMenu[this.pnPrefixCandidateMenu.map(function (e) { return e.text }).indexOf(val)].value
    }
    // pn (val) {
    //   if (val.length >= 2) {
    //     this.pnPrefixCandidate = val.substr(0, 2)
    //   }
    // }
  },
  mounted () {
    this.checkPermission()
    this.getPartsNoPrefix()
  },
  methods: {
    checkPermission () {
      this.$store.dispatch('getAuthorize', '/pqm/cfrAnalysisMgmt/repairPartsStatisticView')
        .then(response => {
          if (response.status === 'success' && response.data.accessible) {
            // this.doSubmit()
          } else if (response.errCode === 'E10000002') {
            const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
            localStorage.setItem('errormsg', response.errMsg)
            window.location.assign(routeData.href)
          } else {
            const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
            window.location.assign(routeData.href)
          }
        })
        .catch(err => {
          console.log(err)
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        })
    },
    getPartsNoPrefix () {
      this.$http.get('/api/pqm/cfr/materialPartNoPrefixList').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.pnPrefixCandidateMenu = res.data
          }
        })
    },
    inputPnPrefix (value, index) {
      this.pn = value
    },
    doSubmit () {
      if (this.pn.length >= 2) {
        this.loadingView = true
        const body = {
          pn: this.pn,
          partName: this.pnPrefixCandidate
        }
        this.$http.post('/api/pqm/cfrAnalysisMgmt/repairPartsStatisticList', body).then(res => res.data)
          .then(res => {
            this.loadingView = false
            if (res.status === 'success') {
              this.submit = true
              this.responseData = res.data.statisticData
              this.statisticType = res.data.statisticType
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      } else {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '料件P/N不得為空或少於2字元'
      }
    },
    doDownload () {
      if (this.pn.length > 0) {
        this.processingView = true
        const body = {
          pn: this.pn,
          partName: this.pnPrefixCandidate
        }
        this.$http.post('/api/pqm/cfrAnalysisMgmt/repairPartsStatisticListFile', body, { responseType: 'blob' })
          .then(res => {
            this.processingView = false
            if (res.data.type === 'application/json') {
              return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                  res.data = JSON.parse(reader.result)
                  alert(res.data.data)
                  resolve(Promise.reject(res.data.data))
                }
                reader.onerror = () => {
                  reject(res.data.data)
                }
                reader.readAsText(res.data)
              })
            } else if (res.status !== 'failure' && res.headers['content-disposition']) {
              const blob = new Blob([res.data], { type: 'application/octet-stream' })
              const url = window.URL.createObjectURL(blob)
              const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
              const downloadLink = document.createElement('a')
              downloadLink.href = url
              downloadLink.download = fileName

              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = '無原始檔案'
            }
          })
      } else {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '料號不得為空'
      }
    }
  }
}
</script>
<style>
.hint_item {
  margin-left: 8px;
}
</style>
