<template>
  <v-container
    cfr
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        mt-3
        xs12
      >
        <!-- <material-card
          title="L3/L4原始維修資料查詢"
          color="general long-title-bar"
          class="card-padding pt-0 pb-3"
          style="width: calc(50% - 5px)!important;margin-right: 5px!important;display: inline-block!important;"> -->
        <!-- <material-card
          class="pt-5 pb-1"
          style="width: calc(50% - 5px)!important;margin-left: 5px!important;display: inline-block!important;"> -->
        <material-card
          title="L3/L4原始維修資料查詢"
          color="general"
          class="card-mix card-padding pt-0 pb-3">
          <v-btn
            class="mix-btn mx-0 font-weight-light small"
            @click="modelYear=[];series=[];modelGroup=[];model=[];mucModule=[];columnName='';listDataColumnName=[];listData={}"
          >清除重置</v-btn>
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  flex-small
                  xs12
                  lg1>
                  <v-autocomplete
                    v-model="modelYear"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="modelYearMenu"
                    label="開賣年度"
                    class="v-text-field__slot"
                    style="margin-top: 10px;"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg2>
                  <v-autocomplete
                    v-model="series"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="seriesMenu"
                    label="Series"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                    chips
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg3>
                  <v-autocomplete
                    v-model="modelGroup"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="modelGroupMenu"
                    label="modelGroup"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                    chips
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg3>
                  <v-autocomplete
                    v-model="model"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="modelMenu"
                    label="Model"
                    class="v-text-field__slot list-select-field"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                    chips
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg3>
                  <v-autocomplete
                    v-model="mucModule"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="mucModuleMenu"
                    label="MucModule"
                    class="v-text-field__slot list-select-field"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                    chips
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg3
                  style="display: flex;">
                  <v-autocomplete
                    v-model="columnName"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="columnNameMenu"
                    label="清單項目"
                    class="v-text-field__slot list-select-field"
                    persistent-hint
                    outlined
                  />
                  <v-btn
                    color="black"
                    class="ml-3"
                    small
                    @click="doAddListDataItem(columnName)">
                    <v-icon class="mr-2">mdi-plus</v-icon>新增
                  </v-btn>
                </v-flex>
                <input
                  v-show="false"
                  id="upload-file"
                  :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                  type="file"
                  label="檔名"
                  @change="setFile($event)">
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          v-for="(column, c) in listDataColumnName"
          :key="'c'+c"
          style="margin-top: 20px!important;">
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  flex-small
                  xs12
                  lg11>
                  <v-textarea
                    v-model="listData[column]"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :label="column+'清單 (多組請以逗號區隔)'"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg1>
                  <v-btn
                    class="mx-0 my-2 font-weight-light float-right"
                    color="red"
                    @click="doDeleteListDataItem(column)">
                    <v-icon class="float-left">mdi-delete</v-icon>刪除清單項目
                  </v-btn>
                  <v-btn
                    class="mx-0 my-2 font-weight-light float-right"
                    color="black"
                    @click="doSampleDownload(column)">
                    <v-icon class="float-left">mdi-file</v-icon>下載範本檔案
                  </v-btn>
                  <v-btn
                    class="mx-0 my-2 font-weight-light float-right"
                    color="black"
                    @click="uploadFile(0,column)">
                    <v-icon class="float-left">mdi-expand-all</v-icon>匯入序號清單
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
        <material-card style="margin-top: 20px!important;">
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  flex-small
                  xs12
                  lg12>
                  <v-card-actions class="m-0 border-top border-secondary mt-3">
                    <v-spacer/>
                    <v-btn
                      :disabled="modelYear==='' || series.length===0 || modelGroup.length===0"
                      color="indigo"
                      class="ml-3"
                      small
                      @click="doDownload()">
                      <v-icon class="mr-2">mdi-export</v-icon>匯出Raw Data
                    </v-btn>
                  </v-card-actions>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :top="true">
      {{ snackbarMsg }}
      <v-btn
        flat
        @click="snackbar = false">
        關閉
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
// import qs from 'qs'
import $ from 'jquery'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'CscBackendRawDataExport',
  components: {
  },
  data () {
    return {
      snackbar: false,
      snackbarColor: 'green',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      expressDownload: false,
      modelYear: '',
      modelYearMenu: [],
      modelMenu: [],
      model: [],
      modelGroup: [],
      modelGroupMenu: [],
      series: [],
      seriesMenu: [],
      mucModule: [],
      mucModuleMenu: [],
      columnName: '',
      columnNameMenu: ['orgSn', 'pnOrgUnit', 'replacePartNo'],
      listDataColumnName: [],
      listData: {},
      importTargetItem: '',
      file: [
        {
          file: '',
          name: '',
          url: ''
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink'])
  },
  watch: {
    modelYear (val) {
      this.getSeries(val)
    },
    series (val) {
      this.getModelGroup(this.modelYear, val)
    },
    modelGroup (val) {
      this.getModel(val)
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/pqm/cfrAnalysisMgmt/cscBackendRawDataExportView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          this.$http.get('/api/pqm/cfrAnalysisMgmt/modelYear').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.modelYearMenu = res.data
                this.modelYear = ''
              }
            })
          this.$http.get('/api/pqm/getSelectOptionData/mucModule').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.mucModuleMenu = res.data
                this.mucModule = []
              }
            })
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    getSeries (year) {
      if (year !== '') {
        this.$http.get(`/api/pqm/cfr/series?year=${year}`).then(res => res.data)
          .then(res => {
            if (res.status === 'success') {
              this.seriesMenu = res.data
              this.series = []
            }
          })
      }
    },
    getModelGroup (year, series) {
      if (year !== '' && series.length > 0) {
        this.$http.get(`/api/pqm/cfr/modelGroup?year=${year}&seriesList=${series}`).then(res => res.data)
          .then(res => {
            if (res.status === 'success') {
              this.modelGroupMenu = res.data
              this.modelGroup = []
            }
          })
      }
    },
    getModel (modelGroup) {
      if (modelGroup.length > 0) {
        this.$http.get(`/api/pqm/cfr/model?modelGroupList=${modelGroup}`).then(res => res.data)
          .then(res => {
            if (res.status === 'success') {
              this.modelMenu = res.data
              this.model = []
            }
          })
      }
    },
    doAddListDataItem (columnName) {
      if (columnName === '') {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '清單項目不得為空'
      } else if (Object.keys(this.listData).indexOf(columnName) === -1) {
        this.listData[columnName] = ''
        this.listDataColumnName.push(columnName)
      } else {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '該欄位項目已存在'
      }
    },
    doDeleteListDataItem (columnName) {
      if (this.listDataColumnName.indexOf(columnName) !== -1) {
        this.listDataColumnName.splice(this.listDataColumnName.indexOf(columnName), 1)
        delete this.listData[columnName]
      } else {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '該欄位項目不存在'
      }
    },
    ImportListData (columnName, resData) {
      this.listData[columnName] = resData[columnName].concat(this.listData[columnName].split(','))
      this.listData[columnName] = this.listData[columnName].filter((element, index, arr) => {
        return arr.indexOf(element) === index
      })
      this.listData[columnName] = this.listData[columnName].join(',')
    },
    conversionExcelFieldsToList (columnName) {
      if (this.file[0].file) {
        this.processingView = true
        const formData = new FormData()
        formData.append('file', this.file[0].file)
        formData.append('sourceFields', [columnName])
        this.$http.post('/api/pqm/cfrAnalysisMgmt/parseExcelFieldsToList', formData).then(res => res.data)
          .then(res => {
            this.processingView = false
            this.cancelFile()
            if (res.status === 'success') {
              this.ImportListData(columnName, res.data)
              this.importTargetItem = ''
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      } else {
        this.processingView = false
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '檔名或檔案不得為空'
      }
    },
    uploadFile (type, item) {
      this.importTargetItem = item
      $('#upload-file').click()
    },
    cancelFile () {
      this.file[0].file = this.file[0].name = this.file[0].url = ''
      $('#upload-file').val('')
    },
    setFile (e) {
      const files = e.target.files || e.dataTransfer.files
      this.file[0].url = window.URL.createObjectURL(e.srcElement.files.item(0))
      this.file[0].file = files[0]
      this.file[0].name = e.srcElement.files.item(0).name
      setTimeout(() => {
        this.conversionExcelFieldsToList(this.importTargetItem)
      }, 0)
    },
    doDownload () {
      this.processingView = true
      const body = {
        modelYear: this.modelYear,
        series: this.series,
        modelGroup: this.modelGroup,
        model: this.model,
        mucModule: this.mucModule
      }
      this.listDataColumnName.forEach(column => {
        body[column] = this.listData[column].split(',')
      })
      this.$http.post('/api/pqm/cscDataParsingMgmt/exportCscBackendRawData', body, { responseType: 'blob' })
        .then(res => {
          if (this.expressDownload) {
            this.resetOrgSnListItem()
            this.expressDownload = false
          }
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                alert(res.data.data)
                // resolve(Promise.reject(res.data.data))
              }
              reader.onerror = () => {
                reject(res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '無原始檔案'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    },
    doSampleDownload (columnName) {
      this.processingView = true
      this.$http.get(`/api/pqm/cfr/sampleFileForQuery?columnName=${columnName}`, { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.status === 'success') {
                  alert(res.data.data)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '無檔案可供下載'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    }
  }
}
</script>
<style>
</style>
