<template>
  <v-container
    cfr
    fluid
    grid-list-xl
  >
    <v-layout wrap>
      <v-flex
        xs12
        lg12
      >
        <material-card
          :class="{'card-shrink-active' : searchShrink}"
          title="機種不良率趨勢分析"
          class="card-padding card-shrink"
          color="general"
        >
          <v-icon
            :class="{'card-shrink-active-icon' : searchShrink}"
            @click="doSearchShrinkSwitch()"
          >mdi-apple-keyboard-control</v-icon>
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  flex-small
                  my-2
                  xs12
                  lg3
                  style="display: flex;flex-flow: column;">
                  <p style="max-width: 150px;margin: 0!important;">可選線組清單</p>
                  <tree-menu-selector
                    :children="treeMenuList"
                    :depth="0"
                    :parameter="parameter"
                    :deselect="sequence"
                    class="min-height-200 max-height-200"
                    @return-option-value="processSelectedOptions"
                    @return-deselect-state="getDeselectState"/>
                </v-flex>
                <v-flex
                  flex-small
                  my-2
                  xs12
                  lg5
                  style="display: flex;flex-flow: column;">
                  <p style="max-width: 150px;margin: 0!important;">已選擇線組</p>
                  <category-options-display
                    :original-data="lineSetList"
                    class="min-height-200 max-height-200"
                    @return-option-sequence="processDeselectOptions"/>
                </v-flex>
                <v-flex
                  flex-small
                  d-flex
                  flex-column
                  my-2
                  xs12
                  lg4>
                  <v-flex
                    d-flex
                    align-items-center
                    xs12
                    lg12>
                    <p style="min-width: 100px;max-width: 120px;margin: 0 30px 0 0 !important;text-align: end;">Level</p>
                    <v-autocomplete
                      v-model="repairLevel"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      :items="repairLevelMenu"
                      class="v-text-field__slot"
                      style="margin: -15px 0;"
                      persistent-hint
                      outlined
                      multiple
                      multitude
                      chips
                    />
                  </v-flex>
                  <hr class="m-0 border-secondary">
                  <v-flex
                    d-flex
                    align-items-center
                    xs12
                    lg12>
                    <p style="min-width: 100px;max-width: 120px;margin: 0 30px 0 0 !important;text-align: end;">選擇時間格式</p>
                    <v-radio-group
                      v-model="statisticPeriodType"
                      class="m-0 py-0"
                      row>
                      <v-radio
                        label="產品週期(Life time)"
                        value="lifeTime"/>
                      <v-radio
                        label="返修年月"
                        value="yearMonth"/>
                    </v-radio-group>
                  </v-flex>
                  <hr class="m-0 border-secondary">
                  <v-flex
                    d-flex
                    align-items-center
                    xs12
                    lg12>
                    <p style="min-width: 100px;max-width: 120px;margin: 0 30px 0 0 !important;text-align: end;">
                      選擇資料項目
                    </p>
                    <v-checkbox
                      v-model="excludeBestBuy"
                      :value="true"
                      input-value="1"
                      class="m-0 py-0"
                      label="Exclude Best Buy"/>
                  </v-flex>
                  <hr class="m-0 border-secondary">
                  <v-flex
                    xs12
                    lg12
                    style="display: flex;align-items: end;justify-content: end;"
                  >
                    <v-btn
                      :disabled="statisticPeriodType === '' || lineSetList.length === 0"
                      class="mx-0 mr-3 font-weight-light"
                      color="general"
                      @click="doSubmit()"
                    >
                      <v-icon class="mr-2">mdi-backup-restore</v-icon>統計
                    </v-btn>
                    <v-btn
                      :disabled="statisticPeriodType === '' || lineSetList.length === 0"
                      class="mx-0 font-weight-light"
                      color="indigo"
                      @click="doDownload()"
                    >
                      <v-icon class="mr-2">mdi-export</v-icon>匯出Raw Data
                    </v-btn>
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>

      <v-flex>
        <v-flex selenium-grab>
          <v-flex
            v-show="analyzerView"
            flex_table_width_auto
            md12
            xs12
            style="padding: 0px!important;">
            <material-card
              :title="chartName"
              color="brown"
              class="card-mix d-table-t0 no-data-left"
              style="margin-top: 30px!important;">
              <vue-c3 :handler="chartArea"/>
              <svg style="height: 0;">
                <defs>
                  <linearGradient
                    id="targetGradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                    spreadMethod="pad">
                    <stop
                      offset="25%"
                      stop-color="#99cc55"
                      stop-opacity="1"
                    />
                    <stop
                      offset="100%"
                      stop-color="#99cc55"
                      stop-opacity="0"
                    />
                  </linearGradient>
                </defs>
              </svg>
            </material-card>
            <material-card class="card-mix d-table-t0 no-data-left">
              <!-- <v-select
                      v-model="pagination.current.rowsPerPage"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      :items="rowsAmount"
                      class="mix-list v-text-field__slot d-inline-block float-right ml-5"
                      style="width: 90px;"
                      persistent-hint
                      outlined
                    /> -->
              <v-data-table
                :headers="responseHeaders"
                :items="responseSpecifyData"
                class="height-row-3 table-align-left"
                multi-sort
                hide-actions
              >
                <template
                  slot="headerCell"
                  slot-scope="{ header }"
                >
                  <span
                    class="subheading font-weight-light text-general text--darken-3"
                    v-text="header.text"
                  />
                </template>
                <template
                  slot="items"
                  slot-scope="{ item }"
                >
                  <tr>
                    <td>{{ item.modelName }}</td>
                    <td>{{ item.dateRange }}</td>
                    <td>{{ item.totalLiftTime }}</td>
                    <td>{{ item.inWarrantyFailureQty }}</td>
                    <td>{{ item.emsShipQty }}</td>
                    <td>{{ item.activeQty }}</td>
                    <td>{{ (item.activeByEmsRate * 100).toFixed(2) }}%</td>
                    <td>{{ (item.activeByThatRangeRate * 100).toFixed(2) }}%</td>
                    <td>{{ (item.modelCfr * 100).toFixed(2) }}%</td>
                    <td>{{ (item.modelTargetCfr * 100).toFixed(2) }}%</td>
                    <td>{{ (item.thatContributionCfr * 100).toFixed(2) }}%</td>
                    <td>{{ (item.cfrByThatContributionRate * 100).toFixed(2) }}%</td>
                  </tr>
                </template>
              </v-data-table>
              <!-- <div
                      v-if="pagination.current.rowsPerPage != -1"
                      class="text-xs-center pt-2 d-inline-block"
                      style="margin-left: 0px;width: calc(100% - 140px);">
                      <v-pagination
                        v-model="pagination.page"
                        :length="pages"/>
                    </div>
                    <v-autocomplete
                      v-if="pagination.current.rowsPerPage != -1"
                      v-model="pagination.page"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      :items="pageslist"
                      class="v-text-field__slot d-inline-block float-right mr-0"
                      style="width: 90px;"
                      persistent-hint
                      outlined
                    /> -->
            </material-card>
          </v-flex>
        </v-flex>
        <v-flex
          v-show="analyzerView"
          flex_table_width_auto
          xs12
          lg12
          style="padding: 5PX 0px!important;">
          <material-card
            id="checkTable"
            :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
            :title="tableName"
            color="deep-orange"
            class="card-mix d-table-t0 no-data-left"
            style="margin-top: 30px!important;">
            <!-- <v-select
              v-model="pagination.current.rowsPerPage"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              :items="rowsAmount"
              class="mix-list v-text-field__slot d-inline-block float-right ml-5"
              style="width: 90px;"
              persistent-hint
              outlined
            /> -->
            <v-data-table
              :headers="responseHeaders"
              :items="responseData"
              class="height-row-3 table-align-left row-problem-total"
              multi-sort
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }"
              >
                <span
                  class="subheading font-weight-light text-general text--darken-3"
                  v-text="header.text"
                />
              </template>
              <template
                slot="items"
                slot-scope="{ item }"
              >
                <tr>
                  <td>{{ item.modelName }}{{ item.key }}</td>
                  <td>{{ item.dateRange }}</td>
                  <td>{{ item.totalLiftTime }}</td>
                  <td>{{ item.inWarrantyFailureQty }}</td>
                  <td>{{ item.emsShipQty }}</td>
                  <td>{{ item.activeQty }}</td>
                  <td>{{ (item.activeByEmsRate * 100).toFixed(2) }}%</td>
                  <td>{{ (item.activeByThatRangeRate * 100).toFixed(2) }}%</td>
                  <td>{{ (item.modelCfr * 100).toFixed(2) }}%</td>
                  <td>{{ (item.modelTargetCfr * 100).toFixed(2) }}%</td>
                  <td>{{ (item.thatContributionCfr * 100).toFixed(2) }}%</td>
                  <td>{{ (item.cfrByThatContributionRate * 100).toFixed(2) }}%</td>
                </tr>
              </template>
            </v-data-table>
            <!-- <div
              v-if="pagination.current.rowsPerPage != -1"
              class="text-xs-center pt-2 d-inline-block"
              style="margin-left: 0px;width: calc(100% - 140px);">
              <v-pagination
                v-model="pagination.page"
                :length="pages"/>
            </div>
            <v-autocomplete
              v-if="pagination.current.rowsPerPage != -1"
              v-model="pagination.page"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              :items="pageslist"
              class="v-text-field__slot d-inline-block float-right mr-0"
              style="width: 90px;"
              persistent-hint
              outlined
            /> -->
          </material-card>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :top="true">
      {{ snackbarMsg }}
      <v-btn
        flat
        @click="snackbar = false">
        關閉
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import TreeMenuSelector from './TreeMenuSelector.vue'
import CategoryOptionsDisplay from './CategoryOptionsDisplay.vue'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'DeviceCfrTrendAnalysis',
  components: {
    VueC3,
    TreeMenuSelector,
    CategoryOptionsDisplay
  },
  data () {
    return {
      reportYear: '',
      reportSeries: '',
      reportModelGroup: '',
      generateForReport: false,
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      searchShrink: false,
      treeMenuList: [],
      lineSetList: [],
      repairLevel: [],
      repairLevelMenu: [],
      statisticPeriodType: 'lifeTime',
      excludeBestBuy: false,
      parameter: [],
      sequence: null,
      tableName: '',
      chartName: '',
      baseline: false,
      analyzerView: false,
      analyzerType: 0,
      responseChartData: [],
      chartXaxisName: [],
      chartXaxisValue: [],
      chartLineTypes: {},
      chartLineHint: {},
      chartLineTooltip: [],
      chartLineId: 0,
      chartLineIndex: 0,
      chartBaseLineId: '',
      chartBaseLineColor: {},
      chartStatisticPeriodType: 'lifeTime',
      chartArea: new Vue(),
      responseData: [],
      responseSpecifyData: [],
      responseHeaders: []
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    typeList () {
      const list = []
      this.resTypeListData.forEach((r) => {
        list.push({ text: r.columnLabel, value: r.columnName })
      })
      return list
    },
    options_line () {
      return {
        data: {
          x: 'x',
          columns: this.chartXaxisName.concat(this.chartXaxisValue),
          labels: {
            format: (v, id, i, j) => {
              if (v && id && i >= 0) {
                if (this.chartLineHint[id].indexOf(i) > -1) {
                  return (v * 100).toFixed(2) + '%'
                } else {
                  return ''
                }
              }
            },
            color: '#999999'
          },
          type: (this.analyzerType === 0) ? 'line' : 'pie',
          types: this.chartLineTypes,
          // regions: this.chartLineRegions,
          // colors: {
          //   '2022 Target': '#99cc5555'
          // },
          colors: this.chartBaseLineColor,
          onclick: (d, i) => {
            if (d.id !== this.chartBaseLineId) {
              this.doChangeChartLineHint(d)
            }
          },
          onmouseover: (d, i) => { this.doChangeChartLineId(d) }
        },
        point: {
          r: (v) => {
            if (v.id && v.index >= 0) {
              if (this.chartLineHint[v.id].indexOf(v.index) > -1) {
                return 5
              } else if (v.id === this.chartBaseLineId) {
                return 0
              } else {
                return 3
              }
            } else {
              return 0
            }
          }
        },
        axis: {
          x: {
            type: 'category', // this needed to load string x value
            tick: {
              rotate: -45,
              multiline: false
            },
            label: {
              text: this.chartStatisticPeriodType === 'lifeTime' ? '週期月' : this.chartStatisticPeriodType === 'yearMonth' ? '返修年/月' : '',
              position: 'outer-right'
            },
            padding: {
              top: 0,
              right: 0.25,
              bottom: 0,
              left: 0.25
            }
          },
          y: {
            tick: {
              format: (value) => { return (value * 100).toFixed(2) + '%' }
            },
            label: {
              text: '不良率',
              position: 'outer-bottom'
            }
          }
        },
        pie: {
          label: {
            format: (value, ratio, id) => {
              return (ratio * 100).toFixed(2) + '%'
            }
          }
        },
        // pie: {
        //   label: {
        //     format: (value, ratio, id) => {
        //       return value + 'Pcs'
        //     },
        //     threshold: -1
        //   }
        // },
        // tooltip: this.analyzerType === 0 ? this.chartLineTooltip : this.chartPieTooltip,
        tooltip: {
          contents: (d) => {
            let message = ''
            const that = this
            d.forEach((dd, index) => {
              if (dd.id === that.chartLineId) {
                that.chartLineTooltip.forEach((c, i) => {
                  if (c.id === that.chartLineId) {
                    message = '<div style="color:#ffffff!important;background-color:#000000;padding:10px;font-weight:900!important;">' + that.chartLineTooltip[i].message[dd.index] + '</div>'
                  }
                })
              }
            })
            return message
          }
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'right',
          padding: 0,
          show: true
        },
        size: {
          width: ((window.innerWidth - this.shrink) * 1) - 64 - (this.chartType === 1 ? 410 : 0),
          height: 600
        }
      }
    }
  },
  watch: {
    // sequence (val) {
    //   if (val) {
    //     this.processingView = true
    //   } else {
    //     this.processingView = false
    //   }
    // },
    chartType (val) {
      if (val === 0) {
        this.lineArray = [{ lineType: 0, customGroupName: false, groupName: '', fields: [], selectAllFields: [], selectAllList: [] }]
        this.lineGroup = 0
        this.lineGroupList = []
      } else if (val === 1) {
        this.lineArray = [{ lineType: 2, customGroupName: false, groupName: '', fields: [], selectAllFields: [], selectAllList: [] }]
        this.lineGroup = 0
        this.lineGroupList = []
      }
    },
    chartLineIndex (val) {
      this.responseSpecifyData = [this.responseData[val]]
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/pqm/cfrAnalysisMgmt/DeviceCfrTrendAnalysisView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          this.loadingView = true
          this.$http.get('/api/pqm/cfr/seriesGroupModelTreeList').then(res => res.data)
            .then(res => {
              this.loadingView = false
              if (res.status === 'success') {
                this.treeMenuList = res.data
                if (this.$route.params.depth1) {
                  setTimeout(() => {
                    this.parameter.push(this.$route.params.depth1)
                    if (this.$route.params.depth2) this.parameter.push(this.$route.params.depth2)
                    if (this.$route.params.depth3) this.parameter.push(this.$route.params.depth3)
                    if (this.$route.params.depth4) this.parameter.push(this.$route.params.depth4)
                    if (this.$route.params.depth5) this.parameter.push(this.$route.params.depth5)
                    this.generateForReport = true
                  }, 0)
                  setTimeout(() => {
                    if (this.lineSetList.length > 0) {
                      this.doSubmit()
                    } else {
                      this.snackbar = true
                      this.snackbarColor = 'red'
                      this.snackbarMsg = '清單內查無符合項目(請留意大小寫)'
                    }
                  }, 0)
                }
              }
            })
          this.$http.get('/api/pqm/cfr/repairLevel').then(res => res.data)
            .then(res => {
              this.loadingView = false
              if (res.status === 'success') {
                this.repairLevelMenu = res.data
              }
            })
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', '無此頁面權限')
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    doSearchShrinkSwitch () {
      if (this.searchShrink) {
        this.searchShrink = false
      } else {
        this.searchShrink = true
      }
    },
    doChangeChartLineId (d) {
      this.chartLineId = d.id
    },
    doChangeChartLineHint (d) {
      if (this.chartLineHint[d.id].indexOf(d.index) === -1) {
        this.chartLineHint[d.id].push(d.index)
      } else {
        this.chartLineHint[d.id].splice(this.chartLineHint[d.id].indexOf(d.index), 1)
      }
      this.chartArea.$emit('dispatch', chart => {
        const options = {
          columns: this.chartXaxisName.concat(this.chartXaxisValue)
        }
        chart.load(options)
      })
      this.chartXaxisValue.forEach((c, index) => {
        if (c[0] === d.id) {
          this.chartLineIndex = index
        }
      })
    },
    doSubmitVerification () {
      if (this.statisticPeriodType === '' && !this.generateForReport) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '請選擇時間格式'
        return false
      } else if (this.lineSetList.length === 0 && !this.generateForReport) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '請選擇線組'
        return false
      } else {
        return true
      }
    },
    doSubmit () {
      if (this.doSubmitVerification()) {
        let body = {}
        if (this.generateForReport) {
          this.loadingView = true
          body = {
            repairLevelList: this.repairLevel,
            statisticPeriodType: this.statisticPeriodType,
            excludeBestBuy: false,
            lineSet: this.lineSetList.map(e => e.contect).flat(Infinity).map(e => e.attribute)
          }
        } else {
          this.loadingView = true
          body = {
            repairLevelList: this.repairLevel,
            statisticPeriodType: this.statisticPeriodType,
            excludeBestBuy: this.excludeBestBuy || false,
            lineSet: this.lineSetList.map(e => e.contect).flat(Infinity).map(e => e.attribute)
          }
        }
        this.$http.post('/api/pqm/cfr/modelCfrTrendData', body).then(res => res.data)
          .then(res => {
            this.loadingView = false
            if (res.status === 'success') {
              this.responseSpecifyData = []
              this.chartName = res.data.chartName
              this.chartStatisticPeriodType = res.data.statisticPeriodType
              this.tableName = res.data.table.tableName
              this.responseHeaders = res.data.table.columnDefinitionList
              this.responseData = res.data.table.tableData
              this.responseSpecifyData = [res.data.table.tableData[0]]
              this.chartXaxisName = [res.data.xColumn]
              this.chartXaxisValue = []
              this.chartLineTypes = {}
              // this.chartLineRegions = {}
              this.chartLineHint = {}
              this.chartLineTooltip = []
              if (res.data.lineData.length > 0) {
                res.data.lineData.forEach((c) => {
                  this.chartXaxisValue.push(c.data)
                  this.chartLineTypes[c.data[0]] = 'line'
                  this.chartLineHint[c.data[0]] = c.hint
                  this.chartLineTooltip.push({ id: c.data[0], message: c.messageList })
                })
              }
              if (res.data.barData.length > 0) {
                res.data.barData.forEach((c) => {
                  this.chartXaxisValue.push(c.data)
                  this.chartLineTypes[c.data[0]] = 'bar'
                  this.chartLineHint[c.data[0]] = c.hint
                  this.chartLineTooltip.push({ id: c.data[0], message: c.messageList })
                })
              }
              if (res.data.pieData.length > 0) {
                res.data.pieData.forEach((c) => {
                  this.chartXaxisValue.push(c.data)
                  this.chartLineTypes[c.data[0]] = 'pie'
                  this.chartLineHint[c.data[0]] = c.hint
                })
              }
              if (res.data.type === 0) {
                this.chartXaxisValue.push(res.data.baseline.data)
                this.chartLineTypes[res.data.baseline.data[0]] = 'area'
                // this.chartLineRegions[res.data.baseline.data[0]] = [{ style: 'dashed' }]
                this.chartLineHint[res.data.baseline.data[0]] = res.data.baseline.hint
                this.chartBaseLineId = res.data.baseline.data[0]
                this.chartBaseLineColor = { [res.data.baseline.data[0]]: '#99cc5555' }
              }
              this.chartArea.$emit('init', this.options_line)
              this.analyzerType = res.data.type
              this.analyzerView = true
            } else {
              this.analyzerView = false
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
          .catch(err => {
            // this.error = true
            this.loadingView = false
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '系統錯誤，請洽系統管理人員\n錯誤訊息：' + err
          })
      }
    },
    doDownload () {
      if (this.doSubmitVerification()) {
        this.processingView = true
        const body = {
          repairLevelList: this.repairLevel,
          statisticPeriodType: this.statisticPeriodType,
          excludeBestBuy: this.excludeBestBuy || false,
          lineSet: this.lineSetList.map(e => e.contect).flat(Infinity).map(e => e.attribute)
        }
        this.$http.post('/api/pqm/cfr/modelCfrTrendRawData', body, { responseType: 'blob' })
          .then(res => {
            this.processingView = false
            if (res.data.type === 'application/json') {
              return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                  res.data = JSON.parse(reader.result)
                  alert(res.data.data)
                  resolve(Promise.reject(res.data.data))
                }
                reader.onerror = () => {
                  reject(res.data.data)
                }
                reader.readAsText(res.data)
              })
            } else if (res.status !== 'failure' && res.headers['content-disposition']) {
              const blob = new Blob([res.data], { type: 'application/octet-stream' })
              const url = window.URL.createObjectURL(blob)
              const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
              const downloadLink = document.createElement('a')
              downloadLink.href = url
              downloadLink.download = fileName

              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = '無原始檔案'
            }
          })
          .catch(err => {
            // this.error = true
            this.loadingView = false
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = err
          })
      }
    },
    processSelectedOptions (selected, name, attribute, color, depth, category, sequence) {
      if (selected) {
        if (this.lineSetList.map(e => e.depth).indexOf(depth) === -1) {
          this.lineSetList.push({ depth: depth, category: category, contect: [] })
          this.lineSetList.sort((a, b) => parseFloat(a.depth) - parseFloat(b.depth))
        }
        if (this.lineSetList[this.lineSetList.map(e => e.depth).indexOf(depth)].contect.map(e => e.sequence).indexOf(sequence) === -1) {
          this.lineSetList[this.lineSetList.map(e => e.depth).indexOf(depth)].contect.push({ sequence: sequence, name: name, attribute: attribute, color: color })
          this.lineSetList[this.lineSetList.map(e => e.depth).indexOf(depth)].contect.sort((a, b) => parseFloat(a.sequence) - parseFloat(b.sequence))
        }
      } else {
        if (this.lineSetList[this.lineSetList.map(e => e.depth).indexOf(depth)].contect.map(e => e.sequence).indexOf(sequence) !== -1) {
          this.lineSetList[this.lineSetList.map(e => e.depth).indexOf(depth)].contect.splice(this.lineSetList[this.lineSetList.map(e => e.depth).indexOf(depth)].contect.map(e => e.sequence).indexOf(sequence), 1)
        }
        if (this.lineSetList[this.lineSetList.map(e => e.depth).indexOf(depth)].contect.length === 0) {
          this.lineSetList.splice(this.lineSetList.map(e => e.depth).indexOf(depth), 1)
        }
      }
    },
    processAutoSelectOptions (array) {
      this.parameter = array
    },
    processDeselectOptions (sequence) {
      this.sequence = sequence
    },
    getDeselectState (state) {
      this.sequence = state
    }
  }
}
</script>
<style>
  .c3-area {
    fill: url(#targetGradient) !important;
    opacity: 0.25 !important;
  }
</style>
